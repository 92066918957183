<template>
  <div />
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'Logout',
  mounted() {
    this.signOutOidc();
  },
  methods: {
    ...mapActions({
      signOutOidc: 'oidcStore/signOutOidc',
    }),
  },
};
</script>

<template>
  <div class="h-full flex flex-row">
    <div class="w-1/4 self-center">
      <VerticalProgressBar
        class="m-auto"
        :steps="pages"
        :step="currentPageIndex"
        @navigate="navigateToPage"
      />
    </div>
    <div class="w-1/2 text-center self-center ml-auto">
      <Title
        class="m-auto mb-10"
        :title="currentPage.title"
        :subtitle="currentPage.subtitle"
      />
      <div class="w-3/4 mx-auto">
        <component
          :is="currentPage.component"
          ref="currentComponent"
          :getValue="getValue"
          :setValue="setValue"
          class="my-12"
        />
        <Navigation
          :config="currentPage.directions"
          :prevTitle="currentPage.prevTitle || 'Go back'"
          :nextTitle="currentPage.nextTitle || 'Next Step'"
          @navigate="navigateToPage"
        />
      </div>
    </div>
    <div class="w-1/4"></div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Title from '@/components/atoms/Title.vue';
import CompanyDetails from '@/components/molecules/registration/CompanyDetails.vue';
import CreatePassword from '@/components/molecules/registration/CreatePassword.vue';
import PersonalDetails from '@/components/molecules/registration/PersonalDetails.vue';
import Navigation from '@/components/atoms/Navigation.vue';
import VerticalProgressBar from '@/components/atoms/VerticalProgressBar.vue';

export default {
  name: 'Registration',
  components: {
    CompanyDetails,
    CreatePassword,
    Title,
    Navigation,
    PersonalDetails,
    VerticalProgressBar,
  },
  props: {
    registrationId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentPageIndex: 0,
      routeHistory: [],
    };
  },
  computed: {
    currentPage() {
      return this.pages[this.currentPageIndex];
    },
    pages() {
      return [
        {
          title: 'Welcome to Portnetic',
          subtitle: 'You’re almost ready to get started.',
          progressBarTitle: 'Company Details',
          component: 'CompanyDetails',
          directions: {
            next: 1,
          },
        },
        {
          title: 'Contact Details',
          subtitle:
            'This is your main contact’s information. We will use the email provided for further communications.',
          progressBarTitle: 'Contact Details',
          component: 'PersonalDetails',
          directions: {
            prev: 0,
            next: 2,
          },
        },
        {
          title: 'Create Password',
          subtitle: 'Please create a password for your account',
          progressBarTitle: 'Set Password',
          component: 'CreatePassword',
          directions: {
            prev: 1,
            next: 'complete',
          },
          nextTitle: 'Get started',
        },
      ];
    },
  },
  async mounted() {
    await this.getAuctionHouses();
    this.$store.dispatch('registration/updateValue', {
      id: 'registrationId',
      value: this.registrationId,
    });
  },
  methods: {
    ...mapActions({
      getAuctionHouses: 'auctionHouses/getAuctionHouses',
      setStepCompletion: 'registration/setStepCompletion',
    }),
    async navigateToPage(index) {
      const hasAlreadyVisitedNextRoute = this.routeHistory.includes(index);
      this.routeHistory.push(this.currentPageIndex);
      if (index === 'complete') {
        await this.register();
        this.$router.push('/');
      } else {
        this.currentPageIndex = index;
      }
      this.setStepCompletion({
        stepCompletion: hasAlreadyVisitedNextRoute,
      });
      if (hasAlreadyVisitedNextRoute) {
        setTimeout(() => this.$refs.currentComponent.touchValidation());
      }
    },
    getValue(fieldName) {
      return this.$store.state.registration[fieldName];
    },
    setValue(config) {
      this.$store.dispatch('registration/updateValue', config);
    },
    register() {
      return this.$store.dispatch('registration/register');
    },
  },
};
</script>

import moment from 'moment';
import Vue from 'vue';
import {
  createVehicle,
  getVehicles,
  setCustomerDutyPayment,
  uploadVehicleDocument,
  updateVehicleStatus,
  updateVehicle,
  deleteVehicle,
} from '@/services/api/vehicles';
import { deleteDocument } from '@/services/api/documents';
import { formatStatus } from '@/services/utils/formatEnums';
import idGenerator from '@/services/utils/idGenerator.js';
import { fetchVehicle } from '../../services/api/vehicles';

const getDefaultState = () => ({
  forecourt: {
    registrationNumber: '',
    siteLink: '',
    make: '',
    model: '',
    maxPrice: '',
  },
  auction: {
    registrationNumber: '',
    auctionHouseName: null,
    auctionHouseBranch: null,
    auctionHouseAccountNumber: '',
    make: '',
    model: '',
  },
  search: '',
  filters: {
    status: '',
    type: '',
  },
  items: null,
  pagination: {
    totalItems: 0,
    totalPages: null,
    limit: 4,
    pageNr: 0,
  },
  rows: [],
  isTransportEdit: false,
  selectedForTransport: [],
  newVehicleDocuments: {},
  currentVehicle: {},
});

const actions = {
  resetVehiclesState({ commit }) {
    commit('resetState');
  },
  async resetPagination({ commit }) {
    await commit('RESET_PAGINATION');
  },
  async selectVehicle({ commit, dispatch }, vehicle) {
    commit('SET_SELECTED_VEHICLE', vehicle);
    await dispatch('loads/setDocuments', vehicle, { root: true });
  },
  resetSearch({ commit }) {
    commit('SET_SEARCH', '');
  },
  updateField({ commit }, data) {
    commit('UPDATE_FIELD', data);
  },
  setSearch({ commit }, search) {
    commit('SET_SEARCH', search);
  },
  setFilter({ commit }, filter) {
    commit('SET_FILTER', filter);
  },
  resetFilter({ commit }) {
    commit('RESET_FILTERS');
  },
  clearRows({ commit }) {
    commit('CLEAR_ROWS');
  },
  addLocation({ commit }, data) {
    commit('SET_LOCATION', data);
  },
  deleteDocument(_, { id }) {
    return deleteDocument(id);
  },
  removeVehicle({ dispatch }, id) {
    return deleteVehicle(id).then(() => {
      dispatch('getVehicles', { next: false, prev: false });
    });
  },
  updateStatus({ dispatch }, { id, status }) {
    return updateVehicleStatus(id, status).then(async () => {
      await dispatch('getVehicles', { next: false, prev: false });
    });
  },
  changeDocumentType({ commit }, { documentId, vehicleId, documentType }) {
    commit('CHANGE_DOCUMENT_TYPE', { documentId, vehicleId, documentType });
  },
  addDocument({ commit }, { files, vehicleId }) {
    commit('ADD_DOCUMENT', { files, vehicleId });
  },
  clearNewVehicleDocuments({ commit }, { vehicleId, documentId }) {
    commit('CLEAR_NEW_VEHICLE_DOCUMENTS', { vehicleId, documentId });
  },
  checkVehicles({ dispatch }) {
    return getVehicles().then((rsp) => {
      dispatch(
        'user/setHasVehicles',
        { doesHe: rsp.data.items.length > 0 },
        { root: true },
      );
    });
  },
  setTransportEdit({ commit }, isTransporting) {
    commit('CLEAR_TRANSPORT_VEHICLES');
    commit('SET_TRANSPORT_EDIT', isTransporting);
  },
  async clearTransportEdit({ commit, dispatch }) {
    dispatch('resetFilter');
    commit('SET_TRANSPORT_EDIT', false);
    commit('CLEAR_TRANSPORT_VEHICLES');
    await dispatch('getVehicles', { type: null, next: false, prev: false });
  },
  async filterVehicles({ dispatch }, { filter, type }) {
    await dispatch('resetPagination');
    await dispatch('setFilter', filter);
    await dispatch('getVehicles', { type, next: false, prev: false });
  },
  async searchVehicles({ dispatch }, { search, type }) {
    await dispatch('resetPagination');
    await dispatch('setSearch', search);
    await dispatch('getVehicles', { type, next: false, prev: false });
  },
  setPaymentReferenceNumber({ state }, { id, paymentReferenceNumber }) {
    let vehicle = state.items.find((item) => item.id.toString() === id.toString());
    vehicle = { ...vehicle, paymentReference: paymentReferenceNumber };
    return updateVehicle(vehicle);
  },
  setDutyPaymentNeeded({ state }, { id, dutyPaymentNeeded, dutyDueAmount }) {
    let vehicle = state.items.find((item) => item.id.toString() === id.toString());
    vehicle = { ...vehicle, dutyPaymentNeeded, dutyDueAmount };
    return updateVehicle(vehicle);
  },
  setDutyPaid({ state }, { id, dutyPaid }) {
    let vehicle = state.items.find((item) => item.id.toString() === id.toString());
    vehicle = { ...vehicle, dutyPaid };
    return updateVehicle(vehicle);
  },
  updateVehicle({ state, dispatch }) {
    updateVehicle(state.currentVehicle).then(() => {
      dispatch('getVehicles', { next: false, prev: false });
    });
  },
  getVehicles({ commit, state, rootState }, { type, next, prev }) {
    if (next && state.pagination.pageNr <= state.pagination.totalPages) {
      commit('INCREMENT_PAGE');
    }
    if (prev && state.pagination.pageNr <= state.pagination.totalPages) {
      commit('DECREMENT_PAGE');
    }
    const isAdmin = rootState.user.roles.indexOf('APP_ADMIN') > -1;
    const availableForLoad = state.isTransportEdit;
    return getVehicles(
      type,
      {
        limit: state.pagination.limit,
        offset: state.pagination.pageNr * state.pagination.limit,
      },
      state.search,
      state.filters,
      availableForLoad,
    ).then((rsp) => {
      commit('SET_TOTAL_ITEMS', rsp.data.totalCount);
      commit('SET_VEHICLES', { vehicles: rsp.data.items });
      commit('SET_ROWS', { type, isAdmin });
    });
  },
  uploadDocument({ commit }, { vehicle, document, type }) {
    const formData = new FormData();
    formData.append('document', document);
    formData.append('type', type);
    return uploadVehicleDocument(formData, vehicle.id).then((rsp) => { commit('ADD_DOCUMENT_TO_VEHICLE', { ...rsp.data, forVehicle: vehicle.id }); return rsp; });
  },
  setCustomerDutyPayment({ commit }, { id }) {
    return setCustomerDutyPayment(id).then(() => { commit('SET_CUSTOMER_DUTY_PAID_FLAG', id); });
  },
  createForecourtVehicle({ state, commit, dispatch }) {
    const payload = {
      make: state.forecourt.make,
      model: state.forecourt.model,
      sellerUrl: state.forecourt.siteLink,
      registrationNumber: state.forecourt.registrationNumber,
      maxPrice: state.forecourt.maxPrice,
      purchaseType: 'FORECOURT',
    };

    return createVehicle(payload).then((rsp) => {
      dispatch('user/setHasVehicles', { doesHe: true }, { root: true });
      dispatch('getVehicles', { type: 'forecourt' });
      commit('RESET_FORECOURT');
      return rsp;
    });
  },
  getVehicle({ commit }, { id }) {
    return fetchVehicle(id).then((rsp) => commit('SET_CURRENT_VEHICLE', rsp.data));
  },
  createAuctionVehicle({
    state,
    commit,
    dispatch,
    rootState,
  }) {
    const payload = {
      registrationNumber: state.auction.registrationNumber,
      make: state.auction.make,
      model: state.auction.model,
      purchaseType: 'AUCTION',
    };
    if (state.auction.auctionHouseName.value !== 1) {
      const foundBranch = rootState.auctionHouses.houses.find((el) => el.id === state.auction.auctionHouseName.value);
      payload.auctionHouseBranchId = foundBranch.branches[0].id;
    } else {
      payload.auctionHouseBranchId = state.auction.auctionHouseBranch.value;
    }

    if (state.auction.auctionHouseAccountNumber !== '') {
      payload.auctionHouseAccountNumber = state.auction.auctionHouseAccountNumber;
    }

    return createVehicle(payload).then((rsp) => {
      commit('RESET_AUCTION');
      dispatch('user/setHasVehicles', { doesHe: true }, { root: true });
      dispatch('getVehicles', { type: 'auction' });
      return rsp;
    });
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  // pagination
  RESET_PAGINATION(state) {
    state.pagination.totalPages = null;
    state.pagination.pageNr = 0;
  },
  RESET_AUCTION(state) {
    state.auction.registrationNumber = '';
    state.auction.auctionHouseName = null;
    state.auction.auctionHouseBranch = null;
    state.auction.auctionHouseAccountNumber = '';
    state.auction.make = '';
    state.auction.model = '';
  },
  RESET_FORECOURT(state) {
    state.forecourt.registrationNumber = '';
    state.forecourt.siteLink = '';
    state.forecourt.make = '';
    state.forecourt.model = '';
    state.forecourt.maxPrice = '';
  },
  ADD_DOCUMENT_TO_VEHICLE(state, document) {
    state.items.forEach((element) => {
      if (element.id === document.forVehicle) {
        element.documents.push(document);
      }
    });
    if (state.currentVehicle.id === document.forVehicle) {
      state.currentVehicle.documents.push(document);
    }
  },
  SET_TOTAL_ITEMS(state, count) {
    state.pagination.totalPages = Math.ceil(count / state.pagination.limit);
    state.pagination.totalItems = count;
  },
  INCREMENT_PAGE(state) {
    state.pagination.pageNr += 1;
  },
  DECREMENT_PAGE(state) {
    state.pagination.pageNr -= 1;
  },
  UPDATE_FIELD(state, { value, field, type }) {
    state[type][field] = value;
  },
  SET_SEARCH(state, search) {
    state.search = search;
  },
  CLEAR_NEW_VEHICLE_DOCUMENTS(state, payload) {
    if (!payload.documentId) {
      if (state.newVehicleDocuments[payload.vehicleId]?.documents) {
        state.newVehicleDocuments[payload.vehicleId].documents = [];
      }
    } else {
      const { documents } = state.newVehicleDocuments[payload.vehicleId];
      const index = documents.findIndex((item) => item.id === payload.documentId);
      state.newVehicleDocuments[payload.vehicleId].documents.splice(index, 1);
    }
  },
  SET_SELECTED_VEHICLE(state, vehicle) {
    const foundVehicle = state.selectedForTransport.find((el) => el.id === vehicle.id);
    if (foundVehicle) {
      state.selectedForTransport = state.selectedForTransport.filter(
        (el) => el.id !== vehicle.id,
      );
    } else {
      state.selectedForTransport.push({ ...vehicle, location: '' });
    }
  },
  SET_VEHICLE(state) {
    const foundVehicleIndex = state.items.findIndex((el) => el.id === state.currentVehicle.id);
    if (foundVehicleIndex >= 0) {
      state.items[foundVehicleIndex] = state.currentVehicle;
    }
  },
  RESET_FILTERS(state) {
    Object.assign(state.filters, {
      status: '',
      type: '',
    });
  },
  SET_FILTER(state, filter) {
    if (state.filters[filter.type.toLowerCase()] === filter.value) {
      Vue.set(state.filters, filter.type.toLowerCase(), '');
    } else {
      Vue.set(state.filters, filter.type.toLowerCase(), filter.value);
    }
  },
  SET_TRANSPORT_EDIT(state, isTransportEdit) {
    state.isTransportEdit = isTransportEdit;
  },
  CLEAR_TRANSPORT_VEHICLES(state) {
    state.selectedForTransport = [];
  },
  SET_VEHICLES(state, { vehicles }) {
    Vue.set(state, 'items', [...vehicles]);
  },
  CLEAR_ROWS(state) {
    state.rows = [];
  },
  SET_CURRENT_VEHICLE(state, vehicle) {
    state.currentVehicle = vehicle;
  },
  SET_LOCATION(state, data) {
    const vehicleIdx = state.selectedForTransport.findIndex((el) => el.id === data.id);
    state.selectedForTransport[vehicleIdx].location = data.value;
  },
  SET_ROWS(state, { type, isAdmin = false }) {
    state.rows = state.items.map((vehicle, idx) => {
      let oddColumn;
      let customer;
      let pbn;
      if (isAdmin) {
        oddColumn = {
          text: vehicle.purchaseType.toLowerCase(),
          class: 'capitalize',
          type: 'DEFAULT',
        };
        customer = {
          text: vehicle.companyName,
          type: 'DEFAULT',
        };
        pbn = {
          text: vehicle.pbn,
          type: 'DEFAULT',
        };
      } else {
        oddColumn = {
          text:
            type === 'auction' ? vehicle.auctionHouseName : vehicle.maxPrice,
          type: type === 'forecourt' ? 'CURRENCY' : 'DEFAULT',
        };
        customer = {};
        pbn = {};
      }
      const row = [
        {
          text: `${idx
            + (state.pagination.pageNr * state.pagination.limit + 1)}.`,
          class: 'text-center',
        },
        { text: vehicle.registrationNumber },
        { text: vehicle.make ? vehicle.make : '---' },
        { text: vehicle.model ? vehicle.model : '---' },
        { text: moment(vehicle.created).format('MMM Do, hh:mm') },
        oddColumn,
        customer,
        pbn,
        {
          type: isAdmin ? 'SELECTOR' : '',
          object: vehicle,
          disableDropdown: vehicle.nextStatus.length === 0,
          text: formatStatus(vehicle.status).label,
          class: `text-${formatStatus(vehicle.status).color} ${isAdmin && 'min-w-200'}`,
        },
        { type: 'TOOLTIP', status: vehicle.status },
        { type: 'MENU', menuItem: vehicle },
      ];
      if (state.isTransportEdit) {
        row.unshift({ type: 'CHECKBOX', vehicle });
      }
      return row;
    });
  },
  ADD_DOCUMENT(state, { files, vehicleId }) {
    const filesArray = Array.from(files);
    const formattedFiles = filesArray.map((el) => ({
      file: el,
      name: el.name,
      type: '',
      id: idGenerator(),
    }));
    if (state.newVehicleDocuments[vehicleId]) {
      const docs = state.newVehicleDocuments[vehicleId].documents;
      docs.push(...formattedFiles);
      Vue.set(state.newVehicleDocuments[vehicleId], 'documents', docs);
      // state.newVehicleDocuments[vehicleId].documents.push(...formattedFiles);
    } else {
      Vue.set(state.newVehicleDocuments, vehicleId, {});
      Vue.set(
        state.newVehicleDocuments[vehicleId],
        'documents',
        formattedFiles,
      );
    }
  },
  SET_CUSTOMER_DUTY_PAID_FLAG(state, id) {
    const index = state.items.findIndex((el) => el.id === id);
    state.items[index].customerDutyPaid = true;
  },
  CHANGE_DOCUMENT_TYPE(state, { documentId, vehicleId, documentType }) {
    const doc = state.newVehicleDocuments[vehicleId].documents.find(
      (el) => el.id.toString() === documentId.toString(),
    );
    const newDoc = {
      ...doc,
      type: documentType.value,
    };
    Object.assign(doc, newDoc);
  },
};

const getters = {
  getHeaders: () => (type, isAdmin = false) => {
    let oddHeader;
    let customer;
    let pbn;
    if (isAdmin) {
      oddHeader = { text: 'Type' };
      customer = { text: 'Company' };
      pbn = { text: 'PBN' };
    } else {
      oddHeader = { text: type === 'auction' ? 'Auction House' : 'Max Price' };
      customer = {};
      pbn = {};
    }
    const headers = [
      { text: 'No.', class: 'w-40 pr-0' },
      { text: 'Registration' },
      { text: 'Make' },
      { text: 'Model' },
      { text: 'Submission Date' },
      oddHeader,
      customer,
      pbn,
      { text: 'Status' },
    ];
    return headers;
  },
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <thead class="bg-white rounded top-0 z-10">
    <tr>
      <th
        v-for="(column, index) in headers"
        :key="index"
        scope="col"
        class="
          px-2
          text-left text-sm-1
          font-primary font-medium
          text-blue
          text-md
          tracking-wider
        "
        :class="[column.class]"
      >
        {{ column.text }}
      </th>
    </tr>
  </thead>
</template>
<script>
export default {
  name: 'TableHeaders',
  props: {
    headers: {
      type: Array,
      default: () => [
        // each header item receives an object with text key mandatory - what will be displayed
        // and can also receive anything else, eg class, which will automatically be applied via :class
        { text: 'Name', class: '' },
        { text: 'Description', class: '' },
      ],
    },
    isTransportEdit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="css" scoped>
.shift-left {
  transform: translateX(-20px)
}
</style>

<template>
  <div class="flex flex-col items-center h-full p-16">
    <div class="w-full flex flex-row justify-center relative">
      <h1 class="text-center flex-1">New Transport Load</h1>
      <Button
        class="right-10 absolute"
        title="Upload"
        type="tertiary"
        thicc
        :icon="document"
        @click.native="uploadFiles"
      />
    </div>
    <p class="pt-5 text-l w-3/4 text-center">
      Please check through the documents for each vehicle and select the
      relevant ones. An automatic email will be sent to the Customs Agent.
    </p>
    <p class="mt-8 text-mdl font-semibold">
      {{ selectedVehicleNr + 1 }} of {{ totalVehicles }}
    </p>
    <div
      class="shadow w-2/3 py-5 my-5 text-l px-10 flex flex-row items-center justify-between"
    >
      <p>
        {{ vehicle.registrationNumber }}
      </p>
      <p>
        {{ `${vehicle.make} ${vehicle.model}` }}
      </p>
      <p>
        {{ vehicle.customerName }}
      </p>
      <InputField
        :id="vehicle.id"
        placeholder="Location"
        :error="locationError"
        :value="location"
        :validationTrigger="$v.location.$touch"
        :hasMargins="false"
        @changed="(ev) => (location = ev)"
      />
    </div>
    <div>
      <table
        class="text-left border-separate mb-5"
        style="border-spacing: 0 12px;"
      >
        <thead class="mb-2 text-mdl">
          <tr>
            <th class="pr-7"></th>
            <th class="text-blue font-medium">
              Name
            </th>
            <th class="text-blue font-medium">
              Type
            </th>
            <th class="text-blue font-medium">
              Upload Time
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="files.length !== 0">
          <tr v-for="(item, idx) in files" :key="idx" class="text-l">
            <td>
              <input
                type="checkbox"
                :value="item.id"
                :checked="isDocumentChecked(item.id)"
                @change="
                  (ev) =>
                    selectDocument({
                      documentId: ev.target.value,
                      vehicleId: vehicle.id
                    })
                "
              />
            </td>
            <td class="pr-12 min-w-150">{{ item.name }}</td>
            <td class="pr-12">
              {{ docType(item.type) }}
            </td>
            <td class="pr-12">
              {{ formatDate(item.created) }}
            </td>
            <td class="pr-12 cursor-pointer">
              <a :href="item.url" target="_blank">
                <img :src="download" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="files.length === 0"
        class="flex flex-col flex-grow justify-center items-center w-full"
      >
        <img src="@/assets/icons/documentBig.svg" class="mb-4 mt-20" />
        <p
          v-for="item in emptyState"
          :key="item"
          class="text-l text-grey-disabled-light-2 text-center"
        >
          {{ item }}
        </p>
      </div>
    </div>
    <div class="flex flex-row space-x-5 mt-10 items-center">
      <p class="text-l text-blue cursor-pointer" @click="backFn">
        Previous car
      </p>
      <Button title="Next Vehicle" type="primary" @click.native="nextFn" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import download from '@/assets/icons/download.svg';
import document from '@/assets/icons/document.svg';
import { documentType } from '@/services/utils/formatEnums.js';

export default {
  name: 'TransportForm',
  components: {
    Button,
    InputField: Input,
  },
  validations: {
    location: {
      required,
    },
  },
  props: {
    selectedVehicleNr: {
      type: Number,
      default: 0,
    },
    totalVehicles: {
      type: Number,
      default: 0,
    },
    vehicle: {
      type: Object,
      default: () => {},
    },
    files: {
      type: Array,
      default: () => [],
    },
    uploadFiles: {
      type: Function,
      default: () => null,
    },
    closeFn: {
      type: Function,
      default: () => null,
    },
    nextFn: {
      type: Function,
      default: () => null,
    },
    backFn: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({
    download,
    document,
    emptyState: [
      'You have no documents uploaded yet.',
      'When you add documents they will appear here.',
    ],
  }),
  computed: {
    location: {
      get() {
        return this.vehicle.location;
      },
      set(val) {
        this.addLocation(val);
      },
    },
    locationError() {
      if (this.$v.location.$invalid && this.$v.location.$dirty) {
        return 'Required.';
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      addLocation: 'vehicles/addLocation',
      selectDocument: 'loads/selectDocument',
    }),
    docType(type) {
      return documentType(type);
    },
    isDocumentChecked(id) {
      return this.files.find((el) => el.id === id).checked;
    },
    formatDate(date) {
      return moment(date).format('MMM Do, hh:mm');
    },
  },
};
</script>

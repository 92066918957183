import { apiCall } from './index';

export const fetchAnalytics = (filters) => {
  let params = '?';
  if (filters.from !== '') {
    params += `&from=${filters.from.toISOString().split('T')[0]}`;
  }
  if (filters.to !== '') {
    params += `&to=${filters.to.toISOString().split('T')[0]}`;
  }
  if (filters.company !== '') {
    params += `&companies=${filters.company}`;
  }
  return apiCall('GET', `analytics${params}`);
};

export const dummy = () => 'dummy';

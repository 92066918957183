const ukRegexSpaced = /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)/g;
const ukRegex = /(^[A-Z]{2}[0-9]{2}?[A-Z]{3}$)/g;
const linkRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
const eirCodeRegex = /^[ACDEFHKNPRTVWXY]{1}[0-9]{1}[0-9W]{1}[\\-]?[0-9ACDEFHKNPRTVWXY]{4}$/;
const countryCodeRegex = /^[+]\d{2,3}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const ukRegFormatValidator = (value) => ukRegex.test(value) || ukRegexSpaced.test(value);
export const linkFormatValidator = (value) => linkRegex.test(value);
export const eirCodeValidator = (value) => eirCodeRegex.test(value);
export const countryCodeValidator = (value) => countryCodeRegex.test(value);
export const passwordValidator = (value) => passwordRegex.test(value);

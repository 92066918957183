export const documentTypes = [{
  label: 'V5',
  value: 'V5',
}, {
  label: 'Receipt',
  value: 'RECEIPT',
}, {
  label: 'Invoice',
  value: 'INVOICE',
}, {
  label: 'SOO',
  value: 'SOO',
}, {
  label: 'SAD',
  value: 'SAD',
}, {
  label: 'Duty Payment Confirmation',
  value: 'DUTY_PAYMENT_CONFIRMATION',
}, {
  label: 'Non alteration cert',
  value: 'NON_ALTERATION_CERT',
}];

export const vehiclesStatuses = [
  {
    label: 'Awaiting Confirmation',
    value: 'AWAITING_CONFIRMATION',
  },
  {
    label: 'Purchase Agreed',
    value: 'PURCHASE_AGREED',
  },
  {
    label: 'No Purchase Agreed',
    value: 'NO_PURCHASE_AGREED',
  },
  {
    label: 'Transfer Confirmed',
    value: 'TRANSFER_CONFIRMED',
  },
  {
    label: 'Awaiting Payment',
    value: 'AWAITING_PAYMENT',
  },
  {
    label: 'Payment Received',
    value: 'PAYMENT_RECEIVED',
  },
  {
    label: 'Customs Clearance',
    value: 'CUSTOMS_CLEARENCE',
  },
  {
    label: 'Duty Due',
    value: 'DUTY_DUE',
  },
  {
    label: 'Ready for Transport',
    value: 'READY_FOR_TRANSPORT',
  },
  {
    label: 'Transporting',
    value: 'TRANSPORTING',
  },
  {
    label: 'Delivery Completed',
    value: 'DELIVERY_COMPLETED',
  },
];

export const auctionStatuses = [
  {
    label: 'Awaiting Confirmation',
    value: 'AWAITING_CONFIRMATION',
  },
  {
    label: 'Transfer Confirmed',
    value: 'TRANSFER_CONFIRMED',
  },
  {
    label: 'Awaiting Payment',
    value: 'AWAITING_PAYMENT',
  },
  {
    label: 'Payment Received',
    value: 'PAYMENT_RECEIVED',
  },
  {
    label: 'Customs Clearance',
    value: 'CUSTOMS_CLEARENCE',
  },
  {
    label: 'Duty Due',
    value: 'DUTY_DUE',
  },
  {
    label: 'Ready for Transport',
    value: 'READY_FOR_TRANSPORT',
  },
  {
    label: 'Transporting',
    value: 'TRANSPORTING',
  },
  {
    label: 'Delivery Completed',
    value: 'DELIVERY_COMPLETED',
  },
];

export const forecourtStatuses = [
  {
    label: 'Awaiting Confirmation',
    value: 'AWAITING_CONFIRMATION',
  },
  {
    label: 'Purchase Agreed',
    value: 'PURCHASE_AGREED',
  },
  {
    label: 'No Purchase Agreed',
    value: 'NO_PURCHASE_AGREED',
  },
  {
    label: 'Awaiting Payment',
    value: 'AWAITING_PAYMENT',
  },
  {
    label: 'Payment Received',
    value: 'PAYMENT_RECEIVED',
  },
  {
    label: 'Customs Clearance',
    value: 'CUSTOMS_CLEARENCE',
  },
  {
    label: 'Duty Due',
    value: 'DUTY_DUE',
  },
  {
    label: 'Ready for Transport',
    value: 'READY_FOR_TRANSPORT',
  },
  {
    label: 'Transporting',
    value: 'TRANSPORTING',
  },
  {
    label: 'Delivery Completed',
    value: 'DELIVERY_COMPLETED',
  },
];

export const loadsStatuses = [
  {
    label: 'Awaiting PBN',
    value: 'AWAITING_PBN',
  },
  {
    label: 'PBN Received',
    value: 'PBN_RECEIVED',
  },
  {
    label: 'Load Sent',
    value: 'LOAD_SENT',
  },
];

export const dummy = [];

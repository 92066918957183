<template>
  <div class>
    <div class="flex flex-wrap">
      <Input
        id="companyName"
        placeholder="Company"
        title="Company name"
        class="w-1/2"
        :value="companyName"
        :error="companyNameError"
        :validationTrigger="$v.companyName.$touch"
        @changed="(value) => (companyName = value)"
      />
      <Input
        id="companyEircode"
        title="Eircode"
        placeholder="Eircode"
        class="w-1/2"
        :value="companyEircode"
        :error="companyEircodeError"
        :validationTrigger="$v.companyEircode.$touch"
        @changed="(value) => (companyEircode = value)"
      />
    </div>
    <div>
      <Input
        id="companyAddress"
        title="Address"
        placeholder="Address"
        :value="companyAddress"
        :error="companyAddressError"
        :validationTrigger="$v.companyAddress.$touch"
        @changed="(value) => (companyAddress = value)"
      />
    </div>
    <div class="px-3 py-5">
      <DocumentsUploader :handleFiles="handleFiles" />
    </div>
    <div>
      <div
        v-for="(section, idx) in ahSections"
        :key="section.id"
        class="flex flex-row relative"
      >
        <Select
          :id="`house-${section.id}`"
          class="w-5/12"
          :options="auctionHouseOptions()"
          placeholder="Select"
          title="Auction House"
          reducedOptions
          :value="getHouseValue(section.id).house"
          @changed="(val) => setHouseValue(val, section.id, 'house')"
        />
        <Input
          :id="`houseNr-${section.id}`"
          class="flex-1"
          placeholder="Auction house number"
          title="Auction house number"
          :value="getHouseValue(section.id).houseNumber"
          @changed="(val) => setHouseValue(val, section.id, 'houseNumber')"
        />
        <p v-if="showRemove(idx)" class="text-l text-red-dark absolute cursor-pointer remove-ah" @click="removeAh(section.id)">
          Remove
        </p>
      </div>
      <p
        v-if="houses.length >= ahSections.length + 1"
        class="font-medium cursor-pointer" @click="addNewHouse">
        Add another auction house
      </p>
    </div>
  </div>
</template>
<script>
/* eslint-disable operator-linebreak */
import { mapState, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import Select from '@/components/atoms/Select.vue';
import Input from '@/components/atoms/Input.vue';
import DocumentsUploader from './partials/DocumentsUploader.vue';
import { eirCodeValidator } from '@/services/utils/regexUtils.js';

export default {
  name: 'CompanyDetails',
  components: {
    Input,
    Select,
    DocumentsUploader,
  },
  props: {
    getValue: {
      type: Function,
      default: () => {},
    },
    setValue: {
      type: Function,
      default: () => {},
    },
  },
  validations: {
    companyName: { required },
    companyEircode: { eirCodeValidator },
    companyAddress: { required },
  },
  computed: {
    ...mapState({
      ahSections: (state) => state.registration.ahSections,
      houses: (state) => state.auctionHouses.houses,
    }),
    companyName: {
      get() {
        return this.getValue('companyName');
      },
      set(value) {
        this.setValue(value);
      },
    },
    companyNameError() {
      if (this.$v.companyName.$invalid && this.$v.companyName.$dirty) {
        return 'Required.';
      }
      return null;
    },
    companyAddress: {
      get() {
        return this.getValue('companyAddress');
      },
      set(value) {
        this.setValue(value);
      },
    },
    companyAddressError() {
      if (this.$v.companyAddress.$invalid && this.$v.companyAddress.$dirty) {
        return 'Required.';
      }
      return null;
    },
    companyEircode: {
      get() {
        return this.getValue('companyEircode');
      },
      set(value) {
        this.setValue(value);
      },
    },
    companyEircodeError() {
      if (this.$v.companyEircode.$invalid && this.$v.companyEircode.$dirty) {
        return 'Invalid Format.';
      }
      return null;
    },
    invalidValidation() {
      return this.$v.$invalid;
    },
  },
  watch: {
    invalidValidation(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setStepCompletion({ stepCompletion: !this.invalidValidation });
      }
    },
  },
  methods: {
    ...mapActions({
      addNewHouse: 'registration/addNewHouse',
      setAHvalue: 'registration/setAHvalue',
      setStepCompletion: 'registration/setStepCompletion',
      removeAh: 'registration/removeAh',
    }),
    showRemove(idx) {
      return idx > 0;
    },
    setHouseValue(val, id, key) {
      this.setAHvalue({ val, id, key });
    },
    getHouseValue(id) {
      return this.ahSections.find((el) => el.id === id);
    },
    handleFiles(files) {
      this.setValue({ id: 'documents', value: files });
    },
    touchValidation() {
      this.$v.$touch();
      if (this.invalidValidation) {
        this.setStepCompletion({ stepCompletion: !this.invalidValidation });
      }
    },
    auctionHouseOptions() {
      const allHouses = this.houses && this.houses.map((house) => ({
        label: house.name,
        value: house.id,
      }));
      this.ahSections.map((ahSection) => {
        const houseIndex = allHouses.findIndex((house) => house.label === ahSection.house?.label);
        if (houseIndex !== -1) {
          allHouses.splice(houseIndex, 1);
        }
      });
      return allHouses;
    },
  },
};
</script>
<style lang="css" scoped>
.remove-ah {
  right: -80px;
  top: 30px;
}
</style>

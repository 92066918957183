<template>
  <div class="bg-white p-10 relative h-full">
    <div v-if="transport">
      <div class="absolute right-6 top-9">
        <img :src="close" class="cursor-pointer" @click="closeModal" />
      </div>
      <div class="text-center">
        <h1>Send to Transporter</h1>
        <p class="pt-5 text-l w-3/5 text-center mx-auto">
          Are you sure you want to send {{ item.pbn }} to the
          {{ item.transporter }}? Make sure that all cars have their duty paid
          or exempted.
        </p>
        <div class="w-300 mx-auto mt-10">
          <Input
            id="email"
            :value="email"
            placeholder="Email"
            :error="emailError"
            :validationTrigger="$v.email.$touch"
            @changed="(val) => (email = val)"
          />
        </div>
      </div>
      <div class="flex justify-center mt-10 overflow-y-auto">
        <table
          class="text-left border-separate"
          style="border-spacing: 32px 16px;"
        >
          <thead class="mb-2 text-mdl">
            <th class="text-blue font-medium">
              Registration
            </th>
            <th class="text-blue font-medium">
              Make & Model
            </th>
            <th class="text-blue font-medium">Company</th>
            <th class="text-blue font-medium">Duty Paid/Exempted</th>
          </thead>
          <tbody>
            <tr
              v-for="(item, idx) in transport.items"
              :key="idx"
              class="text-l"
            >
              <td class="pr-10 max-w-200 truncate">
                {{ item.registrationNumber }}
              </td>
              <td class="pr-7">
                {{ `${item.make} ${item.model}` }}
              </td>
              <td class="pr-7">
                {{ item.companyName }}
              </td>
              <td
                class="pr-7"
                :class="
                  item.dutyPaymentStatus === 'Unpaid'
                    ? 'text-red'
                    : 'text-green'
                "
              >
                {{ item.dutyPaymentStatus }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex flex-row justify-center mt-8 space-x-10">
        <Button
          title="Yes, send"
          type="primary"
          :disabled="disableSubmit"
          @clicked="send"
        />
        <Button
          title="No, cancel"
          type="simple"
          noBorder
          @clicked="closeModal"
        />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable operator-linebreak */

import { mapActions, mapState } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import close from '@/assets/icons/close.svg';

export default {
  name: 'SendToTransporter',
  components: { Button, Input },
  props: ['item', 'closeDropdown', 'closeModal'],
  data: () => ({
    close,
  }),
  validations: { email: { required, email } },
  computed: {
    ...mapState({
      transport: (state) => state.transports.transport,
      transportEmail: (state) => state.transports.email,
    }),
    email: {
      get() {
        return this.transportEmail;
      },
      set(val) {
        this.setEmail(val.value);
      },
    },
    emailError() {
      if (this.$v.email.$dirty) {
        if (!this.$v.email.required) {
          return 'Required.';
        }
        if (!this.$v.email.email) {
          return 'Invalid Format.';
        }
      }
      return null;
    },
    disableSubmit() {
      return this.$v.$invalid;
    },
  },
  async mounted() {
    await this.getTransport(this.item.id);
  },
  methods: {
    ...mapActions({
      getTransport: 'transports/getTransport',
      getTransports: 'transports/getTransports',
      sendTransport: 'transports/sendTransport',
      setEmail: 'transports/setEmail',
    }),
    async send() {
      try {
        await this.sendTransport(this.item.id);
        await this.getTransports({ next: false, prev: false });
        this.closeModal();
        this.$toast.info('Load successfuly sent!');
      } catch {
        this.$toast.error('There was an error while sending this load');
      }
    },
  },
};
</script>

import { fetchAnalytics } from '@/services/api/analytics';
import { fetchCompanies } from '@/services/api/companies';

export default {
  namespaced: true,
  state: {
    data: {},
    companies: [],
    filters: {
      from: '',
      to: '',
      company: '',
    },
  },
  actions: {
    async getCompanies({ commit }) {
      const companies = await fetchCompanies();
      const data = companies.data.map((el) => ({
        label: el,
        value: el,
      }));
      return commit('SET_COMPANIES', [{ label: 'All companies', value: '' }, ...data]);
    },
    async getAnalytics({ commit, state }) {
      const analytics = await fetchAnalytics(state.filters);
      commit('SET_ANALYTICS', analytics.data);
    },
    async setFilter({ commit, dispatch }, data) {
      commit('SET_FILTER', data);
      await dispatch('getAnalytics');
    },
    async resetFilters({ commit, dispatch }) {
      commit('RESET_FILTERS');
      await dispatch('getAnalytics');
    },
  },
  mutations: {
    SET_ANALYTICS(state, data) {
      state.data = data;
    },
    RESET_FILTERS(state) {
      Object.assign(state.filters, {
        from: '',
        to: '',
        company: '',
      });
    },
    SET_COMPANIES(state, data) {
      state.companies = data;
    },
    SET_FILTER(state, data) {
      state.filters[data.field] = data.value;
    },
  },
};

export const formatStatus = (status) => {
  switch (status) {
    case 'AWAITING_CONFIRMATION':
      return { label: 'Awaiting Confirmation', color: 'orange', description: 'Your request has been received succesfully. A member of our staff will confirm the details.' };
    case 'PURCHASE_AGREED':
      return { label: 'Purchase Agreed', color: 'green', description: 'Vehicle has been purchased succesfully.' };
    case 'NO_PURCHASE_AGREED':
      return { label: 'No Purchase Agreed', color: 'red-dark', description: 'The purchase has not been agreed.' };
    case 'TRANSFER_CONFIRMED':
      return { label: 'Transfer Confirmed', color: 'green', description: 'The transfer has been confirmed for this vehicle.' };
    case 'AWAITING_PAYMENT':
      return { label: 'Awaiting Payment', color: 'orange', description: 'There is an outstanding payment for this vehicle.' };
    case 'AWAITING_PBN':
      return { label: 'Awaiting PBN', color: 'orange', description: 'This load is waiting to hear back from the customs agent.' };
    case 'PBN_RECEIVED':
      return { label: 'PBN Received', color: 'orange', description: 'The customs details have been added. This load is ready for transport.' };
    case 'LOAD_SENT':
      return { label: 'Load Sent', color: 'green', description: 'Load has been sent to the transporter.' };
    case 'PAYMENT_RECEIVED':
      return { label: 'Payment Received', color: 'green', description: 'The funds for this vehicle have been received. Please add reference number.' };
    case 'CUSTOMS_CLEARENCE':
      return { label: 'Customs Clearance', color: 'green', description: 'The vehicle is under process of customs clearance.' };
    case 'DUTY_DUE':
      return { label: 'Duty Due', color: 'orange', description: 'Need to confirm if duty has been paid/exempted.' };
    case 'READY_FOR_TRANSPORT':
      return { label: 'Ready for Transport', color: 'green', description: 'Vehicle is ready for transport.' };
    case 'TRANSPORTING':
      return { label: 'Transporting', color: 'purple', description: 'Your vehicle is in the process of being transported.' };
    case 'DELIVERY_COMPLETED':
      return { label: 'Delivery Completed', color: 'green', description: 'Vehicle has been delivered succesfully.' };
    default:
      return '';
  }
};

export const reverseFormatStatus = (status) => {
  switch (status) {
    case 'Awaiting Confirmation':
      return 'AWAITING_CONFIRMATION';
    case 'Purchase Agreed':
      return 'PURCHASE_AGREED';
    case 'No Purchase Agreed':
      return 'NO_PURCHASE_AGREED';
    case 'Transfer Confirmed':
      return 'TRANSFER_CONFIRMED';
    case 'Awaiting Payment':
      return 'AWAITING_PAYMENT';
    case 'Awaiting PBN':
      return 'AWAITING_PBN';
    case 'PBN Received':
      return 'PBN_RECEIVED';
    case 'Load Sent':
      return 'LOAD_SENT';
    case 'Payment Received':
      return 'PAYMENT_RECEIVED';
    case 'Customs Clearance':
      return 'CUSTOMS_CLEARENCE';
    case 'Duty Due':
      return 'DUTY_DUE';
    case 'Ready for Transport':
      return 'READY_FOR_TRANSPORT';
    case 'Transporting':
      return 'TRANSPORTING';
    case 'Delivery Completed':
      return 'DELIVERY_COMPLETED';
    default:
      return '';
  }
};

export const documentType = (status) => {
  switch (status) {
    case 'V5':
      return 'V5';
    case 'Receipt':
      return 'Receipt';
    case 'Invoice':
      return 'Invoice';
    case 'SOO':
      return 'SOO';
    case 'USER_PROOF_DOCUMENTS':
      return 'User Proof Documents';
    case 'DUTY_PAYMENT_CONFIRMATION':
      return 'Duty Payment Confirmation';
    case 'SAD':
      return 'SAD';
    case 'NON_ALTERATION_CERT':
      return 'Non alteration cert';
    default:
      return '';
  }
};

export const dummy = () => 'dummy';

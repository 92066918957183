<template>
  <div class="bg-white">
    <div class="absolute right-6 top-9">
      <img :src="close" class="cursor-pointer" @click="closeModal" />
    </div>
    <div class="flex flex-col items-center justify-center">
      <p class="my-5 text-xxl text-black font-bold text-center mt-16">
        Funds Received
      </p>
      <p class="font-medium text-l text-black text-center">
        Please input the payment Reference number.
      </p>
      <Input
        id="referenceNumber"
        :value="referenceNumber"
        class="mt-12"
        @changed="setReferenceNumber"
      />
      <Button
        title="Submit"
        class="mt-8 mb-16"
        type="primary"
        :isDisabled="!disableSubmit"
        @click.native="save"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import close from '@/assets/icons/close.svg';
import Input from '../../atoms/Input.vue';
import Button from '../../atoms/Button.vue';

export default {
  name: 'FundsReceivedModal',
  components: { Input, Button },
  data: () => ({ close, referenceNumber: null }),
  computed: {
    ...mapState({ id: (state) => state.statusActions.objectId }),
    disableSubmit() {
      return !!this.referenceNumber;
    },
  },
  methods: {
    ...mapActions({
      triggerStatusUpdate: 'statusActions/triggerStatusUpdate',
      setPaymentReferenceNumber: 'vehicles/setPaymentReferenceNumber',
    }),
    closeModal() {
      this.$modal.hide('action-modal');
    },
    setReferenceNumber(value) {
      this.referenceNumber = value.value;
    },
    save() {
      Promise.resolve(
        this.setPaymentReferenceNumber({
          id: this.id,
          paymentReferenceNumber: this.referenceNumber,
        }),
      ).then((rsp) => {
        if (rsp.data.paymentReference) {
          this.triggerStatusUpdate();
          this.closeModal();
        }
      });
    },
  },
};
</script>

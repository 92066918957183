import Vue from 'vue';
import {
  inviteCustomer,
  getUsers,
  updateCustomer,
  getCustomerDocuments,
  uploadCustomerDocument,
} from '@/services/api/customers.js';
import { deleteDocument } from '@/services/api/documents';
import idGenerator from '@/services/utils/idGenerator.js';

export default {
  namespaced: true,
  state: {
    data: {
      email: '',
      customers: [],
      pagination: {
        totalItems: 0,
        totalPages: null,
        limit: 4,
        pageNr: 0,
      },
    },
    customer: {
      countryCode: '+',
    },
    ahSections: [{ house: null, houseNumber: '', id: 1 }],
    search: '',
    documents: [],
    newCustomerDocuments: [],
  },
  actions: {
    inviteCustomer({ state }) {
      return inviteCustomer(state.data.email);
    },
    updateField({ commit }, data) {
      commit('UPDATE_FIELD', data);
    },
    updateCustomerField({ commit }, data) {
      commit('UPDATE_CUSTOMER', data);
    },
    getUser({ commit }, data) {
      commit('SET_USER', { data });
      commit('SET_SECTIONS', data.auctionHouses);
    },
    async resetPagination({ commit }) {
      await commit('RESET_PAGINATION');
    },
    resetSearch({ commit }) {
      commit('SET_SEARCH', '');
    },
    setSearch({ commit }, search) {
      commit('SET_SEARCH', search);
    },
    addNewHouse({ commit }) {
      commit('ADD_NEW_HOUSE');
    },
    setAHvalue({ state, commit }, { val, id, key }) {
      const ahHouse = state.ahSections.find((sections) => sections.ourId === id);
      const ahIndex = state.ahSections.findIndex(
        (section) => section.ourId === id,
      );
      if (key === 'house') {
        ahHouse.house = val.value.label;
        ahHouse.id = val.value.value;
      } else {
        ahHouse[key] = val.value;
      }
      commit('SET_HOUSE', { ahHouse, ahIndex });
    },
    removeAh({ commit }, id) {
      commit('REMOVE_AH', id);
    },
    getCustomers({ commit, state }, { next, prev }) {
      if (
        next
        && state.data.pagination.pageNr <= state.data.pagination.totalPages
      ) {
        commit('INCREMENT_PAGE');
      }
      if (
        prev
        && state.data.pagination.pageNr <= state.data.pagination.totalPages
      ) {
        commit('DECREMENT_PAGE');
      }
      return getUsers({
        limit: state.data.pagination.limit,
        offset: state.data.pagination.pageNr * state.data.pagination.limit,
      },
      state.search).then((rsp) => {
        commit('SET_TOTAL_ITEMS', rsp.data.totalCount);
        commit('SET_USERS', { users: rsp.data.items, next, prev });
      });
    },
    async searchCustomers({ dispatch }, { search }) {
      await dispatch('resetPagination');
      await dispatch('setSearch', search);
      await dispatch('getCustomers', { next: false, prev: false });
    },
    async updateCustomer({ state, dispatch }) {
      const formattedActionHouses = state.ahSections.map((el) => ({
        auctionHouseAccountNumber: el.houseNumber,
        auctionHouseId: el.id,
      }));
      const payload = {
        ...state.customer,
        auctionHouses: formattedActionHouses,
      };
      await updateCustomer(state.customer.id, { ...payload });
      return dispatch('getCustomers', { next: false, prev: false });
    },
    getCustomerDocuments({ commit }, { id }) {
      return getCustomerDocuments(id).then((rsp) => {
        commit('LOAD_DOCUMENTS', { files: rsp.data });
        return rsp.data;
      });
    },
    addDocument({ commit }, { files, vehicleId }) {
      commit('ADD_DOCUMENT', { files, vehicleId });
    },
    deleteDocument({ commit }, { id }) {
      return deleteDocument(id).then(() => {
        commit('DELETE_DOCUMENT', id);
      });
    },
    uploadDocument({ commit }, { customer, document, type }) {
      const formData = new FormData();
      formData.append('document', document);
      formData.append('type', type);
      return uploadCustomerDocument(formData, customer.userId).then((rsp) => {
        commit('ADD_DOCUMENT_TO_CUSTOMER', { ...rsp.data }); return rsp;
      });
    },
    clearNewCustomerDocuments({ commit }, { customerId, documentId }) {
      commit('CLEAR_NEW_CUSTOMER_DOCUMENTS', { customerId, documentId });
    },
    changeDocumentType({ commit }, { documentId, vehicleId, documentType }) {
      commit('CHANGE_DOCUMENT_TYPE', { documentId, vehicleId, documentType });
    },
  },
  getters: {
    getRows: (state) => state.data.customers.map((customer, idx) => [
      {
        text:
            idx
            + (state.data.pagination.pageNr * state.data.pagination.limit + 1),
        class: 'text-center',
      },
      { text: `${customer.firstName} ${customer.lastName}` },
      { text: customer.emailAddress },
      { text: `${customer.countryCode ? customer.countryCode : ''}${customer.phoneNumber}` },
      {
        text: customer.companyName,
      },
      { type: 'CUSTOMER_MENU', menuItem: customer },
    ]),
    getHeaders: () => [
      { text: 'No.', class: 'w-40 pr-0' },
      { text: 'Name' },
      { text: 'Email' },
      { text: 'Phone' },
      { text: 'Company' },
      { text: '' },
    ],
  },
  mutations: {
    UPDATE_FIELD(state, { value, field }) {
      state.data[field] = value;
    },
    UPDATE_CUSTOMER(state, { value, field }) {
      state.customer[field] = value;
    },
    SET_USERS(state, { users }) {
      state.data.customers = [...users];
    },
    SET_USER(state, { data }) {
      state.customer = { ...state.customer, ...data };
    },
    SET_SEARCH(state, search) {
      state.search = search;
    },
    RESET_PAGINATION(state) {
      state.data.pagination.totalPages = null;
      state.data.pagination.pageNr = 0;
    },
    SET_TOTAL_ITEMS(state, count) {
      state.data.pagination.totalPages = Math.ceil(
        count / state.data.pagination.limit,
      );
      state.data.pagination.totalItems = count;
    },
    INCREMENT_PAGE(state) {
      state.data.pagination.pageNr += 1;
    },
    DECREMENT_PAGE(state) {
      state.data.pagination.pageNr -= 1;
    },
    REMOVE_AH(state, id) {
      const newHouses = state.ahSections.filter((ah) => ah.ourId !== id);
      state.ahSections = newHouses;
    },
    ADD_NEW_HOUSE(state) {
      state.ahSections.push({
        house: null, houseNumber: '', id: null, ourId: idGenerator(),
      });
    },
    SET_HOUSE(state, payload) {
      Vue.set(state.ahSections, payload.ahIndex, payload.ahHouse);
    },
    SET_SECTIONS(state, houses) {
      if (houses.length > 0) {
        const formattedHouses = houses.map((el) => ({
          house: el.auctionHouseName,
          houseNumber: el.auctionHouseAccountNumber,
          id: el.auctionHouseId,
          ourId: idGenerator(),
        }));
        state.ahSections = formattedHouses;
      }
    },
    LOAD_DOCUMENTS(state, { files }) {
      state.documents = files;
    },
    DELETE_DOCUMENT(state, id) {
      const index = state.documents.findIndex((el) => el.id === id);
      state.documents.splice(index, 1);
    },
    ADD_DOCUMENT(state, { files, vehicleId }) {
      const filesArray = Array.from(files);
      const formattedFiles = filesArray.map((el) => ({
        file: el,
        name: el.name,
        type: '',
        id: idGenerator(),
      }));
      if (state.newCustomerDocuments[vehicleId]) {
        const docs = state.newCustomerDocuments[vehicleId].documents;
        docs.push(...formattedFiles);
        Vue.set(state.newCustomerDocuments[vehicleId], 'documents', docs);
      } else {
        Vue.set(state.newCustomerDocuments, vehicleId, {});
        Vue.set(
          state.newCustomerDocuments[vehicleId],
          'documents',
          formattedFiles,
        );
      }
    },
    CHANGE_DOCUMENT_TYPE(state, { documentId, vehicleId, documentType }) {
      const doc = state.newCustomerDocuments[vehicleId].documents.find(
        (el) => el.id.toString() === documentId.toString(),
      );
      const newDoc = {
        ...doc,
        type: documentType.value,
      };
      Object.assign(doc, newDoc);
    },
    CLEAR_NEW_CUSTOMER_DOCUMENTS(state, { customerId, documentId }) {
      if (!documentId) {
        if (state.newCustomerDocuments[customerId]?.documents) {
          state.newCustomerDocuments[customerId].documents = [];
        }
      } else {
        const index = state.newCustomerDocuments[customerId].documents.findIndex((item) => item.id === documentId);
        state.newCustomerDocuments[customerId].documents.splice(index, 1);
      }
    },
    ADD_DOCUMENT_TO_CUSTOMER(state, document) {
      state.documents.push(document);
      state.newCustomerDocuments = [];
    },
  },
};

<template>
  <tbody>
    <tr
      v-for="(row, rowIdx) in rows"
      :key="rowIdx"
      class="transition-all shadow bg-white my-5"
    >
      <td
        v-for="(column, itemIdx) in row"
        :key="column.menuItem ? column.menuItem.status : itemIdx"
        class="px-2 py-5 whitespace-nowrap"
        :class="column.parentClass"
      >
        <component
          :is="cellContent(column)"
          :item="column"
          :options="column.options"
        />
      </td>
    </tr>
  </tbody>
</template>

<script>
/* eslint-disable */
import DefaultCell from './Cells/DefaultCell.vue';
import CurrencyCell from './Cells/CurrencyCell.vue';
import TooltipCell from './Cells/TooltipCell.vue';
import MenuCell from './Cells/MenuCell.vue';
import CheckboxCell from './Cells/CheckboxCell.vue';
import SelectorCell from './Cells/SelectorCell.vue';

export default {
  name: 'TableColumns',
  props: {
    rows: {
      type: Array,
      default: () => [
        // each item from a row receives an object with text key mandatory - what will be displayed
        // and can also receive anything else, eg class, which will automatically be applied via :class
        [{ text: 'something', class: '' }, { text: 'something else' }],
        [{ text: 'something', anyKey: '' }, { text: 'something else' }],
      ],
    },
    isTransportEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cellContent(item) {
      let component = null;
      // What the fuck? Ident rule is messed up
      switch (item.type) {
        case 'CURRENCY':
          component = CurrencyCell;
          break;
        case 'TOOLTIP':
          component = TooltipCell;
          break;
        case 'MENU':
          component = MenuCell;
          break;
        case 'CHECKBOX':
          component = CheckboxCell;
          break;
        case 'CUSTOMER_MENU':
          component = MenuCell;
          break;
        case 'SELECTOR':
          component = SelectorCell;
          break;
        default:
          component = DefaultCell;
          break;
      }
      return component;
    },
  },
};
</script>
<style lang="css" scoped>
.shift-left > td {
  transform: translateX(-20px);
}
</style>
<template>
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.06878 8.36223C9.37397 8.36223 11.2427 6.49351 11.2427 4.18832C11.2427 1.88313 9.37397 0.0144043 7.06878 0.0144043C4.76359 0.0144043 2.89487 1.88313 2.89487 4.18832C2.89717 6.49255 4.76455 8.35993 7.06878 8.36223ZM7.06867 6.9709C5.53187 6.9709 4.28606 5.72508 4.28606 4.18829C4.28721 3.83738 4.35668 3.49005 4.49058 3.16568C4.51002 3.11896 4.5485 3.08276 4.59632 3.06621C4.64456 3.05003 4.69747 3.05588 4.74101 3.08221C5.77609 3.70266 6.96032 4.03015 8.1671 4.02968C8.65864 4.02936 9.14862 3.9743 9.62797 3.86551C9.67578 3.85517 9.72573 3.86553 9.76548 3.89402C9.80524 3.92251 9.83109 3.96648 9.83667 4.01507C9.8447 4.0725 9.84958 4.13033 9.85128 4.18829C9.85128 5.72508 8.60546 6.9709 7.06867 6.9709ZM0.459961 15.6665C0.464178 12.0184 3.42052 9.06206 7.06866 9.05784C10.7168 9.06206 13.6731 12.0184 13.6774 15.6665C13.6774 15.8586 13.5216 16.0144 13.3295 16.0144H0.807787C0.615688 16.0144 0.459961 15.8586 0.459961 15.6665Z"
      :fill="color"
    />
  </svg>
</template>
<script>
/* eslint-disable */
export default {
  name: "CustomersIcon",
  props: {
    iconColor: {
      type: String,
      default: "#FF7B00",
    },
  },
  computed: {
    color() {
      switch (this.iconColor) {
        case "grey":
          return "#B2B2B2";
        case "blue":
          return "#047ABD";
        default:
          break;
      }
      return null;
    },
  },
};
</script>

<template>
  <div>
    <StatusTooltip :label="label" :description="description" :color="color" />
  </div>
</template>

<script>
import { formatStatus } from '@/services/utils/formatEnums';
import StatusTooltip from '@/components/atoms/StatusTooltip.vue';

export default {
  name: 'TooltipCell',
  components: { StatusTooltip },
  props: ['item'],
  data: () => ({ formatStatus }),
  computed: {
    label() {
      return this.formatStatus(this.item.status).label;
    },
    description() {
      return this.formatStatus(this.item.status).description;
    },
    color() {
      return this.formatStatus(this.item.status).color;
    },
  },
};
</script>

import { apiCall } from './index';

export const getVehicles = (type, pagination, searchParam, filters, availableForLoad) => {
  let url = 'vehicle?';
  if (pagination) {
    url += `&limit=${pagination.limit}&offset=${pagination.offset}`;
  }
  if (searchParam && searchParam !== '') {
    url += `&keyword=${searchParam}`;
  }
  if (filters && filters.status !== '') {
    url += `&vehicleStatus=${filters.status}`;
  }
  if (filters && filters.type !== '') {
    url += `&purchaseType=${filters.type}`;
  }
  if (type) {
    url += `&purchaseType=${type.toUpperCase()}`;
  }
  if (availableForLoad) {
    url += '&availableForLoad=true';
  }
  return apiCall('GET', url);
};

export const createVehicle = (payload) => apiCall('POST', 'vehicle', payload);

export const updateVehicle = (vehicle) => {
  const body = { ...vehicle };
  return apiCall('PUT', 'vehicle', body);
};

export const fetchVehicle = (id) => apiCall('GET', `vehicle/${id}`);

export const uploadVehicleDocument = (files, id) => apiCall('POST', `vehicle/${id}/document`, files);

export const setCustomerDutyPayment = (id) => apiCall('POST', `vehicle/${id}/customer/payment`);

export const deleteVehicle = (id) => apiCall('DELETE', `vehicle/${id}`);

export const updateVehicleStatus = (id, status) => {
  const body = { status };
  return apiCall('PATCH', `vehicle/${id}`, body);
};

export default {
  namespaced: true,
  state: {
    openModal: false,
    action: null,
    actionFor: 'VEHICLE',
    objectId: null,
    targetStatus: null,
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    toggleModal({ commit }, { open }) {
      commit('SET_OPEN_MODAL', open);
    },
    setAction({ commit }, { actionName }) {
      commit('SET_ACTION', actionName);
    },
    setActionFor({ commit }, { type }) {
      commit('SET_ACTION_FOR', type);
    },
    setObjectId({ commit }, { id }) {
      commit('SET_OBJECT_ID', id);
    },
    setTargetStatus({ commit }, { status }) {
      commit('SET_TARGET_STATUS', status);
    },
    triggerStatusUpdate({ dispatch, state }) {
      if (state.actionFor === 'VEHICLE') {
        dispatch('vehicles/updateStatus', { id: state.objectId, status: state.targetStatus }, { root: true });
      } else { dispatch('transports/updateStatus', { id: state.objectId, status: state.targetStatus }, { root: true }); }
    },
  },
  mutations: {
    resetState(state) {
      state.openModal = false;
      state.action = null;
      state.objectId = null;
      state.targetStatus = null;
    },
    SET_OPEN_MODAL(state, open) {
      state.openModal = open;
    },
    SET_ACTION(state, actionName) {
      state.action = actionName;
    },
    SET_ACTION_FOR(state, type) {
      state.actionFor = type;
    },
    SET_OBJECT_ID(state, id) {
      state.objectId = id;
    },
    SET_TARGET_STATUS(state, status) {
      state.targetStatus = status;
    },
  },
  getters: {
  },
};

<template>
  <div class="bg-white">
    <div class="absolute right-6 top-9">
      <img :src="close" class="cursor-pointer" @click="closeModal" />
    </div>
    <div v-if="currentVehicle" class="flex flex-col items-center justify-center">
      <p class="my-5 text-xxl text-black font-bold text-center mt-16">
        Confirm Duty Paid
      </p>
      <p class="font-medium text-l text-black text-center">
        Confirm if duty has been paid.
      </p>
      <div v-if="!currentVehicle.customerDutyPaid">
        <p class="font-medium text-l text-black text-center mt-10">
          Duty payment not confirmed.
        </p>
      </div>
      <div v-else>
        <div v-if="file" class="flex mt-10 items-center">
          <div
            class="px-4 py-2 border-4 border-blue border-dashed min-w-250 rounded mr-4"
          >
            <p class="flex">
              <img :src="document" class="pr-5" height="12" />
              {{ file.name }}
            </p>
          </div>
          <a :href="file.url" target="_blank">
            <img :src="download" />
          </a>
        </div>
        <div v-else>
          <p class="font-medium text-l text-black text-center mt-10">
            No files in here.
          </p>
        </div>
      </div>
      <Check
        v-if="currentVehicle.customerDutyPaid"
        label="Confirm Duty Payment"
        class="mt-20"
        :active="confirmDutyPayment"
        @click.native="toggleConfirmDutyPayment"
      />
      <Button
        title="Confirm"
        class="mt-12 mb-16"
        type="primary"
        :disabled="!confirmDutyPayment"
        @click.native="save"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable comma-dangle */
import { mapActions, mapState } from 'vuex';
import document from '@/assets/icons/document.svg';
import download from '@/assets/icons/download.svg';
import close from '@/assets/icons/close.svg';
import Button from '../../atoms/Button.vue';
import Check from '@/components/atoms/Check.vue';

export default {
  name: 'DutyPaymentModal',
  components: { Button, Check },
  data: () => ({
    document,
    download,
    close,
    currentVehicle: null,
    confirmDutyPayment: false,
  }),
  computed: {
    ...mapState({
      id: (state) => state.statusActions.objectId,
      allVehicles: (state) => state.vehicles.items,
    }),
    file() {
      return this.currentVehicle?.documents.find(
        (item) => item.type === 'DUTY_PAYMENT_CONFIRMATION'
      );
    },
  },
  mounted() {
    this.currentVehicle = this.allVehicles.find(
      (item) => item.id.toString() === this.id.toString()
    );
  },
  methods: {
    ...mapActions({
      setDutyPaid: 'vehicles/setDutyPaid',
      triggerStatusUpdate: 'statusActions/triggerStatusUpdate',
    }),
    closeModal() {
      this.$modal.hide('action-modal');
    },
    toggleConfirmDutyPayment() {
      this.confirmDutyPayment = !this.confirmDutyPayment;
    },
    save() {
      Promise.resolve(
        this.setDutyPaid({
          id: this.id,
          dutyPaid: this.confirmDutyPayment,
        })
      ).then((rsp) => {
        if (rsp.data.dutyPaid) {
          this.triggerStatusUpdate();
          this.closeModal();
        }
      });
    },
  },
};
</script>

<template>
  <div id="app" class="flex flex-col min-h-screen">
    <Header />
    <Loader />
    <div class="xl:container xl:mx-auto h-full">
      <router-view />
      <ModalHolder />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/molecules/Footer.vue';
import Header from '@/components/molecules/Header.vue';
import Loader from '@/components/atoms/Loader.vue';
import ModalHolder from './components/molecules/ModalHolder.vue';

export default {
  name: 'App',
  components: {
    Footer,
    Header,
    Loader,
    ModalHolder,
  },
};
</script>

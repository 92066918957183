<template>
  <div
    class="flex items-center text-l font-primary text-grey-dark-5 font-light"
    :class="[item.class]"
  >
    <p class="w-full">{{ item.text | toCurrency }}</p>
  </div>
</template>

<script>
export default {
  name: 'CurrencyCell',
  props: ['item'],
};
</script>

<template>
  <div>
    <table class="w-full border-separate" style="border-spacing: 0 26px;">
      <TableHeaders :headers="headers" :isTransportEdit="isTransportEdit" />
      <TableColumns
        v-if="rows.length !== 0"
        :rows="rows"
        :isTransportEdit="isTransportEdit"
      />
    </table>
    <div
      v-if="rows.length === 0"
      class="flex flex-col flex-grow justify-center items-center w-full"
    >
      <img src="@/assets/icons/empty.svg" class="mb-4 mt-20" />
      <p
        v-for="item in emptyState"
        :key="item"
        class="text-l text-grey-disabled-light-2 text-center"
      >
        {{ item }}
      </p>
    </div>
  </div>
</template>

<script>
import TableColumns from './components/TableColumns.vue';
import TableHeaders from './components/TableHeaders.vue';

export default {
  name: 'Table',
  components: {
    TableHeaders,
    TableColumns,
  },
  props: {
    headers: {
      type: Array,
      default: () => null,
    },
    rows: {
      type: Array,
      default: () => null,
    },
    isTransportEdit: {
      type: Boolean,
      default: false,
    },
    emptyState: {
      type: Array,
    },
  },
};
</script>

<template>
  <div class="m-10 mt-auto  flex flex-row">
    <div class="flex justify-start w-1/2">
      <a class="text-blue" href="/">© {{ currentYear }} Portnetic</a>
    </div>
    <span class="flex justify-end w-1/2 text-right align-right flex-row">
      <a class="text-blue flex flex-row cursor-pointer" target="_blank" :href="userGuideLink">User Guide
        <img :src="questionIcon" width="20px" class="cursor-pointer pl-1"/>
      </a>
    </span>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import questionIcon from '@/assets/icons/question.svg';

export default {
  name: 'Footer',
  data: () => ({
    questionIcon,
  }),
  computed: {
    ...mapState({
      userGuideLink: (state) => state.user.userGuideLink,
    }),
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<template>
  <div class="flex justify-center items-center flex-col p-20">
    <div class="absolute right-6 top-4">
      <img :src="close" class="cursor-pointer" @click="closeDropdown" />
    </div>
    <h1>Remove Vehicle</h1>
    <div class="my-6 mb-10">Are you sure you want to remove this vehicle from the list?</div>
    <div class="flex flex-row mt-10">
      <Button
        title="Remove"
        class="bg-red-dark border-red-dark"
        type="primary"
        @click.native="remove"
      />
      <p class="flex flex-col justify-center text-center items-center font-bold text-blue ml-10 cursor-pointer" @click="closeDropdown">Cancel</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import close from '@/assets/icons/close.svg';
import Button from '@/components/atoms/Button.vue';

export default {
  name: 'RemoveVehicle',
  components: { Button },
  props: ['id', 'closeDropdown'],
  data: () => ({
    close,
  }),
  methods: {
    ...mapActions({
      removeVehicle: 'vehicles/removeVehicle',
    }),
    remove() {
      this.removeVehicle(this.id);
      this.closeDropdown();
    },
  },
};
</script>

<style>

</style>

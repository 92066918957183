import { apiCall } from './index';

export const inviteCustomer = (email) => apiCall('POST', 'user/invitation', { value: email });

export const updateCustomer = (userId, payload) => apiCall('PUT', `users/${userId}`, { ...payload });

export const getUsers = (pagination, searchParam) => {
  let url = 'users?';
  if (pagination) {
    url += `&limit=${pagination.limit}&offset=${pagination.offset}`;
  }
  if (searchParam && searchParam !== '') {
    url += `&keyword=${searchParam}`;
  }
  return apiCall('GET', url);
};

export const getCustomerDocuments = (customerId) => apiCall('GET', `user/${customerId}/document`);

export const uploadCustomerDocument = (files, id) => apiCall('POST', `user/${id}/document`, files);

export const dummy = 'dummy';

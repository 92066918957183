<template>
  <div class="flex flex-row items-center justify-between">
    <div class="w-300">
      <Select
        id="company"
        placeholder="Select company"
        title="Company"
        :options="companies"
        :value="company"
        :searchable="true"
        @changed="(val) => (company = val.value)"
      />
    </div>
    <div class="flex flex-row space-x-5">
      <DatePicker
        id="from"
        placeholder="dd/mm/yyyy"
        title="From:"
        :value="from"
        :disabledDates="disabledFrom"
        @changed="(val) => (from = val)"
      />
      <DatePicker
        id=" to"
        placeholder="dd/mm/yyyy"
        title="To:"
        :value="to"
        :disabledDates="disabledTo"
        @changed="(val) => (to = val)"
      />
    </div>
    <div class="w-300" >
      <Button title="Clear Filters" class="ml-auto" type="primary" @clicked="resetFilters" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DatePicker from '@/components/atoms/DatePicker.vue';
import Select from '@/components/atoms/Select.vue';
import Button from '@/components/atoms/Button.vue';

export default {
  name: 'AnalyticsHeader',
  components: {
    DatePicker,
    Select,
    Button,
  },
  computed: {
    ...mapState({
      filters: (state) => state.analytics.filters,
      companies: (state) => state.analytics.companies,
    }),
    disabledFrom() {
      return {
        from: new Date(),
      };
    },
    disabledTo() {
      return {
        from: new Date(),
        to: this.filters.from,
      };
    },
    from: {
      get() {
        return this.filters.from;
      },
      set(value) {
        this.setFilter({ value, field: 'from' });
      },
    },
    to: {
      get() {
        return this.filters.to;
      },
      set(value) {
        this.setFilter({ value, field: 'to' });
      },
    },
    company: {
      get() {
        return this.filters.company;
      },
      set(value) {
        this.setFilter({ value: value.value, field: 'company' });
      },
    },
  },
  methods: {
    ...mapActions({
      setFilter: 'analytics/setFilter',
      resetFilters: 'analytics/resetFilters',
    }),
  },
};
</script>

<style></style>

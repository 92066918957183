<template>
    <button
      :class="[
        classes[type],
        variantClass[variant],
        thicc ? 'border-2' : noBorder ? 'border-0' : 'border',
        textCenter && 'justify-center'
      ]"
      class="flex flex-row items-center max-h-btn text-center"
      :disabled="isDisabled"
      @click="$emit('clicked')"
    >
      <img v-if="icon" :src="icon" class="pr-3" />{{ title }}
    </button>
</template>
<script>
export default {
  name: 'Button',
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    thicc: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    textCenter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        primary:
          'bg-blue hover:bg-blue-700 text-white font-medium py-2.5 px-4 rounded border-blue',
        secondary:
          'bg-blue-500 hover:bg-blue-700 text-blue font-medium py-2.5 px-4 rounded',
        tertiary:
          'bg-blue-500 border-blue rounded hover:bg-blue-700 text-blue font-medium py-2.5 px-4 rounded',
        simple: 'text-blue py-2 px-4 font-medium',
        white: 'text-grey-lighter border-grey-serach-box py-2.5 px-4 rounded font-medium',
        '': '',
      };
    },
    variantClass() {
      return {
        wide: 'px-8',
      };
    },
  },
};
</script>

<style scoped>
button:disabled {
  background-color: #b2b2b2;
  pointer-events: none;
  border-color: #b2b2b2;
}
</style>

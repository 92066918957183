import moment from 'moment';
import Vue from 'vue';
import {
  getTransports, updateTransportStatus, getTransport, sendTransport,
} from '@/services/api/transports';
import { formatStatus } from '@/services/utils/formatEnums';
import { updateLoad } from '@/services/api/loads.js';

const getDefaultState = () => ({
  items: null,
  pagination: {
    totalItems: 0,
    totalPages: null,
    limit: 4,
    pageNr: 0,
  },
  filters: {
    status: '',
  },
  rows: [],
  search: '',
  email: '',
  transport: null,
});

const actions = {
  resetTransportActions({ commit, dispatch }) {
    dispatch('setEmail', '');
    commit('SET_TRANSPORT', null);
  },
  resetTransportsState({ commit }) {
    commit('resetState');
  },
  updateField({ commit }, data) {
    commit('UPDATE_FIELD', data);
  },
  setPBN({ state }, { id, pbn }) {
    let transport = state.items.find((item) => item.id.toString() === id.toString());
    transport = { ...transport, pbn };
    return updateLoad(transport);
  },
  setFilter({ commit }, filter) {
    commit('SET_FILTER', filter);
  },
  setEmail({ commit }, email) {
    commit('SET_EMAIL', email);
  },
  resetFilter({ commit }) {
    commit('RESET_FILTERS');
  },
  sendTransport({ state }, id) {
    return sendTransport(id, state.email);
  },
  async resetPagination({ commit }) {
    await commit('RESET_PAGINATION');
  },
  resetSearch({ commit }) {
    commit('SET_SEARCH', '');
  },
  clearRows({ commit }) {
    commit('CLEAR_ROWS');
  },
  async getTransport({ commit }, id) {
    const transport = await getTransport(id);
    return commit('SET_TRANSPORT', transport.data);
  },
  updateStatus({ dispatch }, { id, status }) {
    return updateTransportStatus(id, status).then(async () => { await dispatch('getTransports', { next: false, prev: false }); });
  },
  async searchLoads({ dispatch }, { search }) {
    await dispatch('resetPagination');
    await dispatch('setSearch', search);
    await dispatch('getTransports', { next: false, prev: false });
  },
  async filterTransports({ dispatch }, { filter }) {
    await dispatch('resetPagination');
    await dispatch('setFilter', filter);
    await dispatch('getTransports', { next: false, prev: false });
  },
  setSearch({ commit }, search) {
    commit('SET_SEARCH', search);
  },
  getTransports({ commit, state }, { next, prev }) {
    if (next && state.pagination.pageNr <= state.pagination.totalPages) {
      commit('INCREMENT_PAGE');
    }
    if (prev && state.pagination.pageNr <= state.pagination.totalPages) {
      commit('DECREMENT_PAGE');
    }
    return getTransports({
      limit: state.pagination.limit,
      offset: state.pagination.pageNr * state.pagination.limit,
    }, state.filters, state.search).then((rsp) => {
      commit('SET_TOTAL_ITEMS', rsp.data.totalCount);
      commit('SET_TRANSPORTS', { items: rsp.data.items });
      commit('SET_ROWS');
    });
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  RESET_FILTERS(state) {
    Object.assign(state.filters, {
      status: '',
    });
  },
  SET_FILTER(state, filter) {
    if (state.filters[filter.type.toLowerCase()] === filter.value) {
      Vue.set(state.filters, filter.type.toLowerCase(), '');
    } else {
      Vue.set(state.filters, filter.type.toLowerCase(), filter.value);
    }
  },
  // pagination
  RESET_PAGINATION(state) {
    state.pagination.totalPages = null;
    state.pagination.pageNr = 0;
  },
  SET_TOTAL_ITEMS(state, count) {
    state.pagination.totalPages = Math.ceil(count / state.pagination.limit);
    state.pagination.totalItems = count;
  },
  INCREMENT_PAGE(state) {
    state.pagination.pageNr += 1;
  },
  DECREMENT_PAGE(state) {
    state.pagination.pageNr -= 1;
  },
  SET_SEARCH(state, search) {
    state.search = search;
  },
  SET_EMAIL(state, email) {
    state.email = email;
  },
  UPDATE_FIELD(state, { value, field, type }) {
    state[type][field] = value;
  },
  SET_TRANSPORTS(state, { items }) {
    Vue.set(state, 'items', [...items]);
  },
  SET_TRANSPORT(state, transport) {
    Vue.set(state, 'transport', transport);
  },
  CLEAR_ROWS(state) {
    state.rows = [];
  },
  SET_ROWS(state) {
    state.rows = state.items.map((transport, idx) => [
      {
        text: `${idx + ((state.pagination.pageNr * state.pagination.limit) + 1)}.`,
        class: 'text-center',
      },
      { text: transport.items.length },
      { text: transport.transporter },
      { text: moment(transport.transportingDate).format('MMM Do, hh:mm') },
      {
        text: transport.pbn,
      },
      {
        text: transport.destination,
      },
      {
        text: formatStatus(transport.status).label,
        type: 'SELECTOR',
        object: transport,
        disableDropdown: transport.nextStatus.length === 0 || transport.nextStatus.some((el) => el.status === 'LOAD_SENT'),
        class: `text-${formatStatus(transport.status).color}`,
      },
      { type: 'TOOLTIP', status: transport.status },
      { type: 'MENU', menuItem: { isTransport: true, ...transport } },
    ]);
  },
};

const getters = {
  getHeaders: () => [
    { text: 'No.', class: 'w-40 pr-0' },
    { text: 'No. of Cars', class: 'w-32 pr-0' },
    { text: 'Transporter' },
    { text: 'Transporter Date' },
    { text: 'PBN' },
    { text: 'Destination' },
    { text: 'Status' },
  ],
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <div class="flex flex-col items-center h-full p-16">
    <h1>New Customer</h1>
    <p class="pt-5 text-l w-3/5 text-center">
      Please enter the email address of the new customer to send them an
      invitiation to join Portnetic
    </p>
    <div class="w-300 mx-auto mt-10">
      <Input
        id="email"
        :value="email"
        placeholder="Email"
        :error="emailError"
        :validationTrigger="$v.email.$touch"
        @changed="(val) => (email = val)"
      />
    </div>
    <Button
      title="Send Request"
      class="mt-auto"
      type="primary"
      :disabled="disableSubmit"
      @click.native="save"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import Input from '@/components/atoms/Input.vue';
import Button from '@/components/atoms/Button.vue';

export default {
  name: 'InviteCustomerForm',
  components: {
    Input,
    Button,
  },
  props: {
    closeFn: {
      type: Function,
      default: () => null,
    },
  },
  validations: { email: { required, email } },
  computed: {
    ...mapState({
      data: (state) => state.customers.data,
    }),
    email: {
      get() {
        return this.data.email;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
        });
      },
    },
    emailError() {
      if (this.$v.email.$dirty) {
        if (!this.$v.email.required) {
          return 'Required.';
        }
        if (!this.$v.email.email) {
          return 'Invalid Format.';
        }
      }
      return null;
    },
    disableSubmit() {
      return this.$v.$invalid;
    },
  },
  methods: {
    ...mapActions({
      updateField: 'customers/updateField',
      inviteCustomer: 'customers/inviteCustomer',
    }),
    isValid() {
      return !this.emailError;
    },
    async save() {
      await this.inviteCustomer();
      this.closeFn();
    },
  },
};
</script>

<template>
  <div class="flex flex-col items-center h-full p-16">
    <h1>Invite Link</h1>
    <p class="pt-5 text-l w-3/5 text-center">
      Send an invite link to a Customer or a Staff Member?
    </p>
    <div class="flex flex-row space-x-5 mx-auto mt-20">
      <Button
        title="Staff Member"
        type="simple"
        noBorder
        @click.native="goToStaff"
      />
      <Button
        title="Customer"
        type="primary"
        @click.native="goToCustomer"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/atoms/Button.vue';

export default {
  name: 'InviteCustomerForm',
  components: {
    Button,
  },
  props: {
    closeFn: {
      type: Function,
      default: () => null,
    },
    goToCustomer: {
      type: Function,
      default: () => null,
    },
  },
  methods: {
    goToStaff() {
      this.$router.push('/staff-registration');
    },
  },
};
</script>

import axios from 'axios';
import store from '@/store';

export const apiCall = (method, url, data) => {
  const token = store.state.oidcStore.access_token;
  store.dispatch('calls/beginAjaxCall');

  return axios({
    method,
    url: `/api/${url}`,
    headers: { Authorization: `bearer ${token}` },
    data,
  })
    .then((rsp) => {
      store.dispatch('calls/endAjaxCall');
      return rsp;
    })
    .catch((err) => {
      store.dispatch('calls/endAjaxCall');
      throw new Error(err);
    });
};

export const genericApiCall = (method, url, data) => {
  store.dispatch('calls/beginAjaxCall');

  return axios({
    method,
    url: `/api/${url}`,
    data,
  })
    .then((rsp) => {
      store.dispatch('calls/endAjaxCall');
      return rsp;
    })
    .catch((err) => {
      store.dispatch('calls/endAjaxCall');
      throw new Error(err);
    });
};

export const fileDownloadCall = (method, url, data) => {
  const token = store.state.oidcStore.access_token;
  store.dispatch('calls/beginAjaxCall');

  return axios({
    method,
    url: `/api/${url}`,
    data,
    responseType: 'blob',
    headers: { Authorization: `bearer ${token}` },
  })
    .then((rsp) => {
      store.dispatch('calls/endAjaxCall');
      return rsp;
    })
    .catch((err) => {
      store.dispatch('calls/endAjaxCall');
      throw new Error(err);
    });
};

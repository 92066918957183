<template>
  <div>
    <div>
      <Tabs :changeTab="changeTab" :tabs="tabs">
        <!-- Actions -->
        <template v-slot:actions>
          <div class="ml-4 flex flex-row space-x-4 flex-1 items-center">
            <div class="flex-1">
              <SearchInput
                id="search"
                :value="search"
                placeholder="Search"
                @changed="(el) => (search = el)"
              />
            </div>
            <div>
              <FilterButton
                :type="filterType"
                :filterFn="filterFn"
                :filters="filters"
              />
            </div>
            <div>
              <Button
                title="New Transport Load"
                type="primary"
                :icon="plusWhite"
                @click.native="createTransport"
              />
            </div>
          </div>
        </template>
        <!-- Tabs -->
        <template v-slot:tabs>
          <Tab :id="0" name="Purchases" :selected="selectedTab === 0">
            <VehiclesTab
              v-if="selectedTab === 0"
              :openTransport="openTransport"
            />
          </Tab>
          <Tab :id="1" name="Loads" :selected="selectedTab === 1">
            <TransportsTab v-if="selectedTab === 1" />
          </Tab>
        </template>
      </Tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import VehiclesTab from './VehiclesTab.vue';
import TransportsTab from './TransportsTab.vue';
import Tabs from '@/components/molecules/Tabs';
import Tab from '@/components/molecules/Tabs/Tab.vue';
import Button from '@/components/atoms/Button.vue';
import plusWhite from '@/assets/icons/plus_white.svg';
import FilterButton from '@/components/molecules/Filters/FilterButton.vue';
import SearchInput from '@/components/atoms/SearchInput.vue';
import eventBus from '@/services/utils/eventBus.js';

export default {
  name: 'StaffHome',
  components: {
    Tabs,
    Tab,
    Button,
    VehiclesTab,
    TransportsTab,
    FilterButton,
    SearchInput,
  },
  props: {
    openTransport: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({
    selectedTab: 0,
    plusWhite,
  }),
  computed: {
    ...mapState({
      searchVehicleField: (state) => state.vehicles.search,
      searchLoadsField: (state) => state.transports.search,
      vehicleFilters: (state) => state.vehicles.filters,
      transportsFilter: (state) => state.transports.filters,
    }),
    tabs() {
      return [
        { id: 0, name: 'Purchases', isActive: this.selectedTab === 0 },
        { id: 1, name: 'Loads', isActive: this.selectedTab === 1 },
      ];
    },
    filters() {
      return !this.type ? this.vehicleFilters : this.transportsFilter;
    },
    filterType() {
      return !this.type ? 'vehicles' : 'loads';
    },
    type() {
      return this.selectedTab === 0 ? null : 'loads';
    },
    search: {
      get() {
        return !this.type ? this.searchVehicleField : this.searchLoadsField;
      },
      set(val) {
        if (!this.type) {
          this.searchVehicles({ search: val.value, type: this.type });
        } else {
          this.searchLoads({ search: val.value });
        }
      },
    },
  },
  mounted() {
    eventBus.$on('load-submitted', () => {
      this.changeTab(1);
    });
  },
  methods: {
    ...mapActions({
      searchVehicles: 'vehicles/searchVehicles',
      resetVehicleSearch: 'vehicles/resetSearch',
      resetFilter: 'vehicles/resetFilter',
      filterVehicles: 'vehicles/filterVehicles',
      setTransportEdit: 'vehicles/setTransportEdit',
      resetPagination: 'vehicles/resetPagination',
      clearVehicleRows: 'vehicles/clearRows',
      clearLoadsRows: 'transports/clearRows',
      filterTransports: 'transports/filterTransports',
      resetLoadsSearch: 'transports/resetSearch',
      searchLoads: 'transports/searchLoads',
    }),
    filterFn(filter) {
      return !this.type
        ? this.filterVehicles(filter)
        : this.filterTransports(filter);
    },
    async createTransport() {
      if (this.selectedTab !== 0) {
        await this.changeTab(0);
      }
      await this.clearVehicleRows();
      await this.setTransportEdit(true);
      this.filterVehicles({
        filter: { type: 'Status', value: 'CUSTOMS_CLEARENCE' },
      });
    },
    async changeTab(tabId) {
      if (this.selectedTab !== tabId) {
        await this.resetPagination();
        await this.resetVehicleSearch();
        await this.resetLoadsSearch();
        await this.resetFilter();
        await this.clearVehicleRows();
        await this.clearLoadsRows();
        this.setTransportEdit(false);
        this.selectedTab = tabId;
      }
    },
  },
};
</script>

<template>
  <div
    class="flex flex-col items-center h-full relative"
    :class="noHeader ? 'p-2' : 'p-16'"
    @dragover="dragover"
    @drop="drop"
  >
    <Button
      v-if="extraBtn && files.length > 0"
      title="Upload"
      type="tertiary"
      class="absolute -right-36 -top-16"
      thicc
      :icon="document"
      variant="wide"
      @click.native="openFile"
    />
    <img
      v-if="!noHeader"
      :src="close"
      class="absolute top-8 right-8 cursor-pointer"
      @click="closeModal"
    />
    <input
      :id="id"
      :ref="id"
      class="hidden"
      type="file"
      :multiple="multiple"
      @change="onChange"
    />
    <h1 v-if="!noHeader">Upload Documents</h1>
    <div
      class="w-full flex"
      :class="files.length === 0 ? 'justify-center' : 'justify-end'"
    >
      <div v-if="!noHeader">
        <p v-if="files.length === 0" class="pt-5 text-l text-center w-full">
          Select documents to upload.
        </p>
        <Button
          v-else
          title="Upload"
          type="tertiary"
          thicc
          :icon="document"
          @click.native="openFile"
        />
      </div>
    </div>
    <div class="mt-12">
      <div
        v-if="files.length === 0"
        class="p-10 border-2 border-dashed border-grey-lighter"
        @click="openFile"
      >
        <img :src="fileImg" />
      </div>
      <slot v-else></slot>
    </div>
    <p v-if="warning" class="text-red-dark mt-4">The file selected cannot be larger than 1MB!</p>
    <Button
      class="text-center mx-auto"
      :class="[warning ? 'mt-5' : 'mt-16']"
      title="Submit"
      type="primary"
      :disabled="submitDisabled"
      @click.native="submit"
    />
  </div>
</template>

<script>
import close from '@/assets/icons/close.svg';
import document from '@/assets/icons/document.svg';
import fileImg from '@/assets/icons/file.svg';
import Button from '@/components/atoms/Button.vue';

export default {
  name: 'DragAndDrop',
  components: {
    Button,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    extraBtn: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      default: () => [],
    },
    closeModal: {
      type: Function,
      default: () => null,
    },
    submitFn: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({
    document,
    fileImg,
    close,
    warning: false,
  }),
  methods: {
    async submit() {
      if (this.submitFn) {
        await this.submitFn();
      }
    },
    openFile() {
      this.$refs[this.id].click();
    },
    dragover(event) {
      event.preventDefault();
    },
    onChange() {
      if (this.$refs[this.id].files[0].size < 1048576) {
        this.$emit('changed', this.$refs[this.id].files);
        this.warning = false;
      } else {
        this.warning = true;
      }
    },
    drop(event) {
      event.preventDefault();
      this.$refs[this.id].files = event.dataTransfer.files;
      this.onChange();
    },
  },
};
</script>

<style></style>

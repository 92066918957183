<template>
  <div class="px-3 text-left relative text-black-light" :class="hasMargins && 'mb-4'">
    <label v-show="title" :for="id" class="block tracking-wide text-xs font-bold mb-2">{{
      title
    }}</label>
    <input
      :id="id"
      class="appearance-none block w-full border border-black-light rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
      :class="hasMargins && 'mb-1'"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      @keyup="(evt) => $emit('changed', {id, value: evt.target.value})"
      @blur="validationTrigger"
    />
    <p v-if="error" class="absolute right-3 text-red text-xs text-right">
      {{ error }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'Input',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    validationTrigger: { type: Function, default: () => {} },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    hasMargins: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

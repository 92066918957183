/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import createLogger from 'vuex/dist/logger';
import oidcSettings from '@/config';

import registration from './modules/registration';
import calls from './modules/calls'
import user from './modules/user'
import vehicles from './modules/vehicles'
import customers from './modules/customers'
import transports from './modules/transports'
import auctionHouses from './modules/auctionHouses'
import loads from './modules/loads'
import analytics from './modules/analytics'
import statusActions from './modules/statusActions'
import staff from './modules/staff'

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
  modules: ['oidc-store', 'user']
});

const plugins = [vuexLocalStorage.plugin];

if (process.env.NODE_ENV !== 'production') {
  plugins.push(createLogger());
}

const store = new Vuex.Store({
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      {
        userLoaded: (user) => console.log('OIDC user is loaded:', user),
        userUnloaded: () => console.log('OIDC user is unloaded'),
        accessTokenExpiring: () => console.log('Access token will expire'),
        accessTokenExpired: () => {
          store.dispatch('oidcStore/authenticateOidcSilent');
        },
        silentRenewError: () => console.log('OIDC user is unloaded'),
        userSignedOut: () => console.log('OIDC user is signed out'),
      },
    ),
    registration,
    calls,
    user,
    vehicles,
    auctionHouses,
    customers,
    transports,
    loads,
    analytics,
    statusActions,
    staff
  },
  plugins
});

export default store;

<template>
  <div v-show="isActive" type="tab">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    name: { required: true },
    selected: { default: false },
    id: { default: 0 },
    dataQaId: { default: null },
  },
  data: () => ({
    type: 'tab',
    isActive: false,
  }),
  watch: {
    selected(val) {
      this.isActive = val;
    },
  },
  mounted() {
    this.isActive = this.selected;
  },
};
</script>

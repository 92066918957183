<template>
  <div class="flex flex-col items-center h-full p-16">
    <h1>Add Vehicle</h1>
    <p class="pt-5 text-l">
      Please click here to add the details of your Auction Transfer request
    </p>
    <div class="w-300 mx-auto mt-10">
      <Input
        id="registrationNumber"
        title="Car Registration Number"
        :value="registrationNumber"
        placeholder="BD51 CSV"
        :error="registrationNumberError"
        :validationTrigger="$v.registrationNumber.$touch"
        @changed="(val) => (registrationNumber = val)"
      />
      <Select
        id="auctionHouseName"
        title="Auction House"
        :options="auctionHouseOptions"
        placeholder="BCA"
        :value="auctionHouseName"
        :error="auctionHouseNameError"
        :validationTrigger="$v.auctionHouseName.$touch"
        @changed="(val) => (auctionHouseName = val)"
      />
      <Input
        v-if="!alreadyHasHouse"
        id="auctionHouseAccountNumber"
        title="Auction House Number"
        :value="auctionHouseAccountNumber"
        placeholder="1234"
        :error="auctionHouseNumberAccountError"
        :validationTrigger="$v.auctionHouseAccountNumber.$touch"
        @changed="(val) => (auctionHouseAccountNumber = val)"
      />
      <Select
        v-if="isBCA"
        id="auctionHouseBranch"
        title="Auction House Branch"
        :options="branchesOptions"
        placeholder="Blackbushe"
        :value="auctionHouseBranch"
        :error="auctionHouseBranchError"
        @changed="(val) => (auctionHouseBranch = val)"
      />
      <Input
        id="make"
        title="Make"
        placeholder="Audi"
        :value="make"
        :error="makeError"
        :validationTrigger="$v.make.$touch"
        @changed="(val) => (make = val)"
      />
      <Input
        id="model"
        title="Model"
        placeholder="A7"
        :value="model"
        :error="modelError"
        :validationTrigger="$v.model.$touch"
        @changed="(val) => (model = val)"
      />
    </div>
    <Button
      title="Submit Request"
      class="mt-auto"
      type="primary"
      :isDisabled="disableSubmit"
      @click.native="$modal.show('confirm-modal')"
    />
    <Modal name="confirm-modal" width="70%" height="50%">
      <div class="flex h-full flex-col items-center p-16 mt-5">
        <h1>Submit Request</h1>
        <p class="pt-5 text-l">
          Are you happy to proceed with this Auction House request?
        </p>
        <div class="flex flex-row space-x-8 mt-16">
          <Button
            title="No"
            type="simple"
            class="mt-auto"
            variant="wide"
            @click.native="$modal.hide('confirm-modal')"
          />
          <Button
            title="Yes"
            class="mt-auto"
            type="primary"
            variant="wide"
            @click.native="save"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
import { mapState, mapActions } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import Input from '@/components/atoms/Input.vue';
import Button from '@/components/atoms/Button.vue';
import Select from '@/components/atoms/Select.vue';

export default {
  name: 'AuctionForm',
  components: {
    Input,
    Button,
    Select,
  },
  props: {
    closeFn: {
      type: Function,
      default: () => null,
    },
  },
  validations() {
    return {
      registrationNumber: { required },
      auctionHouseName: { required },
      auctionHouseBranch: { required: requiredIf(() => this.isBCA) },
      auctionHouseAccountNumber: { required: requiredIf(() => !this.alreadyHasHouse) },
      make: { required },
      model: { required },
    };
  },
  computed: {
    ...mapState({
      data: (state) => state.vehicles.auction,
      branches: (state) => state.auctionHouses.items,
      houses: (state) => state.auctionHouses.houses,
      ownHouses: (state) => state.user.auctionHouses,
    }),
    isBCA() {
      return this.auctionHouseName && this.auctionHouseName.value === 1;
    },
    auctionHouseOptions() {
      return (
        this.houses &&
        this.houses.map((house) => ({
          label: house.name,
          value: house.id,
        }))
      );
    },
    branchesOptions() {
      return (
        this.branches &&
        this.branches.map((branch) => ({
          label: branch.name,
          value: branch.id,
        }))
      );
    },
    registrationNumber: {
      get() {
        return this.data.registrationNumber;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'auction',
        });
      },
    },
    registrationNumberError() {
      if (this.$v.registrationNumber.$dirty) {
        if (!this.$v.registrationNumber.required) {
          return 'Required.';
        }
      }
      return null;
    },
    auctionHouseName: {
      get() {
        return this.data.auctionHouseName;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'auction',
        });
      },
    },
    auctionHouseNameError() {
      if (
        this.$v.auctionHouseName.$invalid &&
        this.$v.auctionHouseName.$dirty
      ) {
        return 'Required.';
      }
      return null;
    },
    auctionHouseBranch: {
      get() {
        return this.data.auctionHouseBranch;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'auction',
        });
      },
    },
    auctionHouseBranchError() {
      if (
        this.$v.auctionHouseBranch.$invalid &&
        this.$v.auctionHouseBranch.$dirty
      ) {
        return 'Required.';
      }
      return null;
    },
    auctionHouseAccountNumber: {
      get() {
        return this.data.auctionHouseAccountNumber;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'auction',
        });
      },
    },
    auctionHouseNumberAccountError() {
      if (
        this.$v.auctionHouseAccountNumber.$invalid &&
        this.$v.auctionHouseAccountNumber.$dirty
      ) {
        return 'Required.';
      }
      return null;
    },
    make: {
      get() {
        return this.data.make;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'auction',
        });
      },
    },
    makeError() {
      if (this.$v.make.$invalid && this.$v.make.$dirty) {
        return 'Required.';
      }
      return null;
    },
    model: {
      get() {
        return this.data.model;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'auction',
        });
      },
    },
    modelError() {
      if (this.$v.model.$invalid && this.$v.model.$dirty) {
        return 'Required.';
      }
      return null;
    },
    disableSubmit() {
      return this.$v.$invalid;
    },
    alreadyHasHouse() {
      if (!this.auctionHouseName) {
        return true;
      }
      return this.ownHouses.find(
        (el) => el.auctionHouseId === this.auctionHouseName.value,
      );
    },
  },
  mounted() {
    this.getAuctionHouses();
  },
  methods: {
    ...mapActions({
      updateField: 'vehicles/updateField',
      createAuctionVehicle: 'vehicles/createAuctionVehicle',
      getAuctionHouses: 'auctionHouses/getAuctionHouses',
    }),
    async save() {
      this.$modal.hide('confirm-modal');
      await this.createAuctionVehicle();
      this.closeFn();
    },
  },
};
</script>

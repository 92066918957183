<template>
  <div>
    <div>
      <Tabs :changeTab="changeTab" :tabs="tabs">
        <!-- Actions -->
        <template v-slot:actions>
          <div class="ml-4 flex flex-row space-x-4 flex-1 items-center">
            <div class="flex-1">
              <SearchInput id="search" :value="search" placeholder="Search" @changed="el => search = el" />
            </div>
            <div>
              <FilterButton :type="type" :filterFn="filterFn" :filters="filters" />
            </div>
            <div>
              <Button
                title="New Vehicle"
                type="tertiary"
                :icon="plusBlue"
                @click.native="openForm"
              />
            </div>
          </div>
        </template>
        <!-- Tabs -->
        <template v-slot:tabs>
          <Tab :id="0" name="Auction" :selected="selectedTab === 0">
            <CustomerTab v-if="selectedTab === 0" type="auction" />
          </Tab>
          <Tab :id="1" name="Forecourt" :selected="selectedTab === 1">
            <CustomerTab v-if="selectedTab === 1" type="forecourt" />
          </Tab>
        </template>
      </Tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CustomerTab from './CustomerTab.vue';
import Tabs from '@/components/molecules/Tabs';
import Tab from '@/components/molecules/Tabs/Tab.vue';
import Button from '@/components/atoms/Button.vue';
import FilterButton from '@/components/molecules/Filters/FilterButton.vue';
import SearchInput from '@/components/atoms/SearchInput.vue';
import plusBlue from '@/assets/icons/plus_blue.svg';

export default {
  name: 'CustomerHome',
  components: {
    Tabs,
    Tab,
    Button,
    CustomerTab,
    SearchInput,
    FilterButton,
  },
  props: {
    openAuction: {
      type: Function,
      default: () => null,
    },
    openForecourt: {
      type: Function,
      default: () => null,
    },
    tab: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    selectedTab: 0,
    plusBlue,
  }),
  computed: {
    ...mapState({
      searchField: (state) => state.vehicles.search,
      filters: (state) => state.vehicles.filters,
    }),
    type() {
      return this.selectedTab === 0 ? 'auction' : 'forecourt';
    },
    tabs() {
      return [
        { id: 0, name: 'Auction', isActive: this.selectedTab === 0 },
        { id: 1, name: 'Forecourt', isActive: this.selectedTab === 1 },
      ];
    },
    search: {
      get() {
        return this.searchField;
      },
      set(val) {
        this.searchVehicles({ search: val.value, type: this.type });
      },
    },
  },
  mounted() {
    this.selectedTab = this.tab;
  },
  methods: {
    ...mapActions({
      searchVehicles: 'vehicles/searchVehicles',
      resetFilter: 'vehicles/resetFilter',
      resetSearch: 'vehicles/resetSearch',
      resetPagination: 'vehicles/resetPagination',
      clearRows: 'vehicles/clearRows',
      filterVehicles: 'vehicles/filterVehicles',
    }),
    async changeTab(tabId) {
      if (this.selectedTab !== tabId) {
        await this.resetFilter();
        await this.resetPagination();
        await this.resetSearch();
        await this.clearRows();
        this.selectedTab = tabId;
      }
    },
    filterFn(filter) {
      this.filterVehicles({ filter: filter.filter, type: this.type });
    },
    openForm() {
      if (this.selectedTab === 0) {
        this.openAuction();
      } else {
        this.openForecourt();
      }
    },
  },
};
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <div class="flex flex-col space-y-5 text-center">
      <h1>
        Staff Details
      </h1>
      <p>
        Fill in the information below to register a new staff member
      </p>
    </div>
    <div class="flex flex-col space-y-5 mt-10">
      <div class="flex flex-row space-x-5">
        <div>
          <Input
            id="firstName"
            placeholder="First Name"
            :value="firstName"
            :error="firstNameError"
            :validationTrigger="$v.firstName.$touch"
            @changed="(val) => (firstName = val)"
          />
        </div>
        <div>
          <Input
            id="lastName"
            placeholder="Last Name"
            :value="lastName"
            :error="lastNameError"
            :validationTrigger="$v.lastName.$touch"
            @changed="(val) => (lastName = val)"
          />
        </div>
      </div>
      <div>
        <Input
          id="email"
          placeholder="Email"
          :value="email"
          :error="emailError"
          :validationTrigger="$v.email.$touch"
          @changed="(val) => (email = val)"
        />
      </div>
    </div>
    <div class="flex flex-row space-x-5 mt-20">
      <Button title="Go back" type="simple" noBorder @click.native="goBack" />
      <Button
        title="Invite"
        type="primary"
        :disabled="disableSubmit"
        @click.native="invite"
      />
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';
import Input from '@/components/atoms/Input';
import Button from '@/components/atoms/Button';

export default {
  name: 'StaffRegistration',
  components: {
    Input,
    Button,
  },
  validations: {
    firstName: { required },
    lastName: { required },
    email: { required, email },
  },
  computed: {
    ...mapState({
      data: (state) => state.staff.data,
    }),
    firstName: {
      get() {
        return this.data.firstName;
      },
      set(val) {
        this.setValue(val);
      },
    },
    firstNameError() {
      if (this.$v.firstName.$dirty) {
        if (!this.$v.firstName.required) {
          return 'Required.';
        }
      }
      return null;
    },
    lastName: {
      get() {
        return this.data.lastName;
      },
      set(val) {
        this.setValue(val);
      },
    },
    lastNameError() {
      if (this.$v.lastName.$dirty) {
        if (!this.$v.lastName.required) {
          return 'Required.';
        }
      }
      return null;
    },
    email: {
      get() {
        return this.data.email;
      },
      set(val) {
        this.setValue(val);
      },
    },
    emailError() {
      if (this.$v.email.$dirty) {
        if (!this.$v.email.required) {
          return 'Required.';
        }
        if (!this.$v.email.email) {
          return 'Invalid Format.';
        }
      }
      return null;
    },
    disableSubmit() {
      return this.$v.$invalid;
    },
  },
  methods: {
    ...mapActions({
      setValue: 'staff/updateField',
      inviteStaff: 'staff/inviteStaff',
    }),
    goBack() {
      this.$router.push('/customers');
    },
    async invite() {
      try {
        await this.inviteStaff();
        this.$toast.info('Staff member invited!');
        this.$router.push('/customers');
      } catch {
        this.$toast.error('There was an error inviting the staff member.');
      }
    },
  },
};
</script>

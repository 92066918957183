import { apiCall } from './index';

export const getTransports = (pagination, filters, keyword) => {
  let url = 'transport?';
  if (pagination) {
    url += `&limit=${pagination.limit}&offset=${pagination.offset}`;
  }
  if (keyword) {
    url += `&keyword=${keyword}`;
  }
  if (filters && filters.status !== '') {
    url += `&status=${filters.status}`;
  }
  url += '&sortBy=transportingDate.desc';
  return apiCall('GET', url);
};

export const getTransport = (id) => apiCall('GET', `transport/${id}`);

export const sendTransport = (id, email) => apiCall('POST', `transport/${id}`, { value: email });

export const updateTransportStatus = (id, status) => { const body = { status }; return apiCall('PATCH', `transport/${id}`, body); };

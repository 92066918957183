<template>
  <div class="flex flex-col" :class="item.class">
    <Select
      :id="item.object.id.toString()"
      :options="options"
      :value="text"
      :hasPadding="false"
      :hasMargin="false"
      :isDisabled="item.disableDropdown"
      @changed="(val) => (text = val)"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Select from '@/components/atoms/Select.vue';
import { formatStatus } from '@/services/utils/formatEnums.js';

export default {
  name: 'SelectorCell',
  components: {
    Select,
  },
  props: ['item'],
  data: () => ({ formatStatus }),
  computed: {
    text: {
      get() {
        return { label: this.formatStatus(this.item.object.status).label };
      },
      async set(item) {
        await this.update(item);
      },
    },
    options() {
      return this.item.object.nextStatus.map((item) => ({
        label: this.formatStatus(item.status).label,
        value: item.status,
        action: item.action || 'NONE',
      }));
    },
  },
  methods: {
    ...mapActions({
      updateVehicleStatus: 'vehicles/updateStatus',
      updateTransportStatus: 'transports/updateStatus',
      setAction: 'statusActions/setAction',
      setActionFor: 'statusActions/setActionFor',
      setObjectId: 'statusActions/setObjectId',
      setTargetStatus: 'statusActions/setTargetStatus',
      toggleModal: 'statusActions/toggleModal',
    }),
    update(item) {
      this.setAction({ actionName: item.value.action });
      this.setActionFor({
        type: this.item.object.registrationNumber ? 'VEHICLE' : 'TRANSPORT',
      });
      this.setObjectId({ id: item.id });
      this.setTargetStatus({ status: item.value.value });
      this.toggleModal({ open: true });
    },
  },
};
</script>

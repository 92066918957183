import Vue from 'vue';
import idGenerator from '@/services/utils/idGenerator.js';
import { saveLoad } from '@/services/api/loads.js';
import { uploadVehicleDocument } from '@/services/api/files.js';

export default {
  namespaced: true,
  state: {
    vehicleDocuments: {},
    newVehicleDocuments: {},
    data: {
      transportingDate: new Date(),
      destination: '',
      transporter: '',
    },
  },
  actions: {
    setDocuments({ commit }, vehicle) {
      return commit('SET_DOCUMENTS', vehicle);
    },
    updateField({ commit }, data) {
      commit('UPDATE_FIELD', data);
    },
    selectDocument({ commit }, { documentId, vehicleId }) {
      commit('SELECT_DOCUMENT', { documentId, vehicleId });
    },
    saveDocuments({ state, commit }, vehicleId) {
      const { documents } = state.newVehicleDocuments[vehicleId];
      const calls = documents.map((item) => uploadVehicleDocument(item, vehicleId));
      return Promise.all(calls).then((responses) => {
        responses.forEach((rsp) => {
          const file = {
            ...rsp.data,
            checked: false,
          };
          commit('ADD_NEW_FILE', { vehicleId, file });
        });
      });
    },
    changeDocumentType({ commit }, { documentId, vehicleId, documentType }) {
      commit('CHANGE_DOCUMENT_TYPE', { documentId, vehicleId, documentType });
    },
    addDocument({ commit }, { files, vehicleId }) {
      commit('ADD_DOCUMENT', { files, vehicleId });
    },
    removeDocument({ commit }, { fileId, vehicleId }) {
      commit('REMOVE_DOCUMENT', { fileId, vehicleId });
    },
    submit({ rootState, state }) {
      const items = rootState.vehicles.selectedForTransport.map((vehicle) => {
        const documents = state.vehicleDocuments[vehicle.id].documents
          .filter((document) => document.checked)
          .map((doc) => doc.id);
        return {
          vehicleId: vehicle.id,
          location: vehicle.location,
          documents,
        };
      });
      const payload = {
        ...state.data,
        items,
      };
      return saveLoad(payload);
    },
  },
  mutations: {
    UPDATE_FIELD(state, { value, field }) {
      state.data[field] = value;
    },
    SET_DOCUMENTS(state, vehicle) {
      const documents = vehicle.documents.map((el) => ({
        ...el,
        checked: false,
      }));
      if (!state.vehicleDocuments[vehicle.id]) {
        Vue.set(state.vehicleDocuments, vehicle.id, {});
      }
      Vue.set(state.vehicleDocuments[vehicle.id], 'documents', documents);
    },
    ADD_NEW_FILE(state, { vehicleId, file }) {
      if (state.vehicleDocuments[vehicleId]) {
        state.vehicleDocuments[vehicleId].documents.push(file);
      }
    },
    REMOVE_DOCUMENT(state, { fileId, vehicleId }) {
      const filteredDocs = state.newVehicleDocuments[vehicleId].documents.filter((el) => el.id !== fileId);
      Vue.set(state.newVehicleDocuments[vehicleId], 'documents', filteredDocs);
    },
    ADD_DOCUMENT(state, { files, vehicleId }) {
      const filesArray = Array.from(files);
      const formattedFiles = filesArray.map((el) => ({
        file: el,
        name: el.name,
        type: '',
        id: idGenerator(),
      }));
      if (state.newVehicleDocuments[vehicleId]) {
        const docs = state.newVehicleDocuments[vehicleId].documents;
        docs.push(...formattedFiles);
        Vue.set(state.newVehicleDocuments[vehicleId], 'documents', docs);
        // state.newVehicleDocuments[vehicleId].documents.push(...formattedFiles);
      } else {
        Vue.set(state.newVehicleDocuments, vehicleId, {});
        Vue.set(
          state.newVehicleDocuments[vehicleId],
          'documents',
          formattedFiles,
        );
      }
    },
    SELECT_DOCUMENT(state, { documentId, vehicleId }) {
      const doc = state.vehicleDocuments[vehicleId].documents.find(
        (el) => el.id.toString() === documentId.toString(),
      );
      const newDoc = {
        ...doc,
        checked: !doc.checked,
      };
      Object.assign(doc, newDoc);
    },
    CHANGE_DOCUMENT_TYPE(state, { documentId, vehicleId, documentType }) {
      const doc = state.newVehicleDocuments[vehicleId].documents.find(
        (el) => el.id.toString() === documentId.toString(),
      );
      const newDoc = {
        ...doc,
        type: documentType.value,
      };
      Object.assign(doc, newDoc);
    },
  },
};

<template>
  <div class="ml-2 flex flex-row justify-between items-center">
    <Logo class="inline-block w-300" />
    <div v-if="isAdmin && !isPublic" class="flex flex-row space-x-10">
      <router-link
        to="/"
        class="flex flex-row space-x-2 text-mdl pb-1 items-center border-b-3 border-white"
      >
        <DashboardIcon :iconColor="isRouteActive('/') ? 'blue' : 'grey'" />
        <p>
          Dashboard
        </p>
      </router-link>
      <router-link
        to="/customers"
        class="flex flex-row space-x-2 text-mdl pb-1 items-center border-b-3 border-white"
      >
        <CustomersIcon
          :iconColor="isRouteActive('/customers') ? 'blue' : 'grey'"
        />
        <p>
          Customers
        </p>
      </router-link>
      <router-link
        to="/analytics"
        class="flex flex-row space-x-2 text-mdl pb-1 items-center border-b-3 border-white"
      >
        <AnalyticsIcon
          :iconColor="isRouteActive('/analytics') ? 'blue' : 'grey'"
        />
        <p>
          Analytics
        </p>
      </router-link>
    </div>
    <div v-if="!isPublic" class="w-300 flex">
      <router-link
        to="/logout"
        class="text-mdl text-blue mr-10 ml-auto inline-block"
      >
        Sign out
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Logo from '@/components/atoms/Logo.vue';
import DashboardIcon from '@/assets/icons/menu/DashboardIcon.vue';
import AnalyticsIcon from '@/assets/icons/menu/AnalyticsIcon.vue';
import CustomersIcon from '@/assets/icons/menu/CustomersIcon.vue';

export default {
  name: 'Header',
  components: {
    Logo,
    DashboardIcon,
    CustomersIcon,
    AnalyticsIcon,
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
    }),
    isPublic() {
      return this.$route.meta.isPublic;
    },
  },
  methods: {
    isRouteActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style lang="css">
.router-link-exact-active {
  @apply text-blue border-b-3 border-blue;
}
</style>

<template>
  <div>
    <TransportForm
      v-if="stage === 'VEHICLE_FORM'"
      ref="transportForm"
      :key="selectedVehicleNr"
      :uploadFiles="openFiles"
      :closeFiles="openVehiclesForm"
      :closeFn="closeFn"
      :nextFn="nextVehicle"
      :backFn="prevVehicle"
      :selectedVehicleNr="selectedVehicleNr"
      :vehicle="selectedVehicle"
      :totalVehicles="vehicles.length"
      :files="documents"
      :openLoadForm="openLoadForm"
    />
    <DocumentsForm
      v-if="stage === 'DOCUMENT_FORM'"
      :item="selectedVehicle"
      :submitFn="saveVehicleDocumnets"
      :files="newDocuments"
      :changeDocumentType="changeDocumentType"
      :addDocument="addDocument"
      :hideCloseButton="true"
    />
    <LoadForm
      v-if="stage === 'LOAD_FORM'"
      :closeFn="closeFn"
      :backFn="openVehiclesForm"
    />
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */

import { mapState, mapActions } from 'vuex';
import TransportForm from '../Forms/TransportForm.vue';
import DocumentsForm from '../Forms/DocumentsForm.vue';
import LoadForm from '../Forms/LoadForm.vue';

export default {
  name: 'NewLoad',
  components: {
    TransportForm,
    DocumentsForm,
    LoadForm,
  },
  props: {
    closeFn: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({
    stage: 'VEHICLE_FORM',
    selectedVehicleNr: 0,
  }),
  computed: {
    ...mapState({
      vehicles: (state) => state.vehicles.selectedForTransport,
      vehicleDocuments: (state) => state.loads.vehicleDocuments,
      newVehicleDocuments: (state) => state.loads.newVehicleDocuments,
    }),
    documents() {
      return (
        this.vehicleDocuments[this.selectedVehicle.id] &&
        this.vehicleDocuments[this.selectedVehicle.id].documents
      );
    },
    newDocuments() {
      return (
        this.newVehicleDocuments[this.selectedVehicle.id] &&
        this.newVehicleDocuments[this.selectedVehicle.id].documents
      );
    },
    selectedVehicle() {
      return this.vehicles[this.selectedVehicleNr];
    },
  },
  methods: {
    ...mapActions({
      saveDocuments: 'loads/saveDocuments',
      changeDocumentType: 'loads/changeDocumentType',
      addDocument: 'loads/addDocument',
    }),
    openFiles() {
      this.stage = 'DOCUMENT_FORM';
    },
    async saveVehicleDocumnets() {
      await this.saveDocuments(this.selectedVehicle.id);
      this.openVehiclesForm();
    },
    openVehiclesForm() {
      this.selectedVehicleNr = 0;
      this.stage = 'VEHICLE_FORM';
    },
    openLoadForm() {
      this.stage = 'LOAD_FORM';
    },
    nextVehicle() {
      if (this.selectedVehicle.location === '') {
        this.$refs.transportForm.$v.location.$touch();
      } else if (this.selectedVehicleNr < this.vehicles.length - 1) {
        this.selectedVehicleNr += 1;
      } else {
        this.openLoadForm();
      }
    },
    prevVehicle() {
      if (this.selectedVehicleNr > 0) {
        this.selectedVehicleNr -= 1;
      }
    },
  },
};
</script>

<style></style>

<template>
  <div
    class="px-3 text-left relative text-black-light"
    :class="hasMargins && 'mb-4'"
  >
    <label
      v-show="title"
      :for="id"
      class="block tracking-wide text-xs font-bold mb-2"
      >{{ title }}</label
    >
    <Datepicker
      :id="id"
      :disabledDates="disabledDates"
      format="dd MM yyyy"
      :placeholder="placeholder"
      :value="value"
      name="uniquename"
      class="appearance-none block w-full border border-black-light rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
      @blur="validationTrigger"
      @input="(val) => $emit('changed', val)"
    ></Datepicker>
    <img
      src="@/assets/icons/calendar.svg"
      class="absolute right-6"
      :class="title !== '' ? 'top-8.5' : 'top-3'"
    />
    <p v-if="error" class="absolute right-3 text-red text-xs text-right">
      {{ error }}
    </p>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';

export default {
  name: 'DatePicker',
  components: {
    Datepicker,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    validationTrigger: { type: Function, default: () => {} },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    value: {
      type: [Date, String],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    hasMargins: {
      type: Boolean,
      default: true,
    },
    disabledDates: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

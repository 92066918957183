<template>
  <div class="bg-white">
    <div class="absolute right-6 top-9">
      <img :src="close" class="cursor-pointer" @click="closeModal" />
    </div>
    <div class="flex flex-col items-center justify-center">
      <p class="my-5 text-xxl text-black font-bold text-center mt-16">
        Add PBN
      </p>
      <p class="font-medium text-l text-black text-center">
        Add here the assigned PBN for the load.
      </p>
      <Input
        id="pbn"
        placeholder="PBN"
        :value="pbn"
        class="mt-12"
        @changed="setPBN"
      />
      <Button
        title="Submit"
        class="mt-8 mb-16"
        type="primary"
        :isDisabled="!disableSubmit"
        @click.native="save"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable comma-dangle */
import { mapActions, mapState } from 'vuex';
import close from '@/assets/icons/close.svg';
import Input from '../../atoms/Input.vue';
import Button from '../../atoms/Button.vue';

export default {
  name: 'AddPBNModal',
  components: { Input, Button },
  data: () => ({ close, pbn: null }),
  computed: {
    ...mapState({ id: (state) => state.statusActions.objectId }),
    disableSubmit() {
      return !!this.pbn;
    },
  },
  methods: {
    ...mapActions({
      triggerStatusUpdate: 'statusActions/triggerStatusUpdate',
      setPbn: 'transports/setPBN',
    }),
    closeModal() {
      this.$modal.hide('action-modal');
    },
    setPBN(value) {
      this.pbn = value.value;
    },
    save() {
      Promise.resolve(
        this.setPbn({
          id: this.id,
          pbn: this.pbn,
        })
      ).then((rsp) => {
        if (rsp.data.pbn) {
          this.triggerStatusUpdate();
          this.closeModal();
        }
      });
    },
  },
};
</script>

const getDefaultState = () => ({
  callInProgress: false,
  callsCount: 0,
});

const actions = {
  resetCallsState({ commit }) {
    commit('resetState');
  },
  beginAjaxCall({ commit }) {
    commit('BEGIN_CALL');
    commit('INCREMENT_CALLS');
  },
  endAjaxCall({ commit }) {
    commit('END_CALL');
    commit('DECREMENT_CALLS');
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  BEGIN_CALL(state) {
    state.callInProgress = true;
  },
  END_CALL(state) {
    state.callInProgress = false;
  },
  INCREMENT_CALLS(state) {
    state.callsCount += 1;
  },
  DECREMENT_CALLS(state) {
    state.callsCount -= 1;
  },
};

const getters = {
  isLoading: (state) => state.callInProgress || state.callsCount > 0,
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

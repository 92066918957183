import { fetchUser, fetchUserGuide } from '@/services/api/user.js';

export default {
  namespaced: true,
  state: {
    roles: [],
    hasVehicles: false,
    auctionHouses: [],
    userGuideLink: '',
  },
  actions: {
    setRoles({ commit }, { roles }) {
      commit('ADD_ROLES', roles);
    },
    getUserGuide({ commit }) {
      fetchUserGuide().then((rsp) => {
        commit('SET_GUIDE_LINK', rsp.data.url);
      });
    },
    async getUser({ commit }) {
      const user = await fetchUser();
      commit('ADD_HOUSES', user.data.auctionHouses);
    },
    setHasVehicles({ commit }, { doesHe }) {
      commit('SET_HAS_VEHICLES', doesHe);
    },
  },
  mutations: {
    ADD_ROLES(state, roles) {
      state.roles = roles;
    },
    ADD_HOUSES(state, auctionHouses) {
      state.auctionHouses = auctionHouses;
    },
    SET_HAS_VEHICLES(state, doesHe) {
      state.hasVehicles = doesHe;
    },
    SET_GUIDE_LINK(state, link) {
      state.userGuideLink = link;
    },
  },
  getters: {
    getRoles: (state) => state.roles,
    isAdmin: (state) => state.roles.indexOf('APP_ADMIN') > -1,
  },
};

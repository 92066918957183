<template>
  <div class="space-x-3 flex flex-row justify-center">
    <Button
      v-if="prev || prev === 0"
      :title="prevTitle"
      type="secondary"
      noBorder
      @clicked="$emit('navigate', prev)"
    />
    <Button
      v-if="next"
      :title="nextTitle"
      :isDisabled="!isCurrentStepComplete"
      type="primary"
      @clicked="$emit('navigate', next)"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Button from '@/components/atoms/Button.vue';

export default {
  name: 'Navigation',
  components: {
    Button,
  },
  props: {
    /**
     * Config object, containing:
     *
     * prev - the previous page
     * next - the next page
     */
    config: {
      type: Object,
      default: () => {},
    },
    prevTitle: {
      type: String,
      default: '',
    },
    nextTitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      isCurrentStepComplete: (state) => state.registration.isCurrentStepComplete,
    }),
    prev() {
      return this.config && this.config.prev;
    },
    next() {
      return this.config && this.config.next;
    },
  },
};
</script>

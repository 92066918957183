<template>
  <div class="flex flex-col items-center h-full p-16">
    <h1>Add Vehicle</h1>
    <p class="pt-5 text-l">
      Please click here to add the details of your Forecourt Purchase request
    </p>
    <div class="w-300 mx-auto mt-5">
      <Input
        id="registrationNumber"
        title="Car Registration Number"
        :value="registrationNumber"
        placeholder="BD51 CSV"
        :error="registrationNumberError"
        :validationTrigger="$v.registrationNumber.$touch"
        @changed="(val) => (registrationNumber = val)"
      />
      <Input
        id="siteLink"
        title="Link"
        placeholder="www.autotrader.uk"
        :value="siteLink"
        :error="siteLinkError"
        :validationTrigger="$v.siteLink.$touch"
        @changed="(val) => (siteLink = val)"
      />
      <Input
        id="make"
        title="Make"
        placeholder="Audi"
        :value="make"
        :error="makeError"
        :validationTrigger="$v.make.$touch"
        @changed="(val) => (make = val)"
      />
      <Input
        id="model"
        title="Model"
        placeholder="A7"
        :value="model"
        :error="modelError"
        :validationTrigger="$v.model.$touch"
        @changed="(val) => (model = val)"
      />
      <Input
        id="maxPrice"
        title="Max Price"
        placeholder="25000"
        :value="maxPrice"
        :error="maxPriceError"
        :validationTrigger="$v.maxPrice.$touch"
        @changed="(val) => (maxPrice = val)"
      />
    </div>
    <Button
      title="Submit Request"
      class="mt-auto"
      type="primary"
      :isDisabled="disableSubmit"
      @click.native="$modal.show('confirm-modal')"
    />
    <Modal name="confirm-modal" width="70%" height="50%">
      <div class="flex h-full flex-col items-center p-16 mt-5">
        <h1>Submit Request</h1>
        <p class="pt-5 text-l">
          Are you happy to proceed with this Forecourt Purchase request?
        </p>
        <div class="flex flex-row space-x-8 mt-16">
          <Button
            title="No"
            type="simple"
            class="mt-auto"
            variant="wide"
            @click.native="$modal.hide('confirm-modal')"
          />
          <Button
            title="Yes"
            class="mt-auto"
            type="primary"
            variant="wide"
            @click.native="save"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required, alphaNum } from 'vuelidate/lib/validators';
import Input from '@/components/atoms/Input.vue';
import Button from '@/components/atoms/Button.vue';
import {
  linkFormatValidator,
} from '@/services/utils/regexUtils.js';

export default {
  name: 'ForecourtForm',
  components: {
    Input,
    Button,
  },
  props: {
    closeFn: {
      type: Function,
      default: () => null,
    },
  },
  validations: {
    registrationNumber: { required },
    siteLink: { required, linkFormatValidator },
    make: { required },
    model: { required },
    maxPrice: { required, alphaNum },
  },
  computed: {
    ...mapState({
      data: (state) => state.vehicles.forecourt,
    }),
    registrationNumber: {
      get() {
        return this.data.registrationNumber;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'forecourt',
        });
      },
    },
    registrationNumberError() {
      if (this.$v.registrationNumber.$dirty) {
        if (!this.$v.registrationNumber.required) {
          return 'Required.';
        }
      }
      return null;
    },
    siteLink: {
      get() {
        return this.data.siteLink;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'forecourt',
        });
      },
    },
    siteLinkError() {
      if (this.$v.siteLink.$dirty) {
        if (!this.$v.siteLink.required) {
          return 'Required.';
        }
        if (!this.$v.siteLink.linkFormatValidator) {
          return 'Invalid Format.';
        }
      }
      return null;
    },
    make: {
      get() {
        return this.data.make;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'forecourt',
        });
      },
    },
    makeError() {
      if (this.$v.make.$invalid && this.$v.make.$dirty) {
        return 'Required.';
      }
      return null;
    },
    model: {
      get() {
        return this.data.model;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'forecourt',
        });
      },
    },
    modelError() {
      if (this.$v.model.$invalid && this.$v.model.$dirty) {
        return 'Required.';
      }
      return null;
    },
    maxPrice: {
      get() {
        return this.data.maxPrice;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'forecourt',
        });
      },
    },
    maxPriceError() {
      if (this.$v.maxPrice.$dirty && this.$v.maxPrice.$invalid) {
        if (!this.$v.maxPrice.required) {
          return 'Required.';
        }
        if (!this.$v.maxPrice.alphaNum) {
          return 'Invalid Format.';
        }
      }
      return null;
    },
    disableSubmit() {
      return this.$v.$invalid;
    },
  },
  methods: {
    ...mapActions({
      updateField: 'vehicles/updateField',
      createForecourtVehicle: 'vehicles/createForecourtVehicle',
    }),
    async save() {
      this.$modal.hide('confirm-modal');
      await this.createForecourtVehicle();
      this.closeFn();
    },
  },
};
</script>

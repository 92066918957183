import Vue from 'vue';
import VueRouter from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';

import store from '@/store';
import Home from '@/views/Home.vue';
import Customers from '@/views/Customers.vue';
import Analytics from '@/views/Analytics.vue';
import Registration from '@/views/Registration.vue';
import OidcCallback from '@/views/OidcCallback.vue';
import StaffRegistration from '@/views/StaffRegistration.vue';
import Logout from '@/views/Logout.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      permittedRoles: ['APP_USER', 'APP_ADMIN'],
    },
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    meta: {
      permittedRoles: ['APP_ADMIN'],
    },
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: Analytics,
    meta: {
      permittedRoles: ['APP_ADMIN'],
    },
  },
  {
    path: '/staff-registration',
    name: 'StaffRegistration',
    component: StaffRegistration,
    meta: {
      permittedRoles: ['APP_ADMIN'],
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/oidc-callback',
    name: 'oidcCallback',
    component: OidcCallback,
    meta: {
      isOidcCallback: true,
      isPublic: true,
    },
  },
  {
    path: '/register',
    name: 'Registration',
    component: Registration,
    props: (route) => ({
      registrationId: route.query.id,
    }),
    meta: {
      isPublic: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

router.beforeEach((to, _, next) => {
  if (to.matched.some((record) => record.meta.isPublic)) {
    next();
  } else if (
    to.meta.permittedRoles
    && store.getters['user/getRoles'].filter(
      (role) => to.meta.permittedRoles.indexOf(role) > -1,
    ).length
  ) {
    next();
  } else {
    store.dispatch('oidcStore/signOutOidc');
  }
});

export default router;

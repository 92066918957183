<template>
  <div>
    <Table :rows="rows" :headers="headers" :emptyState="emptyState"></Table>
    <div
      v-if="rows.length !== 0"
      class="flex flex-row space-x-10 my-5 justify-center"
    >
      <p
        :class="{
          'text-blue cursor-pointer': !disablePrev,
          'text-grey cursor-not-allowed': disablePrev
        }"
        class="text-mdl"
        @click="!disablePrev && fetchPrev()"
      >
        Previous
      </p>
      <p
        title="Next"
        :class="{
          'text-blue cursor-pointer': !disableNext,
          'text-grey cursor-not-allowed': disableNext
        }"
        class="text-mdl"
        @click="!disableNext && fetchNext()"
      >
        Next
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Table from '@/components/molecules/Table';

export default {
  name: 'TransportsTab',
  components: {
    Table,
  },
  data: () => ({
    headers: [],
    emptyState: [
      'Your loads dashboard is empty.',
      'When you add a load it will appear here.',
    ],
  }),
  computed: {
    ...mapState({
      pagination: (state) => state.transports.pagination,
      rows: (state) => state.transports.rows,
    }),
    ...mapGetters({
      getHeaders: 'transports/getHeaders',
    }),
    disablePrev() {
      return this.pagination.pageNr === 0;
    },
    disableNext() {
      return this.pagination.pageNr === this.pagination.totalPages - 1;
    },
  },
  async mounted() {
    await this.getTransports({ next: false, prev: false });
    this.headers = this.getHeaders;
  },
  methods: {
    ...mapActions({
      getTransports: 'transports/getTransports',
    }),
    async fetchNext() {
      await this.getTransports({ next: true, prev: false });
    },
    async fetchPrev() {
      await this.getTransports({ next: false, prev: true });
    },
  },
};
</script>

<template>
  <div class="">
    <div class="flex flex-wrap">
      <Input
        id="firstName"
        title="First name"
        placeholder="First name"
        class="w-1/2"
        :value="firstName"
        :error="firstNameError"
        :validationTrigger="$v.firstName.$touch"
        @changed="(value) => (firstName = value)"
      />
      <Input
        id="lastName"
        title="Last name"
        placeholder="Last name"
        class="w-1/2"
        :value="lastName"
        :error="lastNameError"
        :validationTrigger="$v.lastName.$touch"
        @changed="(value) => (lastName = value)"
      />
    </div>
    <div>
      <Input
        id="emailAddress"
        title="Email"
        placeholder="Email"
        :value="emailAddress"
        :error="emailAddressError"
        :validationTrigger="$v.emailAddress.$touch"
        @changed="(value) => (emailAddress = value)"
      />
    </div>
    <div class="flex flex-wrap">
      <Input
        id="countryCode"
        v-mask="'+###'"
        title="Phone country code"
        class="w-1/2"
        placeholder="Phone country code"
        :value="countryCode"
        :error="countryCodeError"
        :validationTrigger="$v.countryCode.$touch"
        @changed="(value) => (countryCode = value)"
      />
      <Input
        id="phoneNumber"
        title="Phone number"
        placeholder="Phone number"
        class="w-1/2"
        :value="phoneNumber"
        :error="phoneNumberError"
        :validationTrigger="$v.phoneNumber.$touch"
        @changed="(value) => (phoneNumber = value)"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import { required, email } from 'vuelidate/lib/validators';
import { countryCodeValidator } from '@/services/utils/regexUtils.js';
import Input from '@/components/atoms/Input.vue';

export default {
  name: 'PersonalDetails',
  directives: {
    mask,
  },
  components: {
    Input,
  },
  props: {
    showErrors: {
      type: Boolean,
      default: false,
    },
    getValue: {
      type: Function,
      default: () => {},
    },
    setValue: {
      type: Function,
      default: () => {},
    },
  },
  validations: {
    firstName: { required },
    lastName: { required },
    emailAddress: { required, email },
    countryCode: { required, countryCodeValidator },
    phoneNumber: { required },
  },
  computed: {
    firstName: {
      get() {
        return this.getValue('firstName');
      },
      set(value) {
        this.setValue(value);
      },
    },
    firstNameError() {
      if (this.$v.firstName.$invalid && this.$v.firstName.$dirty) {
        return 'Required.';
      }
      return null;
    },
    lastName: {
      get() {
        return this.getValue('lastName');
      },
      set(value) {
        this.setValue(value);
      },
    },
    lastNameError() {
      if (this.$v.lastName.$invalid && this.$v.lastName.$dirty) {
        return 'Required.';
      }
      return null;
    },
    emailAddress: {
      get() {
        return this.getValue('emailAddress');
      },
      set(value) {
        this.setValue(value);
      },
    },
    emailAddressError() {
      if (this.$v.emailAddress.$dirty) {
        if (!this.$v.emailAddress.required) {
          return 'Required.';
        }
        if (!this.$v.emailAddress.email) {
          return 'Invalid Format.';
        }
      }
      return null;
    },
    countryCode: {
      get() {
        return this.getValue('countryCode');
      },
      set(value) {
        this.setValue(value);
      },
    },
    countryCodeError() {
      if (this.$v.countryCode.$dirty) {
        if (!this.$v.countryCode.required) {
          return 'Required.';
        }
        if (!this.$v.countryCode.countryCodeValidator) {
          return 'Invalid Format.';
        }
      }
      return null;
    },
    phoneNumber: {
      get() {
        return this.getValue('phoneNumber');
      },
      set(value) {
        this.setValue(value);
      },
    },
    phoneNumberError() {
      if (this.$v.phoneNumber.$invalid && this.$v.phoneNumber.$dirty) {
        return 'Required.';
      }
      return null;
    },
    invalidValidation() {
      return this.$v.$invalid;
    },
  },
  watch: {
    invalidValidation(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setStepCompletion({ stepCompletion: !this.invalidValidation });
      }
    },
  },
  methods: {
    ...mapActions({ setStepCompletion: 'registration/setStepCompletion' }),
    touchValidation() {
      this.$v.$touch();
      if (this.invalidValidation) {
        this.setStepCompletion({ stepCompletion: !this.invalidValidation });
      }
    },
  },
};
</script>

<template>
  <div class="preloader">
    <Loader force />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import jwt_decode from 'jwt-decode';
import Loader from '@/components/atoms/Loader';

import router from '@/router';

export default {
  name: 'OidcCallback',
  components: {
    Loader,
  },
  computed: {
    ...mapGetters('oidcStore', {
      oidcToken: 'oidcAccessToken',
    }),
  },
  mounted() {
    this.oidcSignInCallback()
      .then(async () => {
        const { roles } = jwt_decode(this.oidcToken).realm_access;
        await this.setRoles({ roles });
        await this.getUserGuide();
        router.push('/');
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.error('Oidc Callback error:', err);
      });
  },
  methods: {
    ...mapActions({
      oidcSignInCallback: 'oidcStore/oidcSignInCallback',
      signOutOidc: 'oidcStore/signOutOidc',
      setRoles: 'user/setRoles',
      getUserGuide: 'user/getUserGuide',
    }),
  },
};
</script>

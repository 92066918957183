<template>
  <div v-if="menuVisibility" class="absolute top-0 left-0 shadow z-10">
    <div class="flex flex-col" :class="hideMenu ? 'hidden' : ''">
      <button
        v-for="option in options"
        :key="option.text"
        @click="handleSelection(option)"
      >
        <p class="text-left p-2.5 bg-white hover:bg-grey-hover-light">
          {{ option.text }}
        </p>
      </button>
    </div>
    <Modal name="customer-details-form" width="80%" height="auto" scrollable @closed="closeDropdown">
      <CustomerDetailsForm :customer="menuItem" :closeDropdown="closeDropdown"/>
    </Modal>
    <Modal name="customer-view-documents" width="80%" height="90%" @closed="closeDropdown">
      <ViewCustomerDocuments :item="menuItem" :closeDropdown="closeDropdown" />
    </Modal>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import CustomerDetailsForm from '../../Customers/forms/CustomerDetailsForm.vue';
import ViewCustomerDocuments from './ViewCustomerDocuments.vue';

export default {
	components: { CustomerDetailsForm, ViewCustomerDocuments },
  name: 'CustomerMenu',
  props: ['menuVisibility', 'menuItem', 'closeDropdown'],
  data: () => ({
    options: [
      {
        text: 'Details',
      },
      { text: 'Documents' },
    ],
    hideMenu: false,
  }),
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
    }),
  },
  watch: {
    menuVisibility(x, y) {
      if (x !== y) this.hideMenu = false;
    },
  },
  methods: {
    handleSelection(option) {
      switch (option.text) {
        case 'Details':
          this.$modal.show('customer-details-form');
          break;
        case 'Documents':
          this.$modal.show('customer-view-documents');
          break;
        case 'Remove':
          break;
        default:
          break;
      }
      this.hide();
    },
    hide() {
      this.hideMenu = true;
    },
  },
};
</script>

<template>
  <div>
    <Modal
      name="action-modal"
      width="90%"
      height="auto"
      @before-close="resetState"
    >
      <component :is="whichModal" />
    </Modal>
  </div>
</template>

<script>
/* eslint-disable vue/script-indent */
import { mapActions, mapState } from 'vuex';
import AddPBNModal from './ActionModals/AddPBNModal.vue';
import DutyDueModal from './ActionModals/DutyDueModal.vue';
import DutyPaymentModal from './ActionModals/DutyPaymentModal.vue';
import FundsReceivedModal from './ActionModals/FundsReceivedModal.vue';
import NoActionModal from './ActionModals/NoActionModal.vue';
import UploadDocumentModal from './ActionModals/UploadDocumentModal.vue';

export default {
  name: 'ModalHolder',
  computed: {
    ...mapState({
      openModal: (state) => state.statusActions.openModal,
      action: (state) => state.statusActions.action,
    }),
    whichModal() {
      switch (this.action) {
        case 'NONE':
          return NoActionModal;
        case 'UPLOAD_DOCUMENT':
          return UploadDocumentModal;
        case 'PAYMENT_REFERENCE':
          return FundsReceivedModal;
        case 'DUTY_PAYMENT_NEEDED':
          return DutyDueModal;
        case 'DUTY_PAYMENT_NOT_NEEDED':
          return DutyDueModal;
        case 'DUTY_PAID':
          return DutyPaymentModal;
        case 'UPDATE_PBN':
          return AddPBNModal;
        default:
          return null;
      }
    },
  },
  watch: {
    openModal() {
      if (this.openModal) {
        this.$modal.show('action-modal');
      }
    },
  },
  methods: {
    ...mapActions({
      triggerStatusUpdate: 'statusActions/triggerStatusUpdate',
      resetState: 'statusActions/resetState',
    }),
  },
};
</script>

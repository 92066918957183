<template>
  <div class="shadow bg-white flex flex-row px-5 rounded">
    <div class="flex flex-col text-center flex-1 border-r border-grey-hover-light py-5">
      <h1 class="text-l font-normal">
        WE'VE PROCESSED
      </h1>
      <p class="text-huge">
        {{ stats.vehiclesProcessed }}
      </p>
      <p class="text-md">
        purchases
      </p>
    </div>
    <div class="flex flex-col text-center flex-1 border-r border-grey-hover-light py-5">
      <h1 class="text-l font-normal">
        AUCTION HOUSE
      </h1>
      <p class="text-huge">
        {{ stats.auctionVehiclesProcessed }}
      </p>
      <p class="text-md">
        purchases
      </p>
    </div>
    <div class="flex flex-col text-center flex-1 border-r border-grey-hover-light py-5">
      <h1 class="text-l font-normal">
        FORECOURT
      </h1>
      <p class="text-huge">
        {{ stats.forecourtVehiclesProcessed }}
      </p>
      <p class="text-md">
        purchases
      </p>
    </div>
    <div class="flex flex-col text-center flex-1 py-5">
      <h1 class="text-l font-normal">
        AVG TIME TO COMPLETION
      </h1>
      <p class="text-huge">
        {{ avgTime }}
      </p>
      <p class="text-md">
        Days
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsStatistics',
  props: ['stats'],
  computed: {
    avgTime() {
      return this.stats.averageProcessingTime ? this.stats.averageProcessingTime.toFixed(1) : 0;
    },
  },
};
</script>

<style></style>

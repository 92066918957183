<template>
  <div class="text-left" :class="[hasPadding && 'px-3', hasMargin && 'mb-4']">
    <label
      v-show="title"
      :for="id"
      class="block tracking-wide text-black-light text-xs font-bold mb-2"
      >{{ title }}</label
    >
    <v-select
      :id="id"
      class="port-select"
      :class="{'z-0': isDisabled}"
      :searchable="searchable"
      transition=""
      :options="options"
      :placeholder="placeholder"
      :clearable="false"
      :value="actualValue"
      :disabled="isDisabled"
      @input="(val) => setValue(val)"
    ></v-select>
    <p v-if="error" class="text-red text-xs italic">{{ error }}</p>
  </div>
</template>
<script>
export default {
  name: 'Select',
  props: {
    id: {
      type: String,
      required: true,
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
    hasMargin: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, String],
      default: () => null,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [
        { label: 'First option', value: 1 },
        { label: 'Second option', value: 2 },
        { label: 'Third option', value: 3 },
      ],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    reducedOptions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actualValue() {
      if (typeof this.value === 'string' && !this.reducedOptions) {
        return this.options.find((el) => el.value === this.value || el.label === this.value);
      }
      return this.value;
    },
  },
  methods: {
    setValue(val) {
      this.$emit('changed', { id: this.id, value: val });
    },
  },
};
</script>
<style lang="css">
.port-select .vs__search {
  @apply pl-3.5;
}
.port-select .vs__selected {
  @apply pl-3;
}
.port-select .vs__dropdown-option {
  font-size: 14px !important;
  line-height: 2.25 !important;
  padding-left: 18px;
}
.port-select .vs__dropdown-menu {
  padding: 0;
}
.port-select.vs--single.vs--open .vs__selected {
  opacity: 1 !important;
}
</style>

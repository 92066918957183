<template>
  <div class="h-full">
    <component
      :is="homeComponent"
      :openAuction="openAuction"
      :openForecourt="openForecourt"
      :openTransport="openTransport"
      :tab="tab"
      @changeToDashboard="setTable"
    />

    <Modal name="forecourt-form" width="90%" height="auto" scrollable class="relative">
      <img
        :src="close"
        class="absolute top-8 right-8 cursor-pointer"
        @click="closeForecourt"
      />
      <ForecourtForm :closeFn="closeForecourt" />
    </Modal>
    <Modal name="auction-form" width="90%" height="auto" scrollable class="relative">
      <img
        :src="close"
        class="absolute top-8 right-8 cursor-pointer"
        @click="closeAuction"
      />
      <AuctionForm :closeFn="closeAuction" />
    </Modal>
    <Modal
      name="transport-form"
      width="90%"
      height="auto"
      scrollable
      class="relative"
    >
      <img
        :src="close"
        class="absolute top-8 right-8 cursor-pointer"
        @click="closeTransport"
      />
      <NewLoad :closeFn="closeTransport" />
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import EmptyHome from '@/components/molecules/Home/EmptyHome.vue';
import CustomerHome from '@/components/molecules/Home/CustomerHome';
import StaffHome from '@/components/molecules/Home/StaffHome';
import ForecourtForm from '@/components/molecules/Home/Forms/ForecourtForm.vue';
import AuctionForm from '@/components/molecules/Home/Forms/AuctionForm.vue';
import NewLoad from '@/components/molecules/Home/NewLoad/index.vue';
import close from '@/assets/icons/close.svg';

export default {
  name: 'Home',
  components: {
    EmptyHome,
    CustomerHome,
    StaffHome,
    ForecourtForm,
    AuctionForm,
    NewLoad,
  },
  data: () => ({
    close,
    homeComponent: null,
    tab: 0,
  }),
  computed: {
    ...mapState({
      hasVehicles: (state) => state.user.hasVehicles,
    }),
    ...mapGetters({
      roles: 'user/getRoles',
    }),
  },
  async mounted() {
    await this.getUser();
    await this.checkVehicles();
    this.setHomeComponent();
  },
  methods: {
    ...mapActions({
      checkVehicles: 'vehicles/checkVehicles',
      getUser: 'user/getUser',
    }),
    setTable() {
      this.homeComponent = 'CustomerHome';
    },
    setHomeComponent() {
      if (this.roles.indexOf('APP_ADMIN') > -1) {
        this.homeComponent = 'StaffHome';
        return;
      }
      this.homeComponent = 'EmptyHome';
    },
    openForecourt() {
      this.$modal.show('forecourt-form');
    },
    closeForecourt() {
      this.$modal.hide('forecourt-form');
      this.homeComponent = 'CustomerHome';
      this.tab = 1;
    },
    openAuction() {
      this.$modal.show('auction-form');
    },
    closeAuction() {
      this.$modal.hide('auction-form');
      this.homeComponent = 'CustomerHome';
      this.tab = 0;
    },
    openTransport() {
      this.$modal.show('transport-form');
    },
    closeTransport() {
      this.$modal.hide('transport-form');
    },
  },
};
</script>

import Vue from 'vue';
import VueTailwind from 'vue-tailwind';
import VueToast from 'vue-toast-notification';

import 'tailwindcss/tailwind.css';
import './assets/css/tailwind.css';
import './assets/scss/main.scss';

import { TInput } from 'vue-tailwind/dist/components';
import VModal from 'vue-js-modal';
import vSelect from 'vue-select';
import VTooltip from 'v-tooltip';
import Vuelidate from 'vuelidate';

import store from './store';
import router from './router';
import App from './App.vue';
import 'vue-toast-notification/dist/theme-sugar.css';

// Import one of the available themes

Vue.config.productionTip = false;

const settings = {
  't-input': {
    component: TInput,
    props: {
      classes: 'border-blue-200',
    },
  },
};

Vue.use(VueToast, {
  position: 'top-right',
  duration: 10000,
});

Vue.filter('toCurrency', (value) => {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    // eslint-disable-next-line
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression]();
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Vue.use(VueTailwind, settings);
Vue.use(VModal, { componentName: 'Modal' });
Vue.component('v-select', vSelect);
Vue.use(VTooltip);
Vue.use(Vuelidate);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');

<template>
  <div>
    <label
      v-if="title"
      :for="id"
      class="block tracking-wide text-left text-gray-700 text-xs font-bold mb-2"
      >{{ title }}</label
    >
    <div class="flex flex-row items-center justify-center space-x-2">
      <Button
        v-if="filesArray.length === 0"
        title="Upload Documents"
        type="tertiary"
        thicc
        :icon="document"
        :class="btnClass"
        @click.native="openModal"
      />
      <Button
        v-else
        title="View Documents"
        class="underline"
        type="tertiary"
        noBorder
        :icon="document"
        :class="btnClass"
        @click.native="openModal"
      />
      <slot></slot>
    </div>
    <Modal name="upload-document-modal" width="80%" height="auto" scrollable>
      <DragAndDrop :id="id" :closeModal="closeModal" :submitFn="submit" :multiple="true" :files="filesArray" @changed="(files) => onChange(files)">
          <table
            class="text-left border-separate"
            style="border-spacing: 0 12px;"
          >
            <thead class="mb-2 text-mdl">
              <th class="text-blue font-medium">
                Name
              </th>
              <th class="text-blue font-medium">
                Upload Time
              </th>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in filesArray" :key="idx" class="text-l">
                <td class="pr-10 min-w-250">{{ item.file.name }}</td>
                <td class="pr-7">
                  {{ formatDate(item.file.lastModifiedDate) }}
                </td>
                <td class="pr-7 cursor-pointer" @click="downloadFn(item.id)">
                  <img :src="download" />
                </td>
                <td
                  class="pr-10 text-red-dark cursor-pointer"
                  @click="removeFn(item.id)"
                >
                  Remove
                </td>
              </tr>
            </tbody>
          </table>
      </DragAndDrop>
    </Modal>
  </div>
</template>

<script>
import moment from 'moment';
import DragAndDrop from '@/components/molecules/Uploader/DragAndDrop.vue';
import document from '@/assets/icons/document.svg';
import download from '@/assets/icons/download.svg';
import fileImg from '@/assets/icons/file.svg';
import close from '@/assets/icons/close.svg';
import Button from './Button.vue';

export default {
  name: 'UploadInput',
  components: {
    Button,
    DragAndDrop,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    btnClass: {
      type: String,
      default: '',
    },
    files: {
      type: Array,
      default: () => [],
    },
    removeFn: {
      type: Function,
      default: () => null,
    },
    downloadFn: {
      type: Function,
      default: () => null,
    },
    submitFn: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({
    document,
    fileImg,
    download,
    close,
  }),
  computed: {
    filesArray() {
      return this.files ? Array.from(this.files) : [];
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MMM Do, hh:mm');
    },
    async submit() {
      if (this.submitFn) {
        await this.submitFn();
      }
      this.closeModal();
    },
    openFile() {
      this.$refs[this.id].click();
    },
    onChange(files) {
      this.$emit('changed', files);
    },
    openModal() {
      this.$modal.show('upload-document-modal');
    },
    closeModal() {
      this.$modal.hide('upload-document-modal');
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.$refs[this.id].files = event.dataTransfer.files;
      this.onChange();
    },
  },
};
</script>

<style></style>

import getAuctionHouses from '@/services/api/auctionHouses';

export default {
  namespaced: true,
  state: {
    items: [],
    houses: [],
  },
  actions: {
    async getAuctionHouses({ commit }) {
      const auctionHouses = await getAuctionHouses();
      const branches = [];
      auctionHouses.data.forEach((auctionHouse) => {
        branches.push(...auctionHouse.branches);
      });
      commit('SET_AUCTION_BRANCHES', branches);
      commit('SET_AUCTION_HOUSES', auctionHouses.data);
    },
  },
  mutations: {
    SET_AUCTION_BRANCHES(state, branches) {
      state.items = branches;
    },
    SET_AUCTION_HOUSES(state, houses) {
      state.houses = houses;
    },
  },
};

<template>
  <div>
    <div class="flex flex-row space-x-10">
      <div class="p-5 flex-1 shadow rounded min-h-440">
        <VehiclesPerTime
          v-if="vehiclesPerTime"
          :chartData="vehiclesPerTime"
          :options="vehiclePerTimeOptions"
          :height="200"
        />
        <div v-else class="flex flex-col items-center justify-center h-full">
          <img :src="EmptyLine" class="w-120" />
          <p class="mt-5 w-1/5 text-center text-grey-light">
            No data available currently
          </p>
        </div>
      </div>
      <div class="p-5 shadow rounded min-w-400">
        <VehiclesPerAuctionHouse
          v-if="vehiclesByHouse"
          :chartData="vehiclesByHouse"
        />
        <div v-else class="flex flex-col items-center justify-center h-full">
          <img :src="EmptyPie" class="w-120" />
          <p class="mt-5 w-1/2 text-center text-grey-light">
            No data available currently
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VehiclesPerTime from './components/VehiclesPerTime.vue';
import VehiclesPerAuctionHouse from './components/VehiclesPerAuctionHouse.vue';
import EmptyPie from '@/assets/icons/charts/pie.svg';
import EmptyLine from '@/assets/icons/charts/line.svg';

export default {
  name: 'AnalyticsCharts',
  components: {
    VehiclesPerAuctionHouse,
    VehiclesPerTime,
  },
  data: () => ({
    EmptyPie,
    EmptyLine,
  }),
  computed: {
    ...mapState({
      vehiclesTransportedOverTime: (state) => state.analytics.data.vehiclesTransportedOverTime,
      vehiclesByAuctionHouse: (state) => state.analytics.data.vehiclesByAuctionHouse,
    }),
    vehiclePerTimeOptions() {
      return {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
              },
            },
          ],
        },
      };
    },
    vehiclesPerTime() {
      const labels = [];
      const values = [];
      if (this.vehiclesTransportedOverTime) {
        Object.keys(this.vehiclesTransportedOverTime).forEach((el) => {
          labels.push(el);
          values.push(this.vehiclesTransportedOverTime[el]);
        });
      }
      const datasets = [
        {
          label: 'Vehicles',
          borderColor: '#047ABD',
          tension: 0.1,
          data: values,
          fill: false,
        },
      ];
      if (values.length === 0) {
        return null;
      }
      return {
        labels,
        datasets,
      };
    },
    vehiclesByHouse() {
      const labels = [];
      const values = [];
      if (this.vehiclesByAuctionHouse) {
        Object.keys(this.vehiclesByAuctionHouse).forEach((el) => {
          labels.push(el);
          values.push(this.vehiclesByAuctionHouse[el]);
        });
      }
      const datasets = [
        {
          label: 'Dataset one',
          backgroundColor: ['#41B883', '#E46651', '#00D8FF'],
          data: values,
        },
      ];
      if (values.length === 0) {
        return null;
      }
      return {
        hoverBackgroundColor: 'red',
        hoverBorderWidth: 10,
        labels,
        datasets,
      };
    },
  },
};
</script>

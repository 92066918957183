import { apiCall } from './index';

export const uploadVehicleDocument = (item, vehicleId) => {
  const formData = new FormData();
  formData.append('document', item.file);
  formData.append('type', item.type);
  return apiCall('POST', `vehicle/${vehicleId}/document`, formData);
};

export const dummy = () => 'dummy';

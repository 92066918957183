<template>
  <div>
    <div class="flex flex-row h-full items-center justify-center px-20">
      <div class="flex flex-row">
        <div class="w-1/2 p-20 px-24 flex flex-col hover:shadow transition-all">
          <h1 class="text-center py-3">Request Auction Transfer</h1>
          <p class="text-center px-4 py-3 text-l">
            Please click here to add the details of your Auction Purchase to
            your Profile and request transfer of the purchase to Portnetic
            Account
          </p>
          <div class="mx-auto pt-2">
            <Button
              title="Auction Transfer"
              type="primary"
              @click.native="openAuction"
            />
          </div>
        </div>
        <div class="w-1/2 p-20 px-24 flex flex-col hover:shadow transition-all">
          <h1 class="text-center py-3">Request Forecourt Purchase</h1>
          <p class="text-center px-4 py-3 text-l">
            Please click here to provide the details to request a Forecourt
            Purchase
          </p>
          <div class="mx-auto mt-auto">
            <Button
              title="Forecourt Purchase"
              type="primary"
              @click.native="openForecourt"
            />
          </div>
        </div>
      </div>
    </div>
    <p class="text-center py-5 text-blue font-bold text-md cursor-pointer" @click="changeToDashboard">Continue to existing purchases</p>
  </div>
</template>

<script>
import Button from '@/components/atoms/Button.vue';

export default {
  name: 'EmptyHome',
  components: {
    Button,
  },
  props: {
    openAuction: {
      type: Function,
      default: () => null,
    },
    openForecourt: {
      type: Function,
      default: () => null,
    },
    openTransport: {
      type: Function,
      default: () => null,
    },
  },
  methods: {
    changeToDashboard() {
      this.$emit('changeToDashboard');
    },
  },
};
</script>

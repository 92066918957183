<template>
  <div class="bg-white">
    <div class="absolute right-6 top-9">
      <img :src="close" class="cursor-pointer" @click="closeModal" />
    </div>
    <div class="flex flex-col items-center h-90vh justify-center">
      <div class="flex flex-col items-center justify-center">
        <p class="mr-11 my-5 text-xxl text-center text-black font-bold">Documents</p>
        <p class="mr-6 text-l text-black font-medium">
          {{ item.firstName }} {{ item.lastName }} | {{ item.companyName }}
          {{ item.model }}
        </p>
        <div v-if="isAdmin" class=" relative ml-20 left-56 bottom-16">
          <Button
            title="Upload"
            type="tertiary"
            thicc
            :icon="documentIcon"
            @click.native="openUploader"
          />
        </div>
      </div>
      <div
        v-if="documents && documents.length !== 0"
        class="mt-60 flex justify-center overflow-y-scroll max-h-table"
        :class="isAdmin ? 'w-80%' : 'w-60%'"
      >
        <table class="text-left border-separate" style="border-spacing: 0 6px;">
          <thead class="mb-2 text-mdl">
            <th class="text-blue font-medium">
              Name
            </th>
            <th class="text-blue font-medium">Date Added</th>
            <th></th>
            <th v-if="isAdmin"></th>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in documents" :key="idx" class="text-l">
              <td class="pr-10 max-w-200 truncate">{{ item.name }}</td>
              <td class="pr-7">
                {{ formatDate(item.created) }}
              </td>
              <td
                class="pr-10 text-red-dark cursor-pointer"
                @click="downloadItem(item.url, item.name)"
              >
                <img :src="download" />
              </td>
              <td
                v-if="isAdmin"
                class="pr-10 text-red-dark cursor-pointer"
                @click="deleteItem(item.id)"
              >
                Remove
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="flex flex-col justify-center items-center mt-60">
        <img :src="documentBig" width="80" height="84" class="mb-5" />
        <p class="text-grey-disabled-light-2 text-l">
          You have no documents uploaded yet.
        </p>
        <p class="text-grey-disabled-light-2 text-l">
          When documents get added they will appear here.
        </p>
      </div>
    </div>
    <Modal name="documents-form" width="80%" height="auto">
      <DocumentsForm
        :item="item"
        :submitFn="submitFn"
        :files="newDocuments"
        :changeDocumentType="changeDocumentType"
        :addDocument="addDocument"
        :removeDocument='removeUnsubmittedDocument'
        :closeModal='closeUploadDocumentsModal'
        isCustomer
      />
    </Modal>
  </div>
</template>
<script>
/* eslint-disable operator-linebreak */

import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import Button from '@/components/atoms/Button';
import DocumentsForm from '@/components/molecules/Home/Forms/DocumentsForm.vue';
import close from '@/assets/icons/close.svg';
import download from '@/assets/icons/download.svg';
import documentIcon from '@/assets/icons/document.svg';
import documentBig from '@/assets/icons/documentBig.svg';

export default {
  name: 'ViewCustomerDocuments',
  components: { Button, DocumentsForm },
  props: ['item', 'closeDropdown'],
  data: () => ({
    download,
    close,
    documentIcon,
    documentBig,
    newDocs: [],
  }),
  computed: {
    ...mapState({
      newCustomerDocuments: (state) => state.customers.newCustomerDocuments,
      documents: (state) => state.customers.documents,
    }),
    ...mapGetters({
      isAdmin: 'user/isAdmin',
    }),
    newDocuments() {
      return (
        this.newCustomerDocuments[this.item.id] &&
        this.newCustomerDocuments[this.item.id].documents
      );
    },
  },
  async mounted() {
    await this.getCustomerDocuments({ id: this.item.userId });
  },
  methods: {
    ...mapActions({
      deleteDocument: 'customers/deleteDocument',
      changeDocumentType: 'customers/changeDocumentType',
      addDocument: 'customers/addDocument',
      uploadDocument: 'customers/uploadDocument',
      getCustomerDocuments: 'customers/getCustomerDocuments',
      clearNewCustomerDocuments: 'customers/clearNewCustomerDocuments',
    }),
    closeModal() {
      this.$modal.hide('view-documents');
      this.closeDropdown();
    },
    formatDate(date) {
      return moment(date).format('MMM Do, hh:mm');
    },
    downloadItem(url, name) {
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    openUploader() {
      this.$modal.show('documents-form');
    },
    closeUploadDocumentsModal() {
      this.$modal.hide('documents-form');
      this.clearNewCustomerDocuments({ customerId: this.item.id });
    },
    removeUnsubmittedDocument(customerId, documentId) {
      this.clearNewCustomerDocuments({ customerId, documentId });
    },
    async deleteItem(id) {
      await this.deleteDocument({ id });
    },
    submitFn() {
      this.newDocuments.forEach(async (document) => {
        await this.uploadDocument({
          customer: this.item,
          document: document.file,
          type: document.type,
        });
      });
      this.$modal.hide('documents-form');
    },
  },
};
</script>

<template>
  <div class="flex flex-col items-center overflow-scroll h-full py-16">
    <img
      :src="close"
      class="absolute top-5 right-5 cursor-pointer"
      @click="closeModal"
    />
    <h1>Details</h1>
    <p class="pt-2 text-l w-3/5 text-center">
      {{ `${customer.firstName} ${customer.lastName}` }} ||
      {{ customer.companyName }}
    </p>
    <div class="flex flex-col w-400 mt-4">
      <div class="flex flex-row w-full justify-center mx-auto mt-3">
        <Input
          id="companyName"
          title="Company name"
          :value="companyName"
          placeholder="Company"
          :error="companyError"
          :validationTrigger="$v.companyName.$touch"
          @changed="(value) => (companyName = value)"
        />
        <Input
          id="companyEircode"
          title="Eircode"
          :value="companyEircode"
          placeholder="Eircode"
          :error="eircodeError"
          :validationTrigger="$v.companyEircode.$touch"
          @changed="(value) => (companyEircode = value)"
        />
      </div>
      <div class="flex w-full justify-center mx-auto">
        <Input
          id="companyAddress"
          title="Address"
          class="w-full"
          :value="companyAddress"
          placeholder="Address"
          :error="addressError"
          :validationTrigger="$v.companyAddress.$touch"
          @changed="(value) => (companyAddress = value)"
        />
      </div>
      <div v-if="ahSections.length > 0" class="flex flex-col w-full my-auto pb-4">
        <div
          v-for="(section, idx) in ahSections"
          :key="section.ourId"
          class="flex flex-row relative"
        >
          <Select
            :id="`house-${section.ourId}`"
            :options="auctionHouseOptions()"
            reducedOptions
            class="w-1/2"
            placeholder="Auction House"
            title="Auction House"
            :value="getHouseValue(section.ourId).house"
            @changed="(val) => setHouseValue(val, section.ourId, 'house')"
          />
          <Input
            :id="`houseNr-${section.ourId}`"
            class="w-1/2"
            placeholder="Auction House Nr"
            title="Auction House Number"
            :value="getHouseValue(section.ourId).houseNumber"
            @changed="(val) => setHouseValue(val, section.ourId, 'houseNumber')"
          />
          <p
            v-if="showRemove(idx)"
            class="text-l text-red-dark absolute cursor-pointer remove-ah"
            @click.stop="removeAh(section.ourId)"
          >
            Remove
          </p>
        </div>
        <p
          v-if="houses.length >= ahSections.length + 1"
          class="flex flex-row justify-center font-medium cursor-pointer"
          @click.stop="addNewHouse"
        >
          Add another auction house
        </p>
      </div>
      <div class="flex flex-row w-full mx-auto">
        <Input
          id="countryCode"
          v-mask="'+###'"
          title="Country code"
          class="w-1/2"
          :value="countryCode"
          :error="countryCodeError"
          :validationTrigger="$v.countryCode.$touch"
          @changed="(value) => (countryCode = value)"
        />
        <Input
          id="phoneNumber"
          title="Number"
          class="w-1/2"
          :value="phoneNumber"
          :error="phoneNumberError"
          :validationTrigger="$v.phoneNumber.$touch"
          @changed="(value) => (phoneNumber = value)"
        />
      </div>
      <div class="flex flex-row w-full justify-center mx-auto">
        <Button
          title="Update"
          class="mt-3"
          type="primary"
          :isDisabled="!disableSubmit"
          @click.native="save"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mask } from 'vue-the-mask';
import { required, alphaNum } from 'vuelidate/lib/validators';
import { eirCodeValidator } from '@/services/utils/regexUtils.js';
import close from '@/assets/icons/close.svg';
import Input from '@/components/atoms/Input.vue';
import Button from '@/components/atoms/Button.vue';
import Select from '@/components/atoms/Select.vue';

export default {
  name: 'CustomerDetailsForm',
  directives: {
    mask,
  },
  components: {
    Input,
    Select,
    Button,
  },
  props: {
    closeFn: {
      type: Function,
      default: () => null,
    },
    customer: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    close,
  }),
  validations: {
    companyName: { required },
    auctionHouseName: { required },
    companyEircode: { required, eirCodeValidator },
    companyAddress: { required },
    countryCode: { required },
    phoneNumber: {
      required,
      alphaNum,
    },
  },
  computed: {
    ...mapState({
      data: (state) => state.customers.customer,
      houses: (state) => state.auctionHouses.houses,
      ahSections: (state) => state.customers.ahSections,
    }),
    companyName: {
      get() {
        return this.data.companyName;
      },
      set(val) {
        this.updateCustomerField({
          value: val.value,
          field: val.id,
        });
      },
    },
    companyEircode: {
      get() {
        return this.data.companyEircode;
      },
      set(val) {
        this.updateCustomerField({
          value: val.value,
          field: val.id,
        });
      },
    },
    companyAddress: {
      get() {
        return this.data.companyAddress;
      },
      set(val) {
        this.updateCustomerField({
          value: val.value,
          field: val.id,
        });
      },
    },
    countryCode: {
      get() {
        return this.data.countryCode;
      },
      set(val) {
        this.updateCustomerField({
          value: val.value,
          field: val.id,
        });
      },
    },
    phoneNumber: {
      get() {
        return this.data.phoneNumber;
      },
      set(val) {
        this.updateCustomerField({
          value: val.value,
          field: val.id,
        });
      },
    },
    auctionHouseName: {
      get() {
        return this.data.auctionHouseName;
      },
      set(val) {
        this.updateCustomerField({
          value: val.value,
          field: val.id,
        });
      },
    },
    companyError() {
      if (this.$v.companyName.$dirty) {
        if (!this.$v.companyName.required) {
          return 'Required.';
        }
      }
      return null;
    },
    eircodeError() {
      if (this.$v.companyEircode.$dirty) {
        if (!this.$v.companyEircode.required) {
          return 'Required.';
        }
        if (!this.$v.companyEircode.eirCodeValidator) {
          return 'Invalid Format.';
        }
      }
      return null;
    },
    addressError() {
      if (this.$v.companyAddress.$dirty) {
        if (!this.$v.companyAddress.required) {
          return 'Required.';
        }
      }
      return null;
    },
    countryCodeError() {
      if (this.$v.countryCode.$dirty) {
        if (!this.$v.countryCode.required) {
          return 'Required.';
        }
        if (!this.$v.countryCode.countryCodeValidator) {
          return 'Invalid Format.';
        }
      }
      return null;
    },
    phoneNumberError() {
      if (this.$v.phoneNumber.$invalid && this.$v.phoneNumber.$dirty) {
        return 'Required.';
      }
      return null;
    },
    disableSubmit() {
      return this.$v.$invalid;
    },
  },
  mounted() {
    this.getAuctionHouses();
    this.getUser(this.customer);
  },
  methods: {
    ...mapActions({
      updateField: 'customers/updateField',
      updateCustomerField: 'customers/updateCustomerField',
      inviteCustomer: 'customers/inviteCustomer',
      getAuctionHouses: 'auctionHouses/getAuctionHouses',
      updateCustomer: 'customers/updateCustomer',
      getUser: 'customers/getUser',
      addNewHouse: 'customers/addNewHouse',
      setAHvalue: 'customers/setAHvalue',
      removeAh: 'customers/removeAh',
    }),
    isValid() {
      return !this.companyError;
    },
    async save() {
      this.$modal.hide('customer-details-form');
      await this.updateCustomer();
      this.closeFn();
    },
    showRemove(idx) {
      return idx > 0;
    },
    setHouseValue(val, id, key) {
      this.setAHvalue({ val, id, key });
    },
    getHouseValue(id) {
      return this.ahSections.find((el) => el.ourId === id);
    },
    auctionHouseOptions() {
      const allHouses = this.houses && this.houses.map((house) => ({
        label: house.name,
        value: house.id,
      }));
      this.ahSections.map((ahSection) => {
        const houseIndex = allHouses.findIndex((house) => house.value === ahSection.id);
        if (houseIndex !== -1) {
          allHouses.splice(houseIndex, 1);
        }
      });
      return allHouses;
    },
    closeModal() {
      this.$modal.hide('customer-details-form');
    },
  },
};
</script>
<style lang="scss" scoped>
.remove-ah {
  right: 0;
  top: 33px;
  margin-right: -70px;
}
</style>

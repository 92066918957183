<template>
  <div class="mb-20">
    <ul class="space-y-3">
      <li class="flex text-blue text-l font-medium mb-2 mt-4">
        <p class="w-150 text-right">Date & Time</p>
        <div class="circle transparent last" />
        <p>Status History</p>
      </li>
      <li
        v-for="(item, index) in fullRoute"
        :key="index"
        :test-id="`progress-bar-link-${index}`"
        class="flex items-center"
      >
        <p
          class="text-l text-black w-150 text-right"
          :class="index > steps.length ? 'invisible' : ''"
        >
          {{ getDateForItem(item) }}
        </p>
        <div>
          <div
            class="circle"
            :class="index === fullRoute.length - 1 ? 'last' : ''"
          />
        </div>
        <p
          class="text-l mr-3"
          :class="
            isActive(item) ? `text-${item.color}` : 'text-grey-disabled-light'
          "
        >
          {{ item.label }}
        </p>
        <StatusTooltip
          :label="item.label"
          :description="
            formatStatus(reverseFormatStatus(item.label)).description
          "
          :color="item.color"
          openDirection="right"
          class="-mb-1.5 ml-auto"
        />
      </li>
    </ul>
  </div>
</template>
<script>
/* eslint-disable */
import moment from 'moment';
import {
  formatStatus,
  reverseFormatStatus,
} from '@/services/utils/formatEnums';
import info from '@/assets/icons/info.svg';
import StatusTooltip from '../../../atoms/StatusTooltip.vue';

const alternativeRoute = [
  { label: 'Awaiting Confirmation', color: 'orange' },
  { label: 'No Purchase Agreed', color: 'dark-red' },
];

const loadsRoute = [
  {
    label: 'Awaiting PBN',
    color: 'orange',
  },
  {
    label: 'PBN Received',
    color: 'orange',
  },
  {
    label: 'Load Sent',
    color: 'green',
  },
];
export default {
  name: 'ProgressBar',
  components: { StatusTooltip },
  props: ['steps', 'type'],
  data: () => ({
    formatStatus,
    reverseFormatStatus,
    fullRoute: [],
    info,
  }),
  mounted() {
    if (this.type === 'LOAD') {
      this.fullRoute = loadsRoute;
    } else {
      let oddStatus;
      if (this.type === 'AUCTION') {
        oddStatus = { label: 'Transfer Confirmed', color: 'green' };
      } else {
        oddStatus = { label: 'Purchase Agreed', color: 'green' };
      }
      const route = [
        { label: 'Awaiting Confirmation', color: 'orange' },
        oddStatus,
        { label: 'Awaiting Payment', color: 'orange' },
        { label: 'Payment Received', color: 'green' },
        { label: 'Customs Clearance', color: 'blue' },
        { label: 'Duty Due', color: 'orange' },
        { label: 'Ready for Transport', color: 'green' },
        { label: 'Transporting', color: 'purple' },
        { label: 'Delivery Completed', color: 'green' },
      ];
      if (this.steps.find((el) => el.status === 'NO_PURCHASE_AGREED')) {
        this.fullRoute = alternativeRoute;
      } else {
        this.fullRoute = route;
      }
      const hasSkippedDutyDue =
        this.steps.length >= 6 &&
        !this.steps.some((element) => element.status === 'DUTY_DUE');
      if (hasSkippedDutyDue) this.fullRoute.splice(5, 1);
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MMM Do, hh:mm');
    },
    getDateForItem(item) {
      let date = null;
      let found = this.findItemBasedOnLabel(item.label);
      if (found) date = this.formatDate(found.created);
      return date;
    },
    isActive(item) {
      return !!this.findItemBasedOnLabel(item.label);
    },
    findItemBasedOnLabel(label) {
      let toFind = this.reverseFormatStatus(label);
      if (this.type !== 'AUCTION' && label === 'Transfer Confirmed')
        toFind = this.reverseFormatStatus('Purchase Agreed');
      return this.steps.find((el) => el.status === toFind);
    },
  },
};
</script>

<style scoped>
.circle {
  border-radius: 50%;
  border: 8px solid #53b044;
  margin-right: 32px;
  margin-left: 32px;
  position: relative;
}
.circle:after {
  content: '';
  border: 2px solid #53b044;
  height: 37px;
  left: -2px;
  position: absolute;
  background-color: #53b044;
}
.circle.last:after {
  content: none;
}
.circle.transparent {
  border-radius: 50%;
  border: 8px solid transparent;
  margin-right: 32px;
  margin-left: 32px;
  position: relative;
}
</style>

<template>
  <div class="flex flex-col items-center overflow-scroll h-full py-16">
    <img
      :src="close"
      class="absolute top-5 right-5 cursor-pointer"
      @click="closeModal"
    />
    <h1>View / Edit</h1>
    <p class="pt-5 text-l">
      Please click here to add the details of your
      {{ isForecourt ? 'Forecourt' : 'Auction' }} House purchase.
    </p>
    <div class="w-300 mx-auto mt-5">
      <Input
        id="registrationNumber"
        title="Car Registration Number"
        :value="registrationNumber"
        placeholder="BD51 CSV"
        :error="registrationNumberError"
        :validationTrigger="$v.registrationNumber.$touch"
        @changed="(val) => (registrationNumber = val)"
      />
      <Select
        v-if="!isForecourt"
        :id="`house-${item.auctionHouseBranchId}`"
        :options="auctionHouseOptions"
        placeholder="Select"
        title="Auction House"
        :isDisabled="true"
        :value="auctionHouseName"
        @changed="(value) => (auctionHouseName = value)"
      />
      <Input
        v-else
        id="sellerUrl"
        title="Link"
        placeholder="www.autotrader.uk"
        :value="sellerUrl"
        :error="sellerUrlError"
        :validationTrigger="$v.sellerUrl.$touch"
        @changed="(val) => (sellerUrl = val)"
      />
      <Input
        id="make"
        title="Make"
        placeholder="Audi"
        :value="make"
        :error="makeError"
        :validationTrigger="$v.make.$touch"
        @changed="(val) => (make = val)"
      />
      <Input
        id="model"
        title="Model"
        placeholder="A7"
        :value="model"
        :error="modelError"
        :validationTrigger="$v.model.$touch"
        @changed="(val) => (model = val)"
      />
      <Input
        id="paymentReference"
        title="Payment Reference"
        placeholder="Payment Reference"
        :value="paymentReference"
        :error="paymentReferenceError"
        :validationTrigger="$v.paymentReference.$touch"
        @changed="(val) => (paymentReference = val)"
      />
      <Input
        v-if="isForecourt"
        id="maxPrice"
        title="Max Price"
        placeholder="25000"
        :value="maxPrice"
        :error="maxPriceError"
        :validationTrigger="$v.maxPrice.$touch"
        @changed="(val) => (maxPrice = val)"
      />
    </div>
    <div class="flex flex-row w-full justify-center mx-auto">
      <Button title="Update" class="mt-3" type="primary" @click.native="save" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required, alphaNum } from 'vuelidate/lib/validators';
import close from '@/assets/icons/close.svg';
import Input from '@/components/atoms/Input.vue';
import Select from '@/components/atoms/Select.vue';
import Button from '@/components/atoms/Button.vue';
import {
  linkFormatValidator,
} from '@/services/utils/regexUtils.js';

export default {
  name: 'EditPurchaseForm',
  components: {
    Input,
    Button,
    Select,
  },
  props: ['item', 'closeDropdown'],
  data: () => ({
    close,
  }),
  validations: {
    registrationNumber: { required },
    make: { required },
    model: { required },
    paymentReference: { required },
    sellerUrl: { required, linkFormatValidator },
    maxPrice: { required, alphaNum },
  },
  computed: {
    ...mapState({
      houses: (state) => state.auctionHouses.houses,
      auction: (state) => state.vehicles.auction,
      currentVehicle: (state) => state.vehicles.currentVehicle,
    }),
    isForecourt() {
      return this.item.purchaseType === 'FORECOURT';
    },
    registrationNumber: {
      get() {
        return this.currentVehicle.registrationNumber;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'currentVehicle',
        });
      },
    },
    registrationNumberError() {
      if (this.$v.registrationNumber.$dirty) {
        if (!this.$v.registrationNumber.required) {
          return 'Required.';
        }
      }
      return null;
    },
    auctionHouseName: {
      get() {
        return this.auction.auctionHouseName;
      },
      set(val) {
        this.updateField({
          value: val.value.label,
          field: 'auctionHouseName',
          type: 'auction',
        });
        this.updateField({
          value: val.value.value,
          field: 'auctionHouseBranch',
          type: 'auction',
        });
      },
    },
    auctionHouseOptions() {
      return (
        this.houses
        && this.houses.map((house) => ({
          label: house.name,
          value: house.id,
        }))
      );
    },
    make: {
      get() {
        return this.currentVehicle.make;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'currentVehicle',
        });
      },
    },
    makeError() {
      if (this.$v.make.$invalid && this.$v.make.$dirty) {
        return 'Required.';
      }
      return null;
    },
    model: {
      get() {
        return this.currentVehicle.model;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'currentVehicle',
        });
      },
    },
    modelError() {
      if (this.$v.model.$invalid && this.$v.model.$dirty) {
        return 'Required.';
      }
      return null;
    },
    paymentReference: {
      get() {
        return this.currentVehicle.paymentReference;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'currentVehicle',
        });
      },
    },
    paymentReferenceError() {
      if (
        this.$v.paymentReference.$invalid
        && this.$v.paymentReference.$dirty
      ) {
        return 'Required.';
      }
      return null;
    },
    sellerUrl: {
      get() {
        return this.currentVehicle.sellerUrl;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'currentVehicle',
        });
      },
    },
    sellerUrlError() {
      if (this.$v.sellerUrl.$dirty) {
        if (!this.$v.sellerUrl.required) {
          return 'Required.';
        }
        if (!this.$v.sellerUrl.linkFormatValidator) {
          return 'Invalid Format.';
        }
      }
      return null;
    },
    maxPrice: {
      get() {
        return String(this.currentVehicle.maxPrice);
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
          type: 'currentVehicle',
        });
      },
    },
    maxPriceError() {
      if (this.$v.maxPrice.$dirty && this.$v.maxPrice.$invalid) {
        if (!this.$v.maxPrice.required) {
          return 'Required.';
        }
        if (!this.$v.maxPrice.alphaNum) {
          return 'Invalid Format.';
        }
      }
      return null;
    },
  },
  async mounted() {
    await this.getAuctionHouses();
    await this.getVehicle({ id: this.item.id });
    this.updateField({
      value: this.item.auctionHouseName,
      field: 'auctionHouseName',
      type: 'auction',
    });
    this.updateField({
      value: this.item.auctionHouseBranchId,
      field: 'auctionHouseBranch',
      type: 'auction',
    });
  },
  methods: {
    ...mapActions({
      updateField: 'vehicles/updateField',
      createForecourtVehicle: 'vehicles/createForecourtVehicle',
      getAuctionHouses: 'auctionHouses/getAuctionHouses',
      getVehicle: 'vehicles/getVehicle',
      updateVehicle: 'vehicles/updateVehicle',
    }),
    closeModal() {
      this.$modal.hide('view-purchase');
      this.closeDropdown();
    },
    async save() {
      this.$modal.hide('view-purchase');
      await this.updateVehicle();
      this.closeDropdown();
    },
  },
};
</script>

<style></style>

<template>
  <div class="bg-white p-10 relative h-full">
    <div class="absolute right-6 top-9">
      <img :src="close" class="cursor-pointer" @click="closeModal" />
    </div>
    <div v-if="transport" class="text-center">
      <h1>Load Details</h1>
      <p class="pt-5 text-l w-3/5 text-center mx-auto">
        {{ subtitle }}
      </p>
      <div class="flex justify-center mt-10 overflow-y-auto">
        <table
          class="text-left border-separate"
          style="border-spacing: 32px 16px;"
        >
          <thead class="mb-2 text-mdl">
            <th class="text-blue font-medium">
              Registration
            </th>
            <th class="text-blue font-medium">
              Make & Model
            </th>
            <th class="text-blue font-medium">Company</th>
            <th class="text-blue font-medium">Duty Paid/Exempted</th>
          </thead>
          <tbody>
            <tr
              v-for="(item, idx) in transport.items"
              :key="idx"
              class="text-l"
            >
              <td class="pr-10 max-w-200 truncate">
                {{ item.registrationNumber }}
              </td>
              <td class="pr-7">
                {{ `${item.make} ${item.model}` }}
              </td>
              <td class="pr-7">
                {{ item.companyName }}
              </td>
              <td
                class="pr-7"
                :class="
                  item.dutyPaymentStatus === 'Unpaid'
                    ? 'text-red'
                    : 'text-green'
                "
              >
                {{ item.dutyPaymentStatus }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable operator-linebreak */

import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import close from '@/assets/icons/close.svg';

export default {
  name: 'LoadDetails',
  props: ['item', 'closeModal'],
  data: () => ({
    close,
  }),
  computed: {
    ...mapState({
      transport: (state) => state.transports.transport,
    }),
    subtitle() {
      const pbn = this.transport.pbn || 'Awaiting PBN';
      return `${pbn} | ${
        this.transport.transporter
      } | Transporting ${this.formatDate(this.transport.transportingDate)}`;
    },
  },
  async mounted() {
    await this.getTransport(this.item.id);
  },
  methods: {
    ...mapActions({
      getTransport: 'transports/getTransport',
    }),
    formatDate(date) {
      return moment(date).format('MMMM Do');
    },
  },
};
</script>

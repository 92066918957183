<template>
  <div class="text-center">
    <h1 class="mb-4 text-black">{{ title }}</h1>
    <h2 class="text-black text-mdl text-medium">{{ subtitle }}</h2>
  </div>
</template>
<script>
export default {
  name: 'Title',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
};
</script>

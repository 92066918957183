<template>
  <div>
    <div class="w-full flex">
      <nav class="flex items-center justify-center w-310">
        <div class="-mb-px flex justify-between mx-auto flex-grow">
          <a
            v-for="tab in tabs"
            :key="tab.name"
            class="
            cursor-pointer
            no-underline
            border-transparent
            tracking-wide
            font-medium font-primary
            text-mdh
            py-2.5
            flex
            items-center
            border-b-3
            w-full
            justify-center
          "
            :class="{
              'text-blue border-blue bg-ultra-light': tab.isActive,
              'border-grey-dark text-grey-dark bg-white':
                tab.isActive === false
            }"
            @click="selectTab(tab)"
          >
            {{ tab.name }}
          </a>
        </div>
      </nav>
      <slot name="actions"></slot>
    </div>

    <div class="tabs-details mt-10">
      <slot name="tabs"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: ['changeTab', 'tabs'],
  methods: {
    selectTab(selectedTab) {
      this.changeTab(selectedTab.id);
      this.tabs.forEach((tab) => {
        // eslint-disable-next-line
        tab.isActive = parseInt(tab.id, 10) === parseInt(selectedTab.id, 10);
      });
    },
  },
};
</script>

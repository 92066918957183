<template>
  <div v-click-outside="closeMenu" class="relative" >
    <Button title="Filter" type="white" :icon="filterIcon" @click.native="toggleMenu" />
    <FilterMenu
      :type="type"
      class="z-10"
      :menuVisibility="menuVisibility"
      :closeDropdown="closeMenu"
      :filterFn="filterFn"
      :filters="filters"
    />
  </div>
</template>

<script>
import Button from '@/components/atoms/Button.vue';
import filterIcon from '@/assets/icons/filter.svg';
import FilterMenu from './FilterMenu.vue';

export default {
  name: 'FilterButton',
  components: {
    Button,
    FilterMenu,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    filterFn: {
      type: Function,
      default: () => null,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    filterIcon,
    menuVisibility: false,
  }),
  methods: {
    toggleMenu() {
      this.menuVisibility = !this.menuVisibility;
    },
    closeMenu() {
      this.menuVisibility = false;
    },
  },
};
</script>

<template>
  <div>
    <DocumentsForm
      :id="id"
      :item="item"
      :submitFn="submitFn"
      :files="newDocuments"
      :changeDocumentType="changeDocumentType"
      :addDocument="addDocument"
      :closeModal="closeModal"
    />
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
import { mapActions, mapState } from 'vuex';
import DocumentsForm from '@/components/molecules/Home/Forms/DocumentsForm.vue';

export default {
  name: 'UploadDocumentModal',
  components: { DocumentsForm },
  computed: {
    ...mapState({
      id: (state) => state.statusActions.objectId,
      newVehicleDocuments: (state) => state.vehicles.newVehicleDocuments,
      item: (state) => state.vehicles.currentVehicle,
    }),
    newDocuments() {
      return (
        this.newVehicleDocuments[this.item.id] &&
        this.newVehicleDocuments[this.item.id].documents
      );
    },
  },
  async mounted() {
    await this.getVehicle({ id: this.id });
  },
  methods: {
    ...mapActions({
      triggerStatusUpdate: 'statusActions/triggerStatusUpdate',
      getVehicle: 'vehicles/getVehicle',
      changeDocumentType: 'vehicles/changeDocumentType',
      addDocument: 'vehicles/addDocument',
      uploadDocument: 'vehicles/uploadDocument',
    }),
    submitFn() {
      const calls = [];
      if (this.newDocuments.length !== 0) {
        this.newDocuments.forEach((document) => {
          calls.push(
            this.uploadDocument({
              vehicle: this.item,
              document: document.file,
              type: document.type,
            }),
          );
        });
        Promise.all(calls).then((rsp) => {
          const found = rsp.find((el) => el.status === 200);
          if (found) {
            this.triggerStatusUpdate();
            this.closeModal();
          }
        });
      }
    },
    closeModal() {
      this.$modal.hide('action-modal');
    },
  },
};
</script>

<template>
  <div class="bg-white">
    <div class="absolute right-6 top-9">
      <img :src="close" class="cursor-pointer" @click="closeModal" />
    </div>
    <div class="flex flex-col items-center justify-center">
      <p class="my-5 text-xxl text-black font-bold text-center mt-16">
        Duty Due?
      </p>
      <p class="font-medium text-l text-black text-center">
        Has this vehicle been paid/exempted.
      </p>
      <p v-if="currentVehicle" class="mt-9 text-l text-black font-medium">
        {{ currentVehicle.registrationNumber }} | {{ currentVehicle.make }}
        {{ currentVehicle.model }}
      </p>
      <Input
        v-if="dutyPaymentNeeded"
        id="dutyDueAmount"
        v-model="dutyDueAmount"
        placeholder="Duty Due Amount"
        title="Duty Due Amount"
        class="mt-12"
        @changed="(val) => (dutyDueAmount = val.value)"
      />
      <Check
        label="Duty payment needed"
        class="mt-20"
        :active="dutyPaymentNeeded"
        @click.native="toggleDutyPaymentNeeded"
      />
      <Button
        title="Submit"
        class="mt-12 mb-16"
        type="primary"
        :disabled="disableSubmit"
        @click.native="save"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable comma-dangle */
import { mapActions, mapState } from 'vuex';
import close from '@/assets/icons/close.svg';
import Button from '../../atoms/Button.vue';
import Input from '../../atoms/Input.vue';
import Check from '@/components/atoms/Check.vue';

export default {
  name: 'DutyDueModal',
  components: { Check, Button, Input },
  data: () => ({
    close,
    dutyPaymentNeeded: false,
    currentVehicle: null,
    dutyDueAmount: '',
    disableSubmit: false,
  }),
  computed: {
    ...mapState({
      id: (state) => state.statusActions.objectId,
      items: (state) => state.vehicles.items,
    }),
  },
  watch: {
    dutyDueAmount(val) {
      if (val !== '') {
        this.disableSubmit = false;
      } else {
        this.disableSubmit = true;
      }
    }
  },
  mounted() {
    this.currentVehicle = this.items.find(
      (item) => item.id.toString() === this.id.toString()
    );
  },
  methods: {
    ...mapActions({
      setTargetStatus: 'statusActions/setTargetStatus',
      triggerStatusUpdate: 'statusActions/triggerStatusUpdate',
      setDutyPaymentNeeded: 'vehicles/setDutyPaymentNeeded',
    }),
    closeModal() {
      this.$modal.hide('action-modal');
    },
    toggleDutyPaymentNeeded() {
      this.dutyPaymentNeeded = !this.dutyPaymentNeeded;
      this.disableSubmit = this.dutyPaymentNeeded;
    },
    save() {
      Promise.resolve(
        this.setDutyPaymentNeeded({
          id: this.id,
          dutyPaymentNeeded: this.dutyPaymentNeeded,
          dutyDueAmount: this.dutyDueAmount,
        })
      ).then(() => {
        if (!this.dutyPaymentNeeded) {
          this.setTargetStatus({ status: 'READY_FOR_TRANSPORT' });
        }
        this.triggerStatusUpdate();
        this.closeModal();
      });
    },
  },
};
</script>

<template>
  <UploadInput
    id="proof-document"
    title="Proof Documents"
    multiple
    :files="documents"
    :removeFn="removeFn"
    :downloadFn="downloadItem"
    @changed="(files) => handleFiles(files)"
  >
    <StatusTooltip
      label="Proof Documents"
      description="Proof of Irish VAT within the last 3 months, Proof of Address and incorporation document if relevant."
      openDirection="right"
    />
  </UploadInput>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import info from '@/assets/icons/info.svg';
import UploadInput from '@/components/atoms/UploadInput.vue';
import StatusTooltip from '../../../atoms/StatusTooltip.vue';

export default {
  name: 'DocumentsUploader',
  components: {
    UploadInput,
    StatusTooltip,
  },
  props: {
    handleFiles: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({
    info,
  }),
  computed: {
    ...mapState({
      documents: (state) => state.registration.documents,
    }),
  },
  methods: {
    ...mapActions({
      removeFile: 'registration/removeFile',
      downloadItem: 'registration/downloadItem',
    }),
    removeFn(id) {
      this.removeFile(id);
    },
    downloadFn(id) {
      this.downloadItem(id);
    },
  },
};
</script>

<style></style>

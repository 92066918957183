<template>
  <div class="vertical-progressbar">
    <ul class="progressbar">
      <li
        v-for="(item, index) in steps"
        :key="item.progressBarTitle"
        :test-id="`progress-bar-link-${index}`"
        :class="{'completed': index <= step}"
        @click="index < step ? $emit('navigate', index) : () => {}"
      >
        <span class="item inline-block" :class="{'current': index === step}">{{ item.progressBarTitle }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'VerticalProgressBar',
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    step: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style scoped>
.vertical-progressbar {
  z-index: 1;
  font-size: 14px;
}

.progressbar ul, .progressbar li {
  width: 200px;
  margin: auto;
}

.progressbar li {
  position: relative;
  height: 75px;
  text-align: left;
}

.progressbar li .item {
  padding: 0 10px;
  margin-top: -15px;
}

.progressbar li:before {
  content: "";
  width: 15px;
  height: 15px;
  border: 4px solid #53b044;
  display: inline-block;
  border-radius: 50%;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}

.progressbar .completed:before {
  background: #53b044;
}

.progressbar .completed {
  cursor: pointer;
}

.progressbar li:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 75px;
  background: #53b044;
  top: 0;
  left: 6px;
  z-index: -1;
}

.progressbar li:last-child:after {
  content: none;
}

.progressbar .item {
  position: relative;
  top: -5px;
  vertical-align: sub;
}

.progressbar .item.current {
  font-weight: 700;
}
</style>

import Vue from 'vue';
import { register, uploadProofDocument } from '@/services/api/registration';
import idGenerator from '@/services/utils/idGenerator';

export default {
  namespaced: true,
  state: {
    ahSections: [{ house: null, houseNumber: '', id: 1 }],
    companyName: '',
    companyEircode: '',
    companyAddress: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    countryCode: '+',
    phoneNumber: '',
    registrationId: '',
    isCurrentStepComplete: false,
    documents: [],
  },
  actions: {
    setStepCompletion({ commit }, { stepCompletion }) {
      commit('SET_STEP_COMPLETION', stepCompletion);
    },
    updateValue({ commit }, config) {
      commit('updateValue', config);
    },
    addNewHouse({ commit, state }) {
      const lastHouseId = state.ahSections.length > 0
        && state.ahSections.sort((a, b) => b.id - a.id)[0].id;
      commit('ADD_NEW_HOUSE', lastHouseId + 1);
    },
    setAHvalue({ state, commit }, { val, id, key }) {
      const ahHouse = state.ahSections.find((sections) => sections.id === id);
      const ahIndex = state.ahSections.findIndex(
        (section) => section.id === id,
      );
      ahHouse[key] = val.value;
      commit('SET_HOUSE', { ahHouse, ahIndex });
    },
    removeAh({ commit }, id) {
      commit('REMOVE_AH', id);
    },
    removeFile({ commit }, id) {
      commit('REMOVE_FILE', id);
    },
    downloadItem({ state }, id) {
      const item = state.documents.find((file) => file.id === id);
      const blob = new Blob([item.file], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = item.file.name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    async register({ state }) {
      const auctionHouses = state.ahSections.map((ah) => ({
        auctionHouseAccountNumber: ah.houseNumber,
        auctionHouseId: ah.house?.value,
      }));
      // refator payload
      const payload = {
        auctionHouses,
        companyAddress: state.companyAddress,
        companyEircode: state.companyEircode,
        companyName: state.companyName,
        emailAddress: state.emailAddress,
        firstName: state.firstName,
        countryCode: state.countryCode,
        lastName: state.lastName,
        password: state.password,
        phoneNumber: state.phoneNumber,
      };
      return register(state.registrationId, payload).then((rsp) => {
        const files = Array.from(state.documents);
        const fileCalls = files.map((document) => {
          const formData = new FormData();
          formData.append('proofDocument', document.file);

          return uploadProofDocument(
            state.registrationId,
            rsp.data.id,
            formData,
          );
        });
        return Promise.all(fileCalls);
      });
    },
  },
  mutations: {
    updateValue(state, config) {
      if (config.id === 'documents') {
        const files = Array.from(config.value).map((file) => ({
          file,
          id: idGenerator(),
        }));
        state[config.id].push(...files);
      } else {
        state[config.id] = config.value;
      }
    },
    REMOVE_FILE(state, id) {
      const newFiles = state.documents.filter((file) => file.id !== id);
      state.documents = newFiles;
    },
    SET_STEP_COMPLETION(state, stepCompletion) {
      state.isCurrentStepComplete = stepCompletion;
    },
    REMOVE_AH(state, id) {
      const newHouses = state.ahSections.filter((ah) => ah.id !== id);
      state.ahSections = newHouses;
    },
    ADD_NEW_HOUSE(state, id) {
      state.ahSections.push({ house: null, houseNumber: '', id });
    },
    SET_HOUSE(state, payload) {
      Vue.set(state.ahSections, payload.ahIndex, payload.ahHouse);
    },
  },
};

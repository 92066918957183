<template>
  <div class="flex flex-col space-y-10 mb-10">
    <AnalyticsHeader />
    <AnalyticsStatistics :stats="stats" />
    <AnalyticsCharts />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AnalyticsHeader from '@/components/molecules/Analytics/components/AnalyticsHeader.vue';
import AnalyticsStatistics from '@/components/molecules/Analytics/components/AnalyticsStatistics.vue';
import AnalyticsCharts from '@/components/molecules/Analytics/AnalyticsCharts.vue';

export default {
  name: 'Analytics',
  components: {
    AnalyticsHeader,
    AnalyticsStatistics,
    AnalyticsCharts,
  },
  computed: {
    ...mapState({
      stats: (state) => state.analytics.data,
    }),
  },
  async mounted() {
    await this.getCompanies();
    this.getAnalytics();
  },
  destroyed() {
    this.resetFilters();
  },
  methods: {
    ...mapActions({
      resetFilters: 'analytics/resetFilters',
      getAnalytics: 'analytics/getAnalytics',
      getCompanies: 'analytics/getCompanies',
    }),
  },
};
</script>

<style></style>

<template>
  <div>
    <input type="checkbox" :value="item.vehicle.id" :checked="isChecked(item.vehicle.id)" @change="selectVehicle(item.vehicle)" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CheckboxCell',
  props: ['item'],
  computed: {
    ...mapState({
      selectedForTransport: (state) => state.vehicles.selectedForTransport,
    }),
  },
  methods: {
    isChecked(id) {
      return !!this.selectedForTransport.find((el) => el.id === id);
    },
    ...mapActions({
      selectVehicle: 'vehicles/selectVehicle',
    }),
  },
};
</script>

<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'NoActionModal',
  mounted() {
    this.triggerStatusUpdate();
    this.$modal.hide('action-modal');
  },
  methods: {
    ...mapActions({
      triggerStatusUpdate: 'statusActions/triggerStatusUpdate',
    }),
  },
};
</script>

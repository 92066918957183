<template>
  <div class="bg-white p-10 relative h-full">
    <div class="absolute right-6 top-9">
      <img :src="close" class="cursor-pointer" @click="closeModal" />
    </div>
    <div v-if="transport" class="text-center">
      <h1>Load Documents</h1>
      <p class="pt-5 text-l w-3/5 text-center mx-auto">
        {{ subtitle }}
      </p>
      <div class="flex justify-center mt-5 overflow-y-auto">
        <div
          v-if="documents && documents.length !== 0"
          class="mt-60 flex justify-center overflow-y-scroll max-h-table"
        >
          <table
            class="text-left border-separate"
            style="border-spacing: 0 6px;"
          >
            <thead class="mb-2 text-mdl">
              <th class="text-blue font-medium">
                Registration
              </th>
              <th class="text-blue font-medium">
                Name
              </th>
              <th class="text-blue font-medium">
                Type
              </th>
              <th class="text-blue font-medium">Upload Time</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="item in documents" :key="item.documentId" class="text-l">
                <td class="pr-7">{{ item.registrationNumber }}</td>
                <td class="pr-7">
                  {{ item.documentName }}
                </td>
                <td class="pr-7">
                  {{ item.type }}
                </td>
                <td class="pr-7">
                  {{ item.created }}
                </td>
                <td
                  class="pr-10 text-red-dark cursor-pointer"
                >
                  <a :href="item.url">
                    <img :src="download" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import _flatten from 'lodash/flatten';
import close from '@/assets/icons/close.svg';
import download from '@/assets/icons/download.svg';
import { documentType } from '@/services/utils/formatEnums.js';

export default {
  name: 'LoadDetails',
  props: ['item', 'closeModal'],
  data: () => ({
    close,
    download,
  }),
  computed: {
    ...mapState({
      transport: (state) => state.transports.transport,
    }),
    subtitle() {
      const pbn = this.transport.pbn || 'Awaiting PBN';
      return `${pbn} | ${
        this.transport.transporter
      } | Transporting ${this.formatTitleDate(
        this.transport.transportingDate,
      )}`;
    },
    documents() {
      return this.transport
        && _flatten(this.transport.items.map((vehicle) => [...vehicle.documents.map((document) => ({
          registrationNumber: vehicle.registrationNumber,
          documentName: document.name,
          type: documentType(document.type),
          created: this.formatDate(document.created),
          url: document.url,
          documentId: document.id,
        }))]));
    },
  },
  async mounted() {
    await this.getTransport(this.item.id);
  },
  methods: {
    ...mapActions({
      getTransport: 'transports/getTransport',
    }),
    formatTitleDate(date) {
      return moment(date).format('MMMM Do');
    },
    formatDate(date) {
      return moment(date).format('MMM Do, hh:mm');
    },
  },
};
</script>

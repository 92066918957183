<template>
  <div class="flex flex-col items-center h-full p-16">
    <div v-if='!hideCloseButton' class="absolute right-6 top-9">
      <img :src="close" class="cursor-pointer" @click="closeModal" />
    </div>
    <div class="w-full flex flex-row justify-center relative">
      <h1 class="text-center flex-1">Upload Documents</h1>
    </div>
    <div class="mt-5">
      <p v-if="isCustomer" class="text-mdl">
        {{ `${item.firstName} ${item.lastName} | ${item.companyName}` }}
      </p>
      <p v-else class="text-mdl">
        {{ `${item.registrationNumber} | ${item.make} ${item.model}` }}
      </p>
    </div>
    <div>
      <DragAndDrop
        :id="id"
        noHeader
        :closeModal="closeModal"
        :submitFn="submitFn"
        :multiple="true"
        :files="files"
        :submitDisabled="!isCustomer && submitDisabled()"
        extraBtn
        @changed="(files) => onChange(files)"
      >
        <table
          class="w-full border-separate"
          style="border-spacing: 32px 24px;"
        >
          <thead>
            <tr>
              <th class="text-left text-blue font-medium">
                Name
              </th>
              <th
                v-if="!isCustomer"
                class="px-4 text-left text-blue font-medium"
              >
                Type
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="file in files" :key="file.id">
              <td
                class="px-4 border-4 border-blue border-dashed min-w-250 rounded"
              >
                <p class="flex">
                  <img :src="document" class="pr-5" height="12" />
                  {{ file.name }}
                </p>
              </td>
              <td v-if="!isCustomer" class="px-4 min-w-300">
                <Select
                  :id="`document-${file.id}`"
                  :hasMargin="false"
                  :hasPadding="false"
                  class="w-full"
                  :options="documentTypes"
                  placeholder="Select document type"
                  :value="file.type"
                  @changed="
                    (val) =>
                      changeDocumentType({
                        documentId: file.id,
                        vehicleId: item.id,
                        documentType: val.value
                      })
                  "
                />
              </td>
              <td>
                <p
                  class="text-l text-red-dark cursor-pointer"
                  @click.stop="removeDocument(item.id, file.id)"
                >
                  Remove
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </DragAndDrop>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DragAndDrop from '@/components/molecules/Uploader/DragAndDrop.vue';
import Select from '@/components/atoms/Select.vue';
import document from '@/assets/icons/document.svg';
import close from '@/assets/icons/close.svg';
import { documentTypes } from '@/services/utils/enums.js';

export default {
  name: 'DocumentsForm',
  components: {
    DragAndDrop,
    Select,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {},
    },
    files: {
      type: Array,
      default: () => [],
    },
    closeModal: {
      type: Function,
      default: () => null,
    },
    submitFn: {
      type: Function,
      default: () => null,
    },
    changeDocumentType: {
      type: Function,
      default: () => null,
    },
    removeDocument: {
      type: Function,
      default: () => null,
    },
    addDocument: {
      type: Function,
      default: () => null,
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    close,
    document,
    documentTypes,
  }),
  methods: {
    ...mapActions({ deleteDocument: 'vehicles/deleteDocument' }),
    submitDisabled() {
      return !!this.files.find((el) => el.type === '');
    },
    onChange(files) {
      this.addDocument({ files, vehicleId: this.item.id });
    },
    closeThis() {
      this.$modal.hide('documents-form');
    },
  },
};
</script>

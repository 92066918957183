<template>
  <div class="flex flex-col items-center h-full p-16">
    <div class="w-full flex flex-row justify-center relative">
      <h1 class="text-center flex-1">New Transport Load</h1>
    </div>
    <p class="pt-5 text-l w-3/4 text-center">
      An automatic email will be sent to the Customs Agent.
    </p>
    <div class="w-300 mx-auto mt-10 space-y-7">
      <DatePicker
        id="transportingDate"
        :value="transportingDate"
        placeholder="Transporting Date"
        :error="dateError"
        :validationTrigger="$v.transportingDate.$touch"
        @changed="(val) => (transportingDate = val)"
      />
      <Input
        id="destination"
        placeholder="Destination"
        :value="destination"
        :error="destinationError"
        :validationTrigger="$v.destination.$touch"
        @changed="(val) => (destination = val)"
      />
      <Input
        id="transporter"
        placeholder="Transporter"
        :value="transporter"
        :error="transporterError"
        :validationTrigger="$v.transporter.$touch"
        @changed="(val) => (transporter = val)"
      />
    </div>
    <div class="flex flex-row space-x-5 mt-10 mb-20 items-center">
      <p class="text-l text-blue cursor-pointer" @click="backFn">
        Go back
      </p>
      <Button
        title="Submit Request"
        class="mt-auto"
        type="primary"
        :isDisabled="disableSubmit"
        @click.native="save"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import Input from '@/components/atoms/Input.vue';
import Button from '@/components/atoms/Button.vue';
import DatePicker from '@/components/atoms/DatePicker.vue';
import eventBus from '@/services/utils/eventBus.js';

export default {
  name: 'LoadForm',
  components: {
    Input,
    Button,
    DatePicker,
  },
  props: {
    backFn: {
      type: Function,
      default: () => null,
    },
    closeFn: {
      type: Function,
      default: () => null,
    },
  },
  validations: {
    transportingDate: { required },
    destination: { required },
    transporter: { required },
  },
  computed: {
    ...mapState({
      data: (state) => state.loads.data,
    }),
    transportingDate: {
      get() {
        return this.data.transportingDate;
      },
      set(val) {
        this.updateField({
          value: val,
          field: 'transportingDate',
        });
      },
    },
    dateError() {
      if (this.$v.transportingDate.$dirty) {
        if (!this.$v.transportingDate.required) {
          return 'Required.';
        }
      }
      return null;
    },
    destination: {
      get() {
        return this.data.destination;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
        });
      },
    },
    destinationError() {
      if (this.$v.destination.$invalid && this.$v.destination.$dirty) {
        return 'Required.';
      }
      return null;
    },
    transporter: {
      get() {
        return this.data.transporter;
      },
      set(val) {
        this.updateField({
          value: val.value,
          field: val.id,
        });
      },
    },
    transporterError() {
      if (this.$v.transporter.$invalid && this.$v.transporter.$dirty) {
        return 'Required.';
      }
      return null;
    },
    disableSubmit() {
      return this.$v.$invalid;
    },
  },
  methods: {
    ...mapActions({
      submit: 'loads/submit',
      updateField: 'loads/updateField',
    }),
    async save() {
      await this.submit();
      eventBus.$emit('load-submitted');
      this.closeFn();
    },
  },
};
</script>

<template>
  <div class="flex items-center">
    <img :src="active ? checked : checkedEmpty" class="mr-2" />
    <p class="text-black text-l">{{ label }}</p>
  </div>
</template>

<script>
import checked from '@/assets/icons/checked.svg';
import checkedEmpty from '@/assets/icons/checked-empty.svg';

export default {
  name: 'Check',
  props: {
    label: { type: String, default: '' },
    active: { type: Boolean, default: false },
  },
  data: () => ({
    checked,
    checkedEmpty,
  }),
};
</script>

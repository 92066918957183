import { inviteStaff } from '@/services/api/staff';

export default {
  namespaced: true,
  state: {
    data: {
      firstName: '',
      lastName: '',
      email: '',
    },
  },
  actions: {
    inviteStaff({ state }) {
      const payload = {
        emailAddress: state.data.email,
        firstName: state.data.firstName,
        lastName: state.data.lastName,
        type: 'APP_ADMIN',
      };
      return inviteStaff(payload);
    },
    updateField({ commit }, data) {
      commit('UPDATE_FIELD', data);
    },
  },
  mutations: {
    UPDATE_FIELD(state, data) {
      state.data[data.id] = data.value;
    },
  },
};

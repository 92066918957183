<template>
  <div :key="menuItem.id">
    <div
      v-if="menuVisibility"
      ref="item-menu"
      class="absolute top-0 -left-32 shadow z-10"
    >
      <div class="flex flex-col" :class="hideMenu ? 'hidden' : ''">
        <button
          v-for="option in options"
          :key="option.text"
          @click.once="handleSelection(option)"
        >
          <p class="text-left p-2.5 bg-white hover:bg-grey-hover-light">
            {{ option.text }}
          </p>
        </button>
      </div>
      <Modal name="duty-payment" width="80%" height="90%">
        <DutyPayment :id="menuItem.id" :closeDropdown="closeDropdown" />
      </Modal>
      <Modal name="view-purchase" width="80%" height="auto">
        <EditPurchaseForm :item="menuItem" :closeDropdown="closeDropdown" />
      </Modal>
      <Modal name="remove-vehicle" width="80%" height="auto">
        <RemoveVehicle :id="menuItem.id" :closeDropdown="closeDropdown" />
      </Modal>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-expressions */
import { mapGetters, mapActions } from 'vuex';
import StatusHistory from './StatusHistory.vue';
import ViewDocuments from './ViewDocuments.vue';
import DutyPayment from './DutyPayment.vue';
import SendToTransporter from './SendToTransporter.vue';
import LoadDetails from './LoadDetails.vue';
import LoadDocuments from './LoadDocuments.vue';
import EditPurchaseForm from '@/components/molecules/Home/Forms/EditPurchaseForm.vue';
import RemoveVehicle from '@/components/molecules/ActionModals/RemoveVehicle.vue';
import close from '@/assets/icons/close.svg';

export default {
  name: 'Menu',
  components: { DutyPayment, EditPurchaseForm, RemoveVehicle },
  props: ['menuVisibility', 'menuItem', 'closeDropdown'],
  data: () => ({
    options: [],
    close,
    hideMenu: false,
  }),
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
    }),
  },
  watch: {
    menuVisibility(x, y) {
      if (x !== y) this.hideMenu = false;
    },
  },
  created() {
    if (this.menuItem.isTransport) {
      this.addBaseOptionsForTransport();
      this.addExtraOptionsForTransport();
    } else {
      this.addBaseOptionsForVehicle();
      this.addExtraOptionsForVehicle();
    }
  },
  beforeUpdate() {
    this.menuItem.isTransport
      ? this.addExtraOptionsForTransport()
      : this.addExtraOptionsForVehicle();
  },
  methods: {
    ...mapActions({
      resetTransportActions: 'transports/resetTransportActions',
    }),
    handleSelection(item) {
      if (item.action) {
        item.action();
        this.hide();
      }
    },
    hide() {
      this.hideMenu = true;
    },
    beforeModalClose() {
      this.closeDropdown();
    },
    addBaseOptionsForVehicle() {
      this.options.push(
        {
          text: 'Status History',
          action: () => this.openStatusHistoryModal(this.menuItem),
        },
        {
          text: 'Documents',
          action: () => this.openDocumentsModal(this.menuItem.id),
        },
      );
      if (this.isAdmin) {
        this.options.unshift({
          text: 'View / Edit',
          action: () => this.$modal.show('view-purchase'),
        });
        if (this.menuItem.status === 'NO_PURCHASE_AGREED') {
          this.options.push({
            text: 'Remove',
            action: () => this.$modal.show('remove-vehicle'),
          });
        }
      }
    },
    addExtraOptionsForVehicle() {
      if (
        !this.options.some((el) => el.text === 'Duty Payment')
        && this.menuItem.status === 'DUTY_DUE'
        && !this.isAdmin
      ) {
        this.options.unshift({
          text: 'Duty Payment',
          action: () => this.$modal.show('duty-payment'),
        });
      } else {
        this.options.filter((el) => el.text !== 'Duty Payment');
      }
    },
    addBaseOptionsForTransport() {
      this.options.push(
        {
          text: 'Load Details',
          action: () => this.openLoadDetailsModal(this.menuItem),
        },
        {
          text: 'Status History',
          action: () => this.openStatusHistoryModal(this.menuItem),
        },
        {
          text: 'Documents',
          action: () => this.openLoadDocumentsModal(this.menuItem),
        },
      );
    },
    addExtraOptionsForTransport() {
      if (
        !this.options.some((el) => el.text === 'Send to Transporter')
        && this.menuItem.status === 'PBN_RECEIVED'
      ) {
        this.options.unshift({
          text: 'Send to Transporter',
          action: () => this.openTransportModal(this.menuItem),
        });
      } else {
        this.options.filter((el) => el.text !== 'Send to Transporter');
      }
    },
    openDocumentsModal(id) {
      this.$modal.show(
        {
          name: 'ViewDocumentsModal',
          template: '<ViewDocuments :id="id" :closeDropdown="closeModal" />',
          components: {
            ViewDocuments,
          },
          props: ['id'],
          methods: {
            closeModal() {
              this.$modal.hide('ViewDocumentsModal');
            },
          },
        },
        {
          id,
        },
        {
          name: 'ViewDocumentsModal',
          width: '80%',
          height: '90%',
        },
        { 'before-close': this.beforeModalClose },
      );
    },
    openStatusHistoryModal(item) {
      this.$modal.show(
        {
          name: 'StatusHistoryModal',
          template:
            '<StatusHistory :item="item" :closeDropdown="closeModal" />',
          components: {
            StatusHistory,
          },
          props: ['item'],
          methods: {
            closeModal() {
              this.$modal.hide('StatusHistoryModal');
            },
          },
        },
        {
          item,
        },
        {
          name: 'StatusHistoryModal',
          width: '90%',
          height: 'auto',
        },
        { 'before-close': this.beforeModalClose },
      );
    },
    openTransportModal(transport) {
      this.$modal.show(
        {
          name: 'SendToTransporterModal',
          template:
            '<SendToTransporter :item="item" :closeModal="closeModal" />',
          components: {
            SendToTransporter,
          },
          props: ['item'],
          methods: {
            closeModal() {
              this.$modal.hide(this.$parent.name);
            },
          },
        },
        {
          item: transport,
        },
        {
          scrollable: true,
          width: '80%',
          height: 'auto',
        },
        {
          'before-close': () => {
            this.beforeModalClose();
            this.resetTransportActions();
          },
        },
      );
    },
    openLoadDetailsModal(transport) {
      this.$modal.show(
        {
          name: 'LoadDetailsModal',
          template: '<LoadDetails :item="item" :closeModal="closeModal" />',
          components: {
            LoadDetails,
          },
          props: ['item'],
          methods: {
            closeModal() {
              this.$modal.hide(this.$parent.name);
            },
          },
        },
        {
          item: transport,
        },
        {
          scrollable: true,
          width: '80%',
          height: 'auto',
        },
        {
          'before-close': () => {
            this.beforeModalClose();
            this.resetTransportActions();
          },
        },
      );
    },
    openLoadDocumentsModal(transport) {
      this.$modal.show(
        {
          name: 'LoadDocumentsModal',
          template: '<LoadDocuments :item="item" :closeModal="closeModal" />',
          components: {
            LoadDocuments,
          },
          props: ['item'],
          methods: {
            closeModal() {
              this.$modal.hide(this.$parent.name);
            },
          },
        },
        {
          item: transport,
        },
        {
          scrollable: true,
          width: '80%',
          height: 'auto',
        },
        {
          'before-close': () => {
            this.beforeModalClose();
            this.resetTransportActions();
          },
        },
      );
    },
  },
};
</script>

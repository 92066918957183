<template>
  <div
    class="flex items-center text-l font-primary text-grey-dark-5 font-light"
    :class="[item.class]"
  >
    <div v-click-outside="closeMenu" class="relative">
      <img :src="dots" class="cursor-pointer" @click="openMenu" />
      <CustomerMenu
        v-if="item.type === 'CUSTOMER_MENU'"
        class="z-10"
        :menuVisibility="menuVisibility"
        :menuItem="item.menuItem"
        :closeDropdown="closeMenu"
      />
      <Menu
        v-else
        class="z-10"
        :menuVisibility="menuVisibility"
        :menuItem="item.menuItem"
        :closeDropdown="closeMenu"
      />
    </div>
    <p v-if="item.text" class="w-full">{{ item.text }}</p>
  </div>
</template>

<script>
import Menu from '../Menu.vue';
import CustomerMenu from '../CustomerMenu.vue';
import dots from '@/assets/icons/three-vertical-dots.svg';

export default {
  name: 'MenuCell',
  components: { Menu, CustomerMenu },
  props: ['item'],
  data: () => ({ menuVisibility: false, dots }),
  methods: {
    openMenu() {
      this.menuVisibility = true;
    },
    closeMenu() {
      this.menuVisibility = false;
    },
  },
};
</script>

export default {
  authority: '/auth/realms/portnetic',
  client_id: 'portnetic-ui',
  redirect_uri: `${window.location.origin}/oidc-callback`,
  silent_redirect_uri: `${window.location.origin}/silent-renew-oidc.html`,
  response_type: 'code',
  scope: 'openid profile',
  post_logout_redirect_uri: window.location.origin,
  revokeAccessTokenOnSignout: true,
  monitorSession: false,
  automaticSilentRenew: true,
};

<template>
  <div>
    <div class="flex flex-row space-x-4 flex-1 items-center">
      <div class="flex-1">
        <SearchInput
          id="search"
          :value="search"
          placeholder="Search"
          @changed="(el) => (search = el)"
        />
      </div>
      <div>
        <Button
          title="Invite Link"
          type="primary"
          :icon="plusWhite"
          @click.native="openUserModal"
        />
      </div>
    </div>
    <Table :rows="rows" :headers="headers" :emptyState="emptyState"></Table>
    <div
      v-if="rows.length !== 0"
      class="flex flex-row space-x-10 my-5 justify-center"
    >
      <p
        :class="{
          'text-blue cursor-pointer': !disablePrev,
          'text-grey cursor-not-allowed': disablePrev
        }"
        class="text-mdl"
        @click="!disablePrev && fetchPrev()"
      >
        Previous
      </p>
      <p
        title="Next"
        :class="{
          'text-blue cursor-pointer': !disableNext,
          'text-grey cursor-not-allowed': disableNext
        }"
        class="text-mdl"
        @click="!disableNext && fetchNext()"
      >
        Next
      </p>
    </div>
    <Modal name="new-customer-modal" height="55%" width="80%" class="relative">
      <div class="absolute right-6 top-9">
        <img :src="close" class="cursor-pointer" @click="closeCustomerModal" />
      </div>
      <NewCustomerForm :closeFn="closeCustomerModal" />
    </Modal>
    <Modal name="new-user-modal" height="55%" width="80%" class="relative">
      <div class="absolute right-6 top-9">
        <img :src="close" class="cursor-pointer" @click="closeUserModal" />
      </div>
      <NewUserModal :closeFn="closeUserModal" :goToCustomer="goToCustomer" />
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Button from '@/components/atoms/Button.vue';
import plusWhite from '@/assets/icons/plus_white.svg';
import close from '@/assets/icons/close.svg';
import NewCustomerForm from '@/components/molecules/Customers/forms/NewCustomerForm.vue';
import NewUserModal from '@/components/molecules/Customers/NewUserModal.vue';
import Table from '@/components/molecules/Table';
import SearchInput from '@/components/atoms/SearchInput.vue';

export default {
  name: 'Customers',
  components: {
    Button,
    NewCustomerForm,
    Table,
    SearchInput,
    NewUserModal,
  },
  data: () => ({
    plusWhite,
    close,
    emptyState: [
      'Your purchases dashboard is empty.',
      'When a customer adds a purchase it will appear here.',
    ],
  }),
  computed: {
    ...mapGetters({
      rows: 'customers/getRows',
      headers: 'customers/getHeaders',
    }),
    ...mapState({
      pagination: (state) => state.customers.data.pagination,
      searchField: (state) => state.customers.search,
    }),
    disablePrev() {
      return this.pagination.totalPages === 0 || this.pagination.pageNr === 0;
    },
    disableNext() {
      return (
        this.pagination.totalPages === 0
        || this.pagination.pageNr === this.pagination.totalPages - 1
      );
    },
    search: {
      get() {
        return this.searchField;
      },
      set(val) {
        this.searchCustomers({ search: val.value });
      },
    },
  },
  async mounted() {
    await this.getCustomers({ next: false, prev: false });
  },
  methods: {
    ...mapActions({
      getCustomers: 'customers/getCustomers',
      searchCustomers: 'customers/searchCustomers',
    }),
    openCustomerModal() {
      this.$modal.show('new-customer-modal');
    },
    closeCustomerModal() {
      this.$modal.hide('new-customer-modal');
    },
    openUserModal() {
      this.$modal.show('new-user-modal');
    },
    closeUserModal() {
      this.$modal.hide('new-user-modal');
    },
    goToCustomer() {
      this.closeUserModal();
      this.openCustomerModal();
    },
    async fetchNext() {
      await this.getCustomers({ next: true, prev: false });
    },
    async fetchPrev() {
      await this.getCustomers({ next: false, prev: true });
    },
  },
};
</script>

<template>
  <div>
    <div class="flex flex-row items-center justify-center w-300 mx-auto">
      <Input
        id="password"
        title="Password"
        type="password"
        class="flex-1 w-1/3"
        :value="password"
        :error="passwordError"
        :validationTrigger="$v.password.$touch"
        @changed="(value) => (password = value)"
      />
      <v-popover
        trigger="hover"
        placement="right-start"
        class="-mb-1.5 w-40"
        offset="10"
      >
        <img :src="info" />
        <div slot="popover">
          <div class="bg-white pt-3 pl-50 pr-8 w-300 min-h-120 shadow">
            <div class="flex mb-1">
              <ColoredInfoIcon iconColor="#000000" class="absolute left-15" />
              <p class="text-md font-medium text-black">
                Password Policy
              </p>
            </div>
            <p class="text-sm text-black">
              The password needs to have minimum 8 characters containing 1 upper
              case, 1 lower case, 1 special character and 1 number
            </p>
            <div class="w-full h-1.5 mt-2 absolute bottom-0 left-0 bg-black" />
          </div>
        </div>
      </v-popover>
    </div>
    <div class="flex flex-row items-center w-300 mx-auto">
      <Input
        id="confirmPassword"
        title="Confirm password"
        class="w-260"
        type="password"
        :value="confirmPassword"
        :error="confirmPasswordError"
        :validationTrigger="$v.confirmPassword.$touch"
        @changed="(value) => (confirmPassword = value)"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { required, sameAs } from 'vuelidate/lib/validators';
import Input from '@/components/atoms/Input.vue';
import { passwordValidator } from '@/services/utils/regexUtils.js';
import info from '@/assets/icons/info.svg';
import ColoredInfoIcon from '@/assets/icons/colored-info.vue';

export default {
  name: 'PersonalDetails',
  components: {
    Input,
    ColoredInfoIcon,
  },
  props: {
    getValue: {
      type: Function,
      default: () => {},
    },
    setValue: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    info,
  }),
  validations: {
    password: { required, passwordValidator },
    confirmPassword: { required, sameAsPassword: sameAs('password') },
  },
  computed: {
    password: {
      get() {
        return this.getValue('password');
      },
      set(value) {
        this.setValue(value);
      },
    },
    passwordError() {
      if (this.$v.password.$dirty) {
        if (!this.$v.password.required) {
          return 'Required.';
        }
        if (!this.$v.password.passwordValidator) {
          return 'Invalid format.';
        }
      }
      return null;
    },
    confirmPassword: {
      get() {
        return this.getValue('confirmPassword');
      },
      set(value) {
        this.setValue(value);
      },
    },
    confirmPasswordError() {
      if (this.$v.confirmPassword.$dirty) {
        if (!this.$v.confirmPassword.required) {
          return 'Required.';
        }
        if (!this.$v.confirmPassword.sameAsPassword) {
          return 'Passwords must be identical.';
        }
      }
      return null;
    },
    invalidValidation() {
      return this.$v.$invalid;
    },
  },
  watch: {
    invalidValidation(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setStepCompletion({ stepCompletion: !this.invalidValidation });
      }
    },
  },
  methods: {
    ...mapActions({ setStepCompletion: 'registration/setStepCompletion' }),
    touchValidation() {
      this.$v.$touch();
      if (this.invalidValidation) {
        this.setStepCompletion({ stepCompletion: !this.invalidValidation });
      }
    },
  },
};
</script>

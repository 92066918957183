<template>
  <div>
    <Table
      :rows="rows"
      :headers="headers"
      :isTransportEdit="isTransportEdit"
      :emptyState="emptyState"
    ></Table>
    <div
      v-if="rows.length !== 0"
      class="flex flex-row space-x-10 my-5 justify-center"
    >
      <p
        :class="{
          'text-blue cursor-pointer': !disablePrev,
          'text-grey cursor-not-allowed': disablePrev
        }"
        class="text-mdl"
        @click="!disablePrev && fetchPrev()"
      >
        Previous
      </p>
      <p
        :class="{
          'text-blue cursor-pointer': !disableNext,
          'text-grey cursor-not-allowed': disableNext
        }"
        class="text-mdl"
        @click="!disableNext && fetchNext()"
      >
        Next
      </p>
    </div>
    <div
      v-if="isTransportEdit && rows.length !== 0"
      class="flex-col flex items-center"
    >
      <p class="text-mdl text-blue my-5">
        Please select vehicles from above to add to a new transport load.
      </p>
      <div class="flex flex-row items-center justify-center space-x-5">
        <p class="text-mdl text-red cursor-pointer" @click="clearTransportEdit">
          Cancel
        </p>
        <Button
          type="primary"
          title="Submit Load"
          :disabled="selectedForTransport.length === 0"
          @click.native="openTransport"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Table from '@/components/molecules/Table';
import Button from '@/components/atoms/Button';

export default {
  name: 'VehiclesTab',
  components: {
    Table,
    Button,
  },
  props: {
    openTransport: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({
    headers: [],
    emptyState: [
      'Your purchases dashboard is empty.',
      'When a customer adds a purchase it will appear here.',
    ],
  }),
  computed: {
    ...mapState({
      pagination: (state) => state.vehicles.pagination,
      isTransportEdit: (state) => state.vehicles.isTransportEdit,
      selectedForTransport: (state) => state.vehicles.selectedForTransport,
      rows: (state) => state.vehicles.rows,
    }),
    ...mapGetters({
      getHeaders: 'vehicles/getHeaders',
    }),
    disablePrev() {
      return this.pagination.totalPages === 0 || this.pagination.pageNr === 0;
    },
    disableNext() {
      return (
        this.pagination.totalPages === 0
        || this.pagination.pageNr === this.pagination.totalPages - 1
      );
    },
  },
  watch: {
    isTransportEdit(val) {
      if (val) {
        this.headers.unshift({
          text: '',
          class: 'invisible',
          id: 'checkbox-col',
        });
      } else {
        const hasHeader = this.headers.find((el) => el.id === 'checkbox-col');
        if (hasHeader) {
          this.headers.shift();
        }
      }
      if (this.isTransportEdit) this.emptyState = ['There is no vehicle currently in Customs Clearance.'];
    },
  },
  async mounted() {
    await this.getVehicles({ next: false, prev: false });
    this.headers = this.getHeaders(false, true);
  },
  methods: {
    ...mapActions({
      getVehicles: 'vehicles/getVehicles',
      resetVehiclesState: 'vehicles/resetVehiclesState',
      clearTransportEdit: 'vehicles/clearTransportEdit',
    }),
    async fetchNext() {
      await this.getVehicles({ next: true, prev: false });
    },
    async fetchPrev() {
      await this.getVehicles({ next: false, prev: true });
    },
  },
};
</script>

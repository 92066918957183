<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.77778 9.95473L7.03111 11.2081L3.03111 15.2081H6.22222V16.9858H0V10.7636H1.77778V13.9547L5.77778 9.95473ZM7.03111 6.76362L5.77778 8.01695L1.77778 4.01695V7.20806H0V0.98584H6.22222V2.76362H3.03111L7.03111 6.76362ZM10.2222 9.95473L14.2222 13.9547V10.7636H16V16.9858H9.77778V15.2081H12.9689L8.96889 11.2081L10.2222 9.95473ZM8.96889 6.76362L12.9689 2.76362H9.77778V0.98584H16V7.20806H14.2222V4.01695L10.2222 8.01695L8.96889 6.76362Z"
      :fill="color"
    />
  </svg>
</template>
<script>
/* eslint-disable */
export default {
  name: "DashboardIcon",
  props: {
    iconColor: {
      type: String,
      default: "#FF7B00",
    },
  },
  computed: {
    color() {
      switch (this.iconColor) {
        case "grey":
          return "#B2B2B2";
        case "blue":
          return "#047ABD";
        default:
          break;
      }
      return null;
    },
  },
};
</script>

<template>
  <div>
    <div class="absolute right-6 top-9">
      <img :src="close" class="cursor-pointer" @click="closeModal" />
    </div>
    <div class="flex flex-col items-center h-90vh justify-center">
      <p
        class="mb-5 text-xxl text-black font-bold"
        :class="item.purchaseType ? 'mr-4' : 'ml-2'"
      >
        Status History
      </p>
      <div
        v-if="item.purchaseType"
        class="mr-7 flex text-l text-black font-medium"
      >
        <p class="w-250 text-right">{{ item.registrationNumber }}</p>
        <p class="mx-2">|</p>
        <p class="w-250 text-left">{{ item.make }} {{ item.model }}</p>
      </div>
      <div v-else class="flex mr-6 text-l text-black font-medium">
        <p>{{ item.pbn ? `PBN ${item.pbn} | ` : '' }}</p>
        <p class="mx-2">{{ item.transporter }}</p>
        <p>{{ ' | Transporting ' + formatDate(item.transportingDate) }}</p>
      </div>
      <ProgressBar
        :steps="item.statusHistory"
        :type="item.purchaseType || 'LOAD'"
        :class="item.purchaseType && 'ml-14'"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ProgressBar from './ProgressBar.vue';
import close from '@/assets/icons/close.svg';

export default {
  name: 'StatusHistory',
  components: {
    ProgressBar,
  },
  props: ['item', 'closeDropdown'],
  data: () => ({ close }),
  methods: {
    closeModal() {
      this.$modal.hide('status-history');
      this.closeDropdown();
    },
    formatDate(date) {
      return moment(date).format('MMM Do, hh:mm');
    },
  },
};
</script>

<template>
  <div class="bg-white">
    <div class="absolute right-6 top-9">
      <img :src="close" class="cursor-pointer" @click="closeModal" />
    </div>
    <div class="flex flex-col items-center h-90vh justify-center">
      <p class="mr-11 my-5 text-xxl text-black font-bold">
        Confirm Duty Payment
      </p>
      <p class="mr-6 mb-2 text-l text-black font-medium">
        {{ item.registrationNumber }} | {{ item.make }}
        {{ item.model }}
      </p>
      <p class="mb-10 mr-8 font-medium">Duty Due Amount {{item.dutyDueAmount}}</p>
      <UploadInput
        id="proof-document"
        :files="documents"
        :removeFn="removeFile"
        :downloadFn="downloadFile"
        @changed="(files) => updateFiles(files)"
      >
        <StatusTooltip
          label="Duty Proof Documents"
          description="Please attach all relevant documents confirming that the duty for this vehicle has been paid."
          class="mt-1.5"
          openDirection="right"
        />
      </UploadInput>
      <Check
        label="Duty paid"
        class="mt-9"
        :active="isDutyPaid"
        @click.native="toggleDutyPaid"
      />
      <Button
        title="Submit"
        type="primary"
        class="mt-100"
        :disabled="!isDutyPaid"
        @click.native="submit"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import close from '@/assets/icons/close.svg';
import download from '@/assets/icons/download.svg';
import Button from '../../../atoms/Button.vue';
import info from '@/assets/icons/info.svg';
import UploadInput from '@/components/atoms/UploadInput.vue';
import ColoredInfoIcon from '@/assets/icons/colored-info.vue';
import Check from '@/components/atoms/Check.vue';
import idGenerator from '@/services/utils/idGenerator';
import { mapActions, mapState } from 'vuex';
import StatusTooltip from '../../../atoms/StatusTooltip.vue';

export default {
  name: 'DutyPayment',
  components: { Button, UploadInput, Check, ColoredInfoIcon, StatusTooltip },
  props: ['id', 'closeDropdown'],
  data: () => ({
    download,
    close,
    info,
    isDutyPaid: false,
    documents: [],
  }),
  computed: { ...mapState({ item: (state) => state.vehicles.currentVehicle }) },
  async mounted() {
    await this.getVehicle({ id: this.id });
    this.isDutyPaid = !!this.item.customerDutyPaid;
    this.documents = this.item.documents
      .filter((item) => item.type === 'DUTY_PAYMENT_CONFIRMATION')
      .map((item) => ({ file: item, id: item.id, hasURL: true }));
  },
  methods: {
    ...mapActions({
      getVehicle: 'vehicles/getVehicle',
      deleteDocument: 'vehicles/deleteDocument',
      uploadDocument: 'vehicles/uploadDocument',
      setCustomerDutyPayment: 'vehicles/setCustomerDutyPayment',
    }),
    closeModal() {
      this.$modal.hide('view-documents');
      this.closeDropdown();
    },
    toggleDutyPaid() {
      this.isDutyPaid = !this.isDutyPaid;
    },
    async removeFile(id) {
      const item = this.documents.find((file) => file.id === id);
      if (item.hasURL) {
        await this.deleteDocument({ id });
      }
      this.$modal.hide('upload-document-modal');
      this.documents = this.documents.filter((item) => item.id !== id);
    },
    downloadFile(id) {
      const item = this.documents.find((file) => file.id === id);
      if (item.hasURL) {
        const link = document.createElement('a');
        link.href = item.file.url;
        link.download = item.file.name;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        const blob = new Blob([item.file], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = item.file.name;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    },
    updateFiles(files) {
      const documents = Array.from(files).map((file) => ({
        file,
        id: idGenerator(),
      }));
      this.documents.push(...documents);
    },
    async submit() {
      this.documents.forEach(async (item) => {
        if (!item.hasURL)
          await this.uploadDocument({
            vehicle: this.item,
            document: item.file,
            type: 'DUTY_PAYMENT_CONFIRMATION',
          });
      });
      await this.setCustomerDutyPayment({ id: this.item.id });
      this.closeModal();
    },
  },
};
</script>

<template>
  <div>
    <div class="tooltip-box">
      <img :src="info" />
      <div class="tooltip" :class="direction">
        <div class="bg-white pt-3 pl-50 pr-8 w-300 h-100 shadow">
          <div class="flex mb-1">
            <ColoredInfoIcon :iconColor="color" class="absolute left-15" />
            <p class="text-md font-medium" :class="`text-${color}`">
              {{ label }}
            </p>
          </div>
          <p class="description text-sm text-black text-left">
            {{ description }}
          </p>
          <div
            class="w-full h-1.5 absolute bottom-0 left-0"
            :class="`bg-${color}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import info from '@/assets/icons/info.svg';
import ColoredInfoIcon from '@/assets/icons/colored-info.vue';

export default {
  name: 'StatusTooltip',
  components: { ColoredInfoIcon },

  props: {
    openDirection: { type: String, default: 'left' },
    label: { type: String, default: '' },
    description: { type: String, default: '' },
    color: { type: String, default: 'black' },
  },
  data: () => ({ info }),
  computed: {
    direction() {
      if (this.openDirection === 'right') return 'left-0';
      return 'right-0';
    },
  },
};
</script>

<style scoped>
.tooltip-box {
  position: relative;
  display: inline-block;
}

img:hover + .tooltip {
  display: inline-block;
}

.tooltip {
  display: none;
  position: absolute;
  z-index: 1;
}
.description {
  white-space: pre-wrap;
}
</style>

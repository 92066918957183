<template>
  <div class="text-left relative">
    <label
      :for="id"
      class="block tracking-wide text-gray-700 text-xs font-bold"
      >{{ title }}</label
    >
    <img :src="search" width="18" class="search-ico absolute" />
    <input
      :id="id"
      class="appearance-none block w-full bg-grey-200 text-gray-700 border border-grey-search-box rounded py-3 pr-4 pl-10 leading-tight focus:outline-none focus:bg-white"
      type="text"
      :placeholder="placeholder"
      :value="value"
      @keyup="(evt) => changedEvent(evt)"
    />
  </div>
</template>
<script>
import debounce from 'lodash/debounce';
import search from '@/assets/icons/search.svg';

export default {
  name: 'SearchInput',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    search,
  }),
  methods: {
    // eslint-disable-next-line func-names
    changedEvent: debounce(function (evt) {
      return this.$emit('changed', { id: this.id, value: evt.target.value });
    }, 500),
  },
};
</script>
<style lang="css" scoped>
input::placeholder {
  @apply text-grey-light;
}
.search-ico {
  left: 12px;
  top: 12px;
}
</style>

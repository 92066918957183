import { apiCall } from './index';

export const saveLoad = (payload) => apiCall('POST', 'transport', payload);

export const getLoad = () => apiCall('GET', 'transport');

export const updateLoad = (transport) => {
  const body = { ...transport };
  return apiCall('PUT', 'transport', body);
};

<template>
  <div>
    <router-link to="/">
      <img src="@/assets/images/logo.svg" />
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'Logo',
};
</script>

<template>
  <div class="vld-parent">
    <Loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="fullPage"
      loader="bars"
      color="#0062FF"
    ></Loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import 'vue-loading-overlay/dist/vue-loading.css';
import Loading from 'vue-loading-overlay';

export default {
  name: 'Loader',
  components: {
    Loading,
  },
  props: {
    force: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fullPage: true,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'calls/isLoading',
    }),
    loading() {
      if (this.force) return true;
      return this.isLoading;
    },
  },
};
</script>

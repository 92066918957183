<template>
  <div
    class="flex items-center text-mdl font-primary text-grey-dark-5 font-light"
    :class="[item.class]"
  >
    <img
      v-if="item.icon"
      :src="item.icon"
      class="cursor-pointer mr-12"
      @click="item.fn"
    />
    <p class="w-full">{{ item.text }}</p>
  </div>
</template>

<script>
export default {
  name: 'DefaultCell',
  props: ['item'],
};
</script>

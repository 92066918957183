<template>
  <div
    v-if="menuVisibility"
    class="absolute top-14 p-5 left-0 shadow z-10 bg-white w-300"
  >
    <div class="flex flex-col" :class="hideMenu ? 'hidden' : ''">
      <div v-for="option in options" :key="option.type">
        <button
          class="flex flex-row items-center hover:bg-grey-hover-light w-full px-2 py-.5"
          @click="option.open = !option.open"
        >
          <img
            :src="chevron"
            :class="option.open && 'transform rotate-180'"
            class="transition-all"
          />
          <p class="text-left p-2.5 block">
            {{ option.type }}
          </p>
        </button>
        <div>
          <ul v-show="option.open">
            <li
              v-for="item in option.values"
              :key="item.value"
              class="py-1 px-2 flex flex-row items-center"
            >
              <input
                :id="item.value"
                :checked="isSelected(option.type, item.value)"
                type="radio"
                :name="option.type"
                :value="item.value"
                @click="
                  filterFn({filter: {type: option.type, value: item.value}})
                "
              />
              <label :for="item.value" class="cursor-pointer pl-2">
                {{ item.label }}
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import chevron from "@/assets/icons/chevron.svg";
import {
  vehiclesStatuses,
  loadsStatuses,
  auctionStatuses,
  forecourtStatuses,
} from "@/services/utils/enums.js";

export default {
  name: "FilterMenu",
  props: {
    menuVisibility: {
      type: Boolean,
      default: false,
    },
    closeDropdown: {
      type: Function,
      default: () => null,
    },
    filterFn: {
      type: Function,
      default: () => null,
    },
    type: {
      type: String,
      default: "",
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    chevron,
    hideMenu: false,
    options: [],
  }),
  methods: {
    isSelected(type, value) {
      return this.filters[type.toLowerCase()] === value;
    },
    setOptions(type) {
      const opts = [];
      switch (type) {
        case "vehicles":
          opts.push({
            type: "Type",
            open: true,
            values: [
              {
                value: "FORECOURT",
                label: "Forecourt",
              },
              { value: "AUCTION", label: "Auction" },
            ],
          });
          opts.push({
            type: "Status",
            open: true,
            values: [...vehiclesStatuses],
          });
          break;
        case "loads":
          opts.push({
            type: "Status",
            open: true,
            values: [...loadsStatuses],
          });
          break;
        case "auction":
          opts.push({
            type: "Status",
            open: true,
            values: [...auctionStatuses],
          });
          break;
        case "forecourt":
          opts.push({
            type: "Status",
            open: true,
            values: [...forecourtStatuses],
          });
          break;
        default:
          break;
      }
      this.options = opts;
    },
  },
  mounted() {
    this.setOptions(this.type);
  },
  watch: {
    type(x) {
      this.setOptions(x);
    },
    menuVisibility(x, y) {
      if (x !== y) this.hideMenu = false;
    },
  },
  hide() {
    this.hideMenu = true;
  },
};
</script>
